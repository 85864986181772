import { Product } from './Product';

export class Order{
    /**
     * Constructor
     */
    constructor() {
        this.ProductList = new Array();
    }
    Id: string;
    Date: Date;
    DateText: string;
    Total: number;
    PaymentType: string;
  ProductList: Product[];
  TotalDiscount: number;
  CouponApplied: boolean;
}
