import { GlobalConstans } from "../global-constants/constants";

export class MenuItemBase{
    
    constructor() {
        this.NewTab = true;
        this.Enable = true;
        this.Identifier = GlobalConstans.generateShortGuid();
    }
    
    Identifier: string;
    Text: string;
    Url: boolean;
    NewTab: boolean;
    Enable: boolean;
    
}