export class ImageItem {
    /**
     * Constructor
     */
    constructor() {
        this.Order = 0;
        this.Enable = true;
    }
    
    public Src: string;
    
    public Enable: boolean;
    
    public Order: number;
    
    public Id: string;
}