import { Component, OnInit } from '@angular/core';
import { RequestsService } from '../services/requests.service';
import { ContactInfo } from '../models/ContactInfo';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  contact: ContactInfo;
  loaded = false;
  constructor(private service: RequestsService) {
    this.contact = new ContactInfo();
   }

  ngOnInit() {
    this.service.GetContactInfo()
    .subscribe((address) => {
      this.contact = address;
      this.loaded = true;
    });
  }

}
