import { Question } from './Question';

export class Test{
Id?:number;
CompanyId ?:number;
Name?: string;
Description ?: string;
CategoryId?: number;
CategoryName ?: string;
TotalQuestions?:number;
TotalTime?:number;
Language ?: string;
QuestionList: Question[];
Price?:number;
PriceHistory?:number;
ImgSrc ?: string;
VideoSrc ?: string;
Type?:number;
Level?:string;
}