import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

//Services
import { RequestsService } from '../shared/services/requests.service';

//Models
import { Purchase } from '../shared/models/Purchase';

@Component({
  selector: 'app-purchases',
  templateUrl: './purchases.component.html',
  styleUrls: ['./purchases.component.css']
})
export class PurchasesComponent implements OnInit {
  userObject: any;

  purchases: Array<Purchase> = new Array();
  loadPurchase = false;

  orderTotal = false;
  orderCreated = false;
  orderTotalProducts = false;

  page = 1;
  pageSize = 25;
  collectionSize = 0;

  constructor(private rutas: Router, private service: RequestsService) { 

    this.userObject = JSON.parse(localStorage.getItem('currentUser'));
    if(this.userObject === null)
    {
      this.rutas.navigate(['/index']); 
    }
    
    this.onGetPurchases(this.userObject.Id);

  }

  ngOnInit(): void {
  }


  onGetPurchases(userId: number){
    
    this.service.getPurchasesByUserId(userId).subscribe((res: Purchase[]) => { 
      this.purchases = res;
      this.loadPurchase = true;
      this.collectionSize = this.purchases.length;
      console.log(this.purchases);
    }, err => { console.log(err); });
    

  }

  get orders(): Purchase[] {
    if(this.purchases !== undefined){
      return this.purchases.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
    
    }
    
  }

  details(order: any){
    //Le mando el segundo parametro como 0 (cero) ya que significa que no va canjear, solo va consultar
    this.rutas.navigate(['/confirmation',order.Slug, 0]);
  }

  onSortTotal(){
    this.orderTotal = !this.orderTotal;

    if(this.orderTotal){
        // Ascending
        this.purchases = this.purchases.sort((a,b) => 0 - (a.Total > b.Total ? -1 : 1));
      }else{
        // Descengind
        this.purchases = this.purchases.sort((a,b) => 0 - (a.Total > b.Total ? 1 : -1));
      }
  }

  onSortCreated(){
    this.orderCreated = !this.orderCreated;

    if(this.orderCreated){
        // Ascending
        this.purchases = this.purchases.sort((a,b) => 0 - (a.Created > b.Created ? -1 : 1));
      }else{
        // Descengind
        this.purchases = this.purchases.sort((a,b) => 0 - (a.Created > b.Created ? 1 : -1));
      }
  }

  onSortTotalProducts(){
    this.orderTotalProducts = !this.orderTotalProducts;

    if(this.orderTotalProducts){
        // Ascending
        this.purchases = this.purchases.sort((a,b) => 0 - (a.TotalProducts > b.TotalProducts ? -1 : 1));
      }else{
        // Descengind
        this.purchases = this.purchases.sort((a,b) => 0 - (a.TotalProducts > b.TotalProducts ? 1 : -1));
      }
  }

}
