import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { GlobalConstans } from '../global-constants/constants';
import { AuthenticationService } from '../services/authentication/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    apiUrl: string = GlobalConstans.apiURL;
    token: string;
    executed = false;

    constructor(private authenticationService: AuthenticationService) { }
    

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      
      if (request.url.includes("login/auth") || request.url.includes("getInit")) return next.handle(request);

      const token = GlobalConstans.getAuthToken();
        
        request = request.clone({
            setHeaders: {
                Authorization: "Bearer " + token
            }
        });
        return next.handle(request);
    }
}
