import { Category } from "./Category";
import { Language } from "./Language";
import { Level } from "./Level";
import { newArray } from "@angular/compiler/src/util";

export class Search{
    /**
     *
     */
    constructor() {
        this.CategoryList = new Array<Category>();
        this.LanguageList = new Array<Language>();
        this.LevelList = new Array<Level>();
        this.MinPrice = 0;
        this.MaxPrice = 0;
        
    }
    Text: string;
    ContentType: number;
    MinPrice: number;
    MaxPrice: number;
    LevelId: number;
    CategoryList: Category[];
    LanguageList: Language[];
    LevelList: Level[];
}