import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgbdModalConfirmAutofocus } from '../couponmodal/couponmodal.component';
import { GlobalConstans } from '../shared/global-constants/constants';
import { DiscountCoupon } from '../shared/models/DiscountCoupon';
import { AlertService } from '../shared/services/alert/alert.service';
import { CouponsService } from '../shared/services/coupons/coupons.service';
import { UsersbycouponComponent } from '../usersbycoupon/usersbycoupon.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { ToastService } from '../shared/services/toast/toast.service';

@Component({
  selector: 'app-discount',
  templateUrl: './discount.component.html',
  styleUrls: ['./discount.component.css']
})
export class DiscountComponent implements OnInit {

  modalOption: NgbModalOptions = {};

  // Table vars
  coupons: Array<DiscountCoupon> = new Array();
  couponsCopy: Array<DiscountCoupon> = new Array();
  loadCoupons = false;

  orderCoupon = false;
  orderDiscount = false;
  orderDueDate = false;
  orderReleasedDate = false;

  enableCoupons = false;

  page = 1;
  pageSize = 25;
  collectionSize = 0;

  constructor(
    private alert: AlertService,
    private rutas: Router,
    private modalService: NgbModal,
    private service: CouponsService,
    private clipboard: Clipboard,
    private toastService: ToastService  ) {

    let userObject = JSON.parse(localStorage.getItem('currentUser'));
    if (userObject === null || !userObject.IsManageCoupons) {
      this.rutas.navigate(['/index']);
      return;
    }
    this.enableCoupons = GlobalConstans.getCacheValue("DiscountCoupons");
    this.OnGetCouponsByOrganization();
  }


  OnGetCouponsByOrganization() {
    this.service.getCouponsByOrganizationId().subscribe((res: DiscountCoupon[]) => {
      this.coupons = res;
      this.couponsCopy = res;
      this.loadCoupons = true;
      this.collectionSize = this.coupons.length;
    }, err => { console.log(err); });
  }

  //=====================================================================================================
  // ngOnInit()  
  //=====================================================================================================
  ngOnInit(): void {

  }

  get result(): DiscountCoupon[] {
    if (this.coupons !== undefined) {
      return this.coupons.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
    }
  }

  setLabel(status: number) {
    switch (status) {
      case 0:
        return '<span class="badge badge-secondary">Draft</span>';
      case 1:
        return '<span class="badge badge-warning">Edited</span>';
      case 2:
        return '<span class="badge badge-success">Released</span>';
      default:
        return '<span class="badge badge-secondary">Draft</span>';
    }
  }


  parseDate(date) {
    var d = new Date(date)

    if (d.getFullYear() === 1) {
      return " - - - ";
    } else {
      return d.toLocaleDateString("en-US");
    }
  }


  addCoupon(data: DiscountCoupon) {

    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.modalOption.centered = true;

    const modalRef = this.modalService.open(NgbdModalConfirmAutofocus, this.modalOption);
   
    var model = data || new DiscountCoupon();
    modalRef.componentInstance.coupon = model;
    modalRef.result.then((result) => {
      this.OnGetCouponsByOrganization();
    });
  }

  publish(coupon: DiscountCoupon) {
    if (coupon.Status === 2) {
      return this.alert.onConfirm("Released","Your discount coupon has already been published");
    }

    let $title = "Release coupon";
    let $text = "Do you want to publish the '" + coupon.Coupon + "' coupon?";
    let $buttons = { 'ConfirmButtonText': 'Publish', 'cancelButtonText': 'Cancel' };

    this.alert.onBeforeConfirm($title, $text, $buttons)
      .then((response) => {
        if (response.value) {
          this.service.publishCoupon(coupon.Id)
            .subscribe((response) => {
              
              if (response) {

                this.alert
                  .onConfirm('Done!', "Your discount coupon has been published successfully")
                  .then((result) => {
                    if (result.value) {
                      this.OnGetCouponsByOrganization();
                    }
                  });
              }
            })
        } else {
          return;
        }
      })
  }

  usersByCoupon(coupon: DiscountCoupon) {
    if (coupon.TotalRedemptions === 0) {
      return this.alert.onInfo("Ups!", "It looks like no one has redeemed this coupon yet");
    } else {

      this.modalOption.backdrop = 'static';
      this.modalOption.keyboard = false;
      this.modalOption.centered = true;
      this.modalOption.size = 'xl';
      const modalRef = this.modalService.open(UsersbycouponComponent, this.modalOption);

      var model = coupon || new DiscountCoupon();
      modalRef.componentInstance.coupon = model;
      modalRef.result.then((result) => { return; });

    }
  }

  delete(coupon: DiscountCoupon) {
    
    let $title = "Delete Coupon";
    let $text = "Do you want to delete the '" + coupon.Coupon + "' coupon?";
    let $buttons = { 'ConfirmButtonText': 'Delete', 'cancelButtonText': 'Cancel' };

    this.alert.onBeforeConfirm($title, $text, $buttons)
      .then((response) => {
        if (response.value) {
          this.service.deleteCoupon(coupon.Id)
            .subscribe((response) => {
              console.log({ response });
              if (response) {

                this.alert
                  .onConfirm('Done!', "Your discount coupon has been deleted successfully")
                  .then((result) => {
                    if (result.value) {                      
                      this.OnGetCouponsByOrganization();
                    }
                  });
              }
            })
        } else {
          return;
        }
      })
  }

  setDiscountCoupons() {
    
    let isChecked = !this.enableCoupons;

    let $title = isChecked ? 'Enable Discount Coupons' : 'Disabled Discount Coupons';
    let $text = isChecked
      ? 'Do you want to enable discount coupons funcionality?'
      : 'Do you want to disabled discount coupons funcionality?'
    let $button = { 'ConfirmButtonText': 'Change', 'cancelButtonText': 'Cancel' };

    this.alert.onBeforeConfirm($title, $text, $button)
      .then((response) => {

        if (response.value) {

          this.service.changeDiscountCoupons(isChecked)
            .subscribe((response: any) => {

              if (response.Success) {

                GlobalConstans.updateCacheValue("DiscountCoupons", isChecked);

                this.enableCoupons = isChecked;
                let text = isChecked
                  ? "You have enabled discount coupons funcionality"
                  : "You have disabled discount coupons funcionality";
                return this.alert.onConfirm("Perfect", text);
              }

            });


        }
      });

  }

  //========================================
  
  onSortCoupon() {
    this.orderCoupon = !this.orderCoupon;

    if (this.orderCoupon) {
      // Ascending
      this.coupons = this.coupons.sort((a, b) => 0 - (a.Coupon > b.Coupon ? -1 : 1));
    } else {
      // Descengind
      this.coupons = this.coupons.sort((a, b) => 0 - (a.Coupon > b.Coupon ? 1 : -1));
    }

  }

  onSortDiscount() {
    this.orderDiscount = !this.orderDiscount;

    if (this.orderDiscount) {
      // Ascending
      this.coupons = this.coupons.sort((a, b) => 0 - (a.Discount > b.Discount ? -1 : 1));
    } else {
      // Descengind
      this.coupons = this.coupons.sort((a, b) => 0 - (a.Discount > b.Discount ? 1 : -1));
    }

  }

  onSortDueDate() {
    this.orderDueDate = !this.orderDueDate;

    if (this.orderDueDate) {
      // Ascending
      this.coupons = this.coupons.sort((a, b) => 0 - (a.DueDate > b.DueDate ? -1 : 1));
    } else {
      // Descengind
      this.coupons = this.coupons.sort((a, b) => 0 - (a.DueDate > b.DueDate ? 1 : -1));
    }

  }

  onSortReleasedDate() {
    this.orderReleasedDate = !this.orderReleasedDate;

    if (this.orderReleasedDate) {
      // Ascending
      this.coupons = this.coupons.sort((a, b) => 0 - (a.ReleasedDate > b.ReleasedDate ? -1 : 1));
    } else {
      // Descengind
      this.coupons = this.coupons.sort((a, b) => 0 - (a.ReleasedDate > b.ReleasedDate ? 1 : -1));
    }

  }


  searchText(search: string) {
    
    if (search === '') {
      this.coupons = this.couponsCopy;
      return;
    }

    this.coupons = this.coupons.filter((a) => a.Coupon.match(search));
  }

  copyText(textToCopy:string) {
    this.clipboard.copy(textToCopy);
    this.toastService.onSuccess("Done!","Copied");
  }
}
