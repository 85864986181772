import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NavlinkType } from '../shared/enums/NavlinkType';
import { Menu } from '../shared/models/Menu';
import { MenuItem } from '../shared/models/MenuItem';
import { MenuItemBase } from '../shared/models/MenuItemBase';
import { AlertService } from '../shared/services/alert/alert.service';
import { RequestsService } from '../shared/services/requests.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-navlinks',
  templateUrl: './navlinks.component.html',
  styleUrls: ['./navlinks.component.css']
})
export class NavlinksComponent implements OnInit {

  //Pagination
  page = 1;
  pageSize = 5;
  collectionSize = 0;

  //Flags
  public isCreation: boolean;
  public creationType: number;

  //Forms
  buttonForm : FormGroup;
  dropdownForm: FormGroup;

  //Submit vars
  submitButtonForm: boolean;
  submitDropdownForm: boolean;

  //Variables para la creación y/o edición de un elemento
  Menu: Menu;
  DropdownItems : MenuItemBase[];
  invalidUrls : boolean;

  constructor(private formBuilder: FormBuilder, private service: RequestsService, private alert : AlertService, private rutas : Router) {

    let userObject = JSON.parse(localStorage.getItem('currentUser'));
      if(userObject === null)
      {
        this.rutas.navigate(['/index']); 
      }
      
    this.Menu = new Menu();
    this.DropdownItems = new Array<MenuItemBase>();
    this.DropdownItems.push(new MenuItemBase());
    this._getMenu();

  }

  ngOnInit(): void {

    //Button form init
    this.buttonForm = new FormGroup({ 
      Identifier:new FormControl(), 
      Text:new FormControl(), 
      Url: new FormControl(),
      Enable: new FormControl()
    });

    //Dropdown form init
    this.dropdownForm = new FormGroup({ 
      Identifier:new FormControl(), 
      Text:new FormControl(), 
      Enable: new FormControl()
    });

    
  }

  // ====================================================================================================================================
  // GetMenu
  // ====================================================================================================================================

  _getMenu(){
    this.service.GetMenu().subscribe((menu:Menu) => {
      
      this.Menu = menu;
      this.collectionSize = this.Menu.Links.length;
      console.log({'menu':menu});
      });
  }

  // ====================================================================================================================================
  //Instance new FormBuilders
  // ====================================================================================================================================
  
  instanceButtonForm(item : MenuItemBase){

    const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

    this.buttonForm = this.formBuilder.group({
      Identifier: [item.Identifier],
      Text: [item.Text, Validators.required],
      Url: [item.Url, [Validators.required, Validators.pattern(reg)]],
      Enable: [item.Enable],
    });
  }

  instanceDropdownForm(item : MenuItemBase){

    this.dropdownForm = this.formBuilder.group({
      Identifier: [item.Identifier],
      Text: [item.Text, Validators.required],
      Enable: [item.Enable],
    });
  }


  // ====================================================================================================================================
  // FORMS PROPERTIES
  // ====================================================================================================================================
  get bf() { return this.buttonForm.controls; }
  get df() { return this.dropdownForm.controls; }

  //===============================================================================================
  // START: Submit actions
  //===============================================================================================
  onSubmitButtonForm(){
    this.submitButtonForm = true;

    // stop here if form is invalid
    if (this.buttonForm.invalid) { return; }

    let navlink = new MenuItem();
    navlink.Identifier = this.buttonForm.value.Identifier;
    navlink.Text = this.buttonForm.value.Text;
    navlink.Url = this.buttonForm.value.Url;
    navlink.Enable = this.buttonForm.value.Enable;
    navlink.Type = NavlinkType.Navlink;

    this.service.UpdateItemsToMenu(navlink)
    .subscribe((res:any) => {
      
      if(res){
        console.log({res});
        this._getMenu();
        this.cancel();
      }

    }, (err) => {
      console.log({err});
      this.submitButtonForm = false;
    });

  }
  
  onSubmitDropdownForm(){
    this.submitDropdownForm = true;
    // stop here if form is invalid
    if (this.dropdownForm.invalid) { return; }

    let navlink = new MenuItem();
    navlink.Identifier = this.dropdownForm.value.Identifier;
    navlink.Text = this.dropdownForm.value.Text;
    navlink.Enable = this.dropdownForm.value.Enable;
    navlink.Type = NavlinkType.Dropdown;
    navlink.DropdownItems = this.DropdownItems;

    this.service.UpdateItemsToMenu(navlink)
    .subscribe((res:any) => {
      
      if(res){
        console.log({res});
        this._getMenu();
        this.cancel();
      }

    }, (err) => {
      console.log({err});
      this.submitDropdownForm = false;
    });

  }

  //===============================================================================================
  // START: Generic actions
  //===============================================================================================
  
  addNewNavlink(comesFrom){

    this.isCreation = true;
    let item = new MenuItemBase();

  switch (comesFrom) {
    case 'Dropdown':
      this.DropdownItems = new Array<MenuItemBase>();
      this.DropdownItems.push(new MenuItemBase());
      this.instanceDropdownForm(item);
      this.creationType = NavlinkType.Dropdown;
      break;
    case 'Navlink':
      this.instanceButtonForm(item);
      this.creationType = NavlinkType.Navlink;
    default:
      break;
  }
  
  }

  cancel(){
      this.isCreation = false;
      this.creationType = 0;

      this.submitButtonForm = false;
      this.submitDropdownForm = false;
    }

    addNavlink(index){
      if(this.DropdownItems.length === 10) return;
    this.DropdownItems.push(new MenuItem());

    }

    deleteNavlink(index){

      if(this.DropdownItems.length === 1) return;

      this.DropdownItems.splice(index, 1);

    }

    returnMenuItemType(type : number){
      switch (type) {
        case NavlinkType.Dropdown:
          return 'Dropdown';
          break;
        case NavlinkType.Navlink:
          return 'Navlink';
        default:
          break;
      }
    }

    editMenuItem(item:MenuItem){
      this.isCreation = true;
      
    switch (item.Type) {
      case NavlinkType.Dropdown:
        this.DropdownItems = item.DropdownItems;
        this.instanceDropdownForm(item);
        this.creationType = NavlinkType.Dropdown;
        break;
      case NavlinkType.Navlink:
        this.instanceButtonForm(item);
        this.creationType = NavlinkType.Navlink;
      default:
        break;
    }
    }

    deleteMenuItem(item){

      let $title = "Delete";
      let $text = "Do you want to delete the " + item.Text + " navlink option?";
      let $button = {'ConfirmButtonText': 'Delete', 'cancelButtonText': 'Cancel'};

      this.alert.onBeforeConfirm($title, $text, $button)
      .then((response) => {
      
        if(response.value === true){
          this.service.DeleteItemToMenu(item)
          .subscribe((res) => {
            if(res){
              this.alert.onConfirm("Perfect!","Your navlink was deleted correctly");
              this._getMenu();
            }
            
          });
  
        }
        
      });

    }

    // validateUrl(navlink:MenuItem){
      
    //   var result = this.validURL(navlink.Url);
      
    //   if(!result && !this.invalidUrls){
    //     debugger;
    //     this.invalidUrls = true;
    //   }
    //   return result;
    // }

    // validURL(str) {
    //   var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    //     '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    //     '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    //     '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    //     '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    //     '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    //   return !!pattern.test(str);
    // }
}
