import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

//Constants
import { GlobalConstans } from '../shared/global-constants/constants';

//Service
import { RequestsService } from '../shared/services/requests.service';
import { CartService } from '../shared/services/cart.service';
import { ToastService } from '../shared/services/toast/toast.service';

//Models
import { Test } from '../shared/models/Test';
import { Product } from '../shared/models/Product';

//Interfaces
import { ITests } from '../shared/interfaces/tests';
import { IQuestion } from '../shared/interfaces/question';

@Component({
  selector: 'app-examdetails',
  templateUrl: './examdetails.component.html',
  styleUrls: ['./examdetails.component.css']
})
export class ExamdetailsComponent implements OnInit {
  testId: number;
  test: ITests;
  loading: boolean;
  videoId: string;
  isYouTube: boolean;

  constructor(private route: ActivatedRoute,
    private service: RequestsService,
    private cartService: CartService, 
    private toastService: ToastService,
    private modalService: NgbModal) {

    this.route.params.subscribe(parameters => { this.testId = parameters.globalId; });
    this.test = new Test();
    this.loading = false;

   }

  ngOnInit(): void {
    this.service.getExamDetailsById(this.testId).subscribe((res: ITests) => { 
      this.test = res;
      console.log({ 'test': res });
      this.test.ImgSrc = GlobalConstans.returnExamsUrlImage(this.test.CompanyId.toString(), this.test.Id.toString(),this.test.ImgSrc);
      this.loading = true;
    }, err => { console.log(err); });
  }

  addProduct(model: any){
debugger;
    this.cartService._addProductToCart(model);
    
  }

  openWindowCustomClass(content) {
    //this.currentCourse.VideoSrc = "https://vjs.zencdn.net/v/oceans.mp4";
    this.getYouTubeId();
    this.modalService.open(content, { centered: true, size: 'lg' });
  }

  getYouTubeId(){
    //Extraje el componente de aqui: https://stackblitz.com/edit/angular-youtube-video?file=src%2Findex.html
    let $this = this.test.VideoSrc;
  
          if ($this.match(/(\?|&)v=([^&#]+)/)) {
              $this = $this.match(/(\?|&)v=([^&#]+)/).pop();
              this.isYouTube = true;
  
          } else if ($this.match(/(\.be\/)+([^\/]+)/)) {
            $this = $this.match(/(\.be\/)+([^\/]+)/).pop();
            this.isYouTube = true;
  
          } else if ($this.match(/(\embed\/)+([^\/]+)/)) {
            $this = $this.match(/(\embed\/)+([^\/]+)/).pop().replace('?rel=0','');
            this.isYouTube = true;
          }
          
          this.videoId = $this;
  }

  setDefaultPic() {
    this.test.ImgSrc = GlobalConstans.returnExamsUrlImage("0", "0", undefined);
  }

  print(): void {
    
    let footer = document.getElementById('footer');
    footer.classList.add("d-none");
  
      setTimeout(function () {
        window.print();
      }, 1000);
  
      setTimeout(function () {
        footer.classList.remove("d-none");
      }, 1000);
  
  }
}
