import { Injectable } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
//DOCUMENTATION AT: https://sweetalert2.github.io/

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor() { }


  onConfirm(title: string, text: string, button?: string){

    let $title = title || 'Good job!';
    let $text = text || 'Everything is working!';
    let $button = button || 'Nice';

    return Swal.fire({
      title: $title,
      text: $text,
      icon: 'success',
      confirmButtonColor: '#28a745',
      showCancelButton: false,
      confirmButtonText: $button
    });
  }

  onBeforeConfirm($title: string, $text: string, $buttons : any){
    return Swal.fire({
      title: $title,
      text: $text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: $buttons["ConfirmButtonText"],
      cancelButtonText: $buttons["cancelButtonText"]
    });
  }

  onError(title: string, text: string, isHtml = false){

    let $title = title || 'Sorry!';
    let $text = text || 'Something went wrong!';

    if (isHtml) {
      return Swal.fire({
        title: $title,
        html: $text,
        icon: 'error',
        confirmButtonColor: '#3085d6',
        showCancelButton: false,
        confirmButtonText: 'Understood'
      });
    } else {
      return Swal.fire({
        title: $title,
        text: $text,
        icon: 'error',
        confirmButtonColor: '#3085d6',
        showCancelButton: false,
        confirmButtonText: 'Understood'
      });
    }

    
  }

  onInfo(title: string, text: string) {
    Swal.fire({
      icon: 'info',
      title: title,
      text: text
    })
  }

  opensweetalert()
  {
    Swal.fire({
        text: 'Hello!',
        icon: 'success'
      });
  }

  opensweetalertdng()
  {
   Swal.fire('Oops...', 'Something went wrong!', 'error')
  }

  opensweetalertcst(){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
      Swal.fire(
        'Deleted!',
        'Your imaginary file has been deleted.',
        'success'
      )
      // For more information about handling dismissals please visit
      // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your imaginary file is safe :)',
        'error'
      )
      }
    })
  }
}
