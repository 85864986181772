import { Component, OnInit } from '@angular/core';
import { RequestsService } from '../shared/services/requests.service';
import { Category } from '../shared/models/Category';
import { ICategory } from '../shared/interfaces/categories';
import { ITests } from '../shared/interfaces/tests';
import { CartService } from '../shared/services/cart.service';
import { ToastService } from '../shared/services/toast/toast.service';
import { Product } from '../shared/models/Product';
import {NgbTabsetConfig} from '@ng-bootstrap/ng-bootstrap';
//Constants
import { GlobalConstans } from '../shared/global-constants/constants';
//enums
import { ContentType } from '../shared/enums/ContentType'

@Component({
  selector: 'app-exams',
  templateUrl: './exams.component.html',
  styleUrls: ['./exams.component.css'],
  providers: [NgbTabsetConfig]
})
export class ExamsComponent implements OnInit {

  examContentType = ContentType.Exam;

  customOptions: any = {}
  categories: ICategory[];
  loadCategories = false;

  fTests: ITests[];
  loadFTests = false;

  rTests: ITests[];
  loadrTests = false;

  constructor(private requestService: RequestsService,
    config: NgbTabsetConfig,
    private cartService: CartService,
    private toastService: ToastService) {

    config.justify = 'center';
    config.type = 'pills';

    this.requestService.examHome_GetTopCategories().subscribe((res: ICategory[]) => { 
      this.categories = res; 
      this.categories.forEach(element => {
        element.ImgSrc = GlobalConstans.returnCategoryUrlImage(element.CompanyId.toString(), element.Id.toString(), element.ImgSrc);
        GlobalConstans.imageExists(element.ImgSrc, function(exists) {
          if(!exists){
              console.log('RESULT: url=' + element.ImgSrc + ', exists=' + exists);
              element.ImgSrc = GlobalConstans.returnCategoryUrlImage(element.CompanyId.toString(), element.Id.toString(), undefined);
          }
          });
       });
      this.loadCategories = true;
      
    }, err => { console.log(err); });

    this.requestService.loadTopTests().subscribe((res: ITests[]) => { 
      this.fTests = res; 
      this.fTests.forEach(element => {
          element.ImgSrc = GlobalConstans.returnExamsUrlImage(element.CompanyId.toString(), element.Id.toString(),element.ImgSrc);
          GlobalConstans.imageExists(element.ImgSrc, function(exists) {
            if(!exists){
                console.log('RESULT: url=' + element.ImgSrc + ', exists=' + exists);
                element.ImgSrc = GlobalConstans.returnExamsUrlImage(element.CompanyId.toString(), element.Id.toString(), undefined);
            }
            });
       });
       this.loadFTests = true;
    }, err => { console.log(err); });

    this.requestService.examHome_GetRecentAdded().subscribe((res: ITests[]) => { 
      this.rTests = res; 
      this.rTests.forEach(element => {
          element.ImgSrc = GlobalConstans.returnExamsUrlImage(element.CompanyId.toString(), element.Id.toString(),element.ImgSrc);
          GlobalConstans.imageExists(element.ImgSrc, function(exists) {
            if(!exists){
                console.log('RESULT: url=' + element.ImgSrc + ', exists=' + exists);
                element.ImgSrc = GlobalConstans.returnExamsUrlImage(element.CompanyId.toString(), element.Id.toString(), undefined);
            }
            });
       });

       this.loadrTests = true;
    }, err => { console.log(err); });
   }

  ngOnInit(): void {
    this.customOptions = {
      loop: false,
      mouseDrag: false,
      touchDrag: false,
      pullDrag: false,
      margin:15,
      dots: false,
      navSpeed: 700,
      navText: ['<i class="fa fa-arrow-left"></i>', '<i class="fa fa-arrow-right"></i>'],
      nav: true,
      responsive: {
        0: {
          items: 1
        },
        400: {
          items: 2
        },
        740: {
          items: 3
        },
        940: {
          items: 3
        }
      },
    }
  }

  addProduct(model: any){

    this.cartService._addProductToCart(model);
    
  }

}
