import { BrowserModule } from '@angular/platform-browser';
import { NgModule, InjectionToken, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ToastrModule } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { YouTubePlayerModule } from "@angular/youtube-player";
import { NgxStripeModule } from 'ngx-stripe';
import { NgxCaptchaModule } from 'ngx-captcha';
import { CommonModule } from '@angular/common';

import { AppComponent } from './app.component';
import { MenuComponent } from './shared/menu/menu.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './shared/footer/footer.component';
import { ProductdetailsComponent } from './productdetails/productdetails.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ContactComponent } from './contact/contact.component';
import { CartComponent } from './cart/cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ExamdetailsComponent } from './examdetails/examdetails.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';

//Service class
import { RequestsService } from './shared/services/requests.service';

//Interceptos
import { ErrorInterceptor } from './shared/helpers/error.intepceptor';
import { JwtInterceptor } from './shared/helpers/jwt.interceptor';
import { AuthGuard } from './shared/helpers/auth.guard';
import { CoursesComponent } from './courses/courses.component';
import { ExamsComponent } from './exams/exams.component';
import { SearchComponent } from './search/search.component';
import { ForgotComponent } from './forgot/forgot.component';
import { SecurityComponent } from './security/security.component';

//Constants
import { GlobalConstans } from './shared/global-constants/constants';
import { PolicyComponent } from './policy/policy.component';
import { CookieComponent } from './cookie/cookie.component';
import { AddedComponent } from './added/added.component';
import { PurchasesComponent } from './purchases/purchases.component';
import { ProfileComponent } from './profile/profile.component';
import { ForgotconfirmationComponent } from './forgotconfirmation/forgotconfirmation.component';
import { CustomizationComponent } from './customization/customization.component';
import { NgbdModalConfirmAutofocus } from './couponmodal/couponmodal.component';

//Directiva
import { ResolveStorageDirective } from './shared/directive/storage/resolveStorage'; // Importa la directiva aquí

//Load config data
import { APP_INITIALIZER } from '@angular/core';
import { AppConfig } from './shared/models/AppConfig';
import { CarouselComponent } from './carousel/carousel.component';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { NavlinksComponent } from './navlinks/navlinks.component';
import { UserService } from './shared/services/user/user.service';
import { DiscountComponent } from './discount/discount.component';
import { NgbDateCustomParserFormatter } from './couponmodal/couponmodal.component';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
// Import library module
import { NgxSpinnerModule } from "ngx-spinner";
import { UsersbycouponComponent } from './usersbycoupon/usersbycoupon.component';



export function configFactory(config: UserService) {

  const x = (): Promise<any> => {
    return config.getInit();
  };
  return x;
}

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HomeComponent,
    FooterComponent,
    ProductdetailsComponent,
    LoginComponent,
    RegisterComponent,
    ContactComponent,
    CartComponent,
    ExamdetailsComponent,
    CheckoutComponent,
    ExamdetailsComponent,
    ConfirmationComponent,
    CoursesComponent,
    ExamsComponent,
    SearchComponent,
    ForgotComponent,
    SecurityComponent,
    PolicyComponent,
    CookieComponent,
    AddedComponent,
    PurchasesComponent,
    ProfileComponent,
    ForgotconfirmationComponent,
    CustomizationComponent,
    CarouselComponent,
    NavlinksComponent,
    NgbdModalConfirmAutofocus,
    DiscountComponent,
    UsersbycouponComponent,
    ResolveStorageDirective
  ],
  imports: [
    NgbModule,
    CommonModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    YouTubePlayerModule,
    NgxStripeModule.forRoot(GlobalConstans.GetStripePublicKey()),
    NgxCaptchaModule,
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 3000,
      progressBar: true,
      positionClass: 'toast-top-right'
    }),
    CarouselModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent },
      /*{ path: 'login', component: LoginComponent },*/
      { path: 'storefront/register', component: RegisterComponent },
      { path: 'storefront/forgot', component: ForgotComponent },
      { path: 'squestion', component: SecurityComponent },
      { path: 'contact', component: ContactComponent },
      { path: 'cart', component: CartComponent },
      { path: 'checkout', component: CheckoutComponent },
      { path: 'courses', component: CoursesComponent },
      { path: 'exams', component: ExamsComponent },
      { path: 'search', component: SearchComponent },
      { path: 'course/detail/:globalId', component: ProductdetailsComponent },
      { path: 'exam/detail/:globalId', component: ExamdetailsComponent },
      { path: 'confirmation/:slug/:redeem', component: ConfirmationComponent },
      { path: 'policy', component: PolicyComponent },
      { path: 'cookie', component: CookieComponent },
      { path: 'added/:globalId', component: AddedComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'customization', component: CustomizationComponent },
      { path: 'purchases', component: PurchasesComponent },
      { path: 'forgotconfirmation/:id', component: ForgotconfirmationComponent },
      { path: 'carousel', component: CarouselComponent },
      { path: 'navlinks', component: NavlinksComponent },
      { path: 'discount', component: DiscountComponent },
      { path: '**', pathMatch: 'full', redirectTo: '' },
    ], { useHash: false })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [UserService],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    RequestsService,
    CookieService,
    CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
