import { Component, Input, Output, Directive, EventEmitter } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

//Constants
import { GlobalConstans } from '../shared/global-constants/constants';

//Models
import { Product } from '../shared/models/Product';

//Interfaces
import { IFeaturedProducts } from '../shared/interfaces/featured-products';
import { ICourses } from '../shared/interfaces/courses';
import { ITests } from '../shared/interfaces/tests';

//Service
import { RequestsService } from '../shared/services/requests.service';
import { CartService } from '../shared/services/cart.service';
import { ToastService } from '../shared/services/toast/toast.service';

//Enum
import { ContentType } from '../shared/enums/ContentType'
import { Banner } from '../shared/models/Banner';
import { ButtonPosition } from '../shared/enums/ButtonPosition';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [NgbCarouselConfig]
})
export class HomeComponent {

  images = [700, 800, 807].map((n) => `https://picsum.photos/id/${n}/900/500`);


  courseContent = 0;
  examContent = 0;

  //Angular Owl-carousel
  customOptions: any = {}
  carouselOptions: OwlOptions = {}

  fProducts: IFeaturedProducts[];
  loadedfProducts = false;

  fCourses: ICourses[];
  loadedfCourses = false;

  fTests: ITests[];
  loadedfTests = false;

  showBanner = false;
  enableCourses = false;
  enableExams = false;

  ImagesList: Array<Banner> = new Array();

  contentType = 0;

  width: number;
  constructor(
    private service: RequestsService,
    private cartService: CartService,
    private toastService: ToastService,
    private router: Router,
    config: NgbCarouselConfig) {

    this.courseContent = ContentType.Course;
    this.examContent = ContentType.Exam;


    // customize default values of carousels used by this component tree
    config.interval = 1000000;//6000
    config.keyboard = true;
    config.pauseOnHover = true;

  }

  ngOnInit() {

    let config = JSON.parse(localStorage.getItem('jsonConfig'));

    if (config !== null && config !== undefined) {

      this.showBanner = config.ShowBanner;
      this.enableCourses = config.EnableCourses;
      this.enableExams = config.EnableExams;

      if (this.enableCourses && this.enableExams) {
        this.contentType = ContentType.AllContent;
      }
      else if (this.enableCourses && !this.enableExams) {
        this.contentType = ContentType.Course;
      }
      else if (!this.enableCourses && this.enableExams) {
        this.contentType = ContentType.Exam;
      }


      if (this.showBanner) {
        this.service.ImagesForBanner().subscribe((images) => {
          this.showBanner = images.length > 0;
          this.ImagesList = images;
        });
      }


    }

    this.customOptions = {
      loop: false,
      mouseDrag: false,
      touchDrag: false,
      pullDrag: false,
      margin: 10,
      dots: false,
      navSpeed: 700,
      navText: ['<i class="fa fa-arrow-left"></i>', '<i class="fa fa-arrow-right"></i>'],
      nav: true
    }

    this.carouselOptions = {
      items: 1,
      loop: true,
      nav: false,
      navSpeed: 700,
      autoplay: true,
      dots: true,
      navText: ['<i class="fas fa-chevron-left fa-4x"></i>', '<i class="fas fa-chevron-right fa-4x"></i>']
    }

    this.service.loadFeaturedProducts(this.contentType).subscribe((res: IFeaturedProducts[]) => {
      this.fProducts = res;
      this.fProducts.forEach(element => {
        if (element.Type === ContentType.Course) {
          element.ImgSrc = GlobalConstans.returnCoursesUrlImage(element.CompanyId.toString(), element.Id.toString(), element.ImgSrc);

          //GlobalConstans.imageExists(element.ImgSrc, function (exists) {
          //  if (!exists) {
          //    element.ImgSrc = GlobalConstans.returnCoursesUrlImage(element.CompanyId.toString(), element.Id.toString(), undefined);
          //  }
          //});

        } else {
          element.ImgSrc = GlobalConstans.returnExamsUrlImage(element.CompanyId.toString(), element.Id.toString(), element.ImgSrc);

          //GlobalConstans.imageExists(element.ImgSrc, function (exists) {
          //  if (!exists) {
          //    element.ImgSrc = GlobalConstans.returnExamsUrlImage(element.CompanyId.toString(), element.Id.toString(), undefined);
          //  }
          //});

        }
      });
      this.loadedfProducts = true;

    }, err => {
      console.log(err);
    });

    if (this.enableCourses) {
      this.service.loadTopCourses().subscribe((res: ICourses[]) => {
        this.fCourses = res;
        this.fCourses.forEach(element => {
          element.ImgSrc = GlobalConstans.returnCoursesUrlImage(element.CompanyId.toString(), element.Id.toString(), element.ImgSrc);
          //GlobalConstans.imageExists(element.ImgSrc, function (exists) {
          //  if (!exists) {
          //    element.ImgSrc = GlobalConstans.returnCoursesUrlImage(element.CompanyId.toString(), element.Id.toString(), undefined);
          //  }
          //});
        });

        this.loadedfCourses = true;
      }, err => { console.log(err); });
    }

    if (this.enableExams) {
      this.service.loadTopTests().subscribe((res: ITests[]) => {
        this.fTests = res;
        this.fTests.forEach(element => {
          element.ImgSrc = GlobalConstans.returnExamsUrlImage(element.CompanyId.toString(), element.Id.toString(), element.ImgSrc);
          //GlobalConstans.imageExists(element.ImgSrc, function (exists) {
          //  if (!exists) {
          //    element.ImgSrc = GlobalConstans.returnExamsUrlImage(element.CompanyId.toString(), element.Id.toString(), undefined);
          //  }
          //});
        });

        this.loadedfTests = true;
      }, err => { console.log(err); });
    }


  }

  addProduct(model: any) {

    this.cartService._addProductToCart(model);

  }

  redirecTo(action: string) {

    switch (action) {
      case "courses":
        this.router.navigate(['/courses']);
        break;
      case "exams":
        this.router.navigate(['/exams']);
        break;
      case "search":
        this.router.navigate(['/search', { "contentType": 0, "search": '' }]);
        break;
      case "login":
        this.router.navigate(['/login']);
        break;
      case "register":
        this.router.navigate(['/register']);
        break;
      case "contact":
        this.router.navigate(['/contact']);
        break;
    }
  }

  getButtonPosition(position) {
    //return "TopLeftCorner";
    //return "TopMiddle";
    //return "TopRightCorner";
    //return "LeftMiddle";
    //return "RightMiddle";
    //return "BottomLeftCorner";
    //return "BottomMiddle";
    //return "BottomRightCorner";
    //return "Center";

    if (position === undefined || position === null || position === 0) {
      return "Center";
    }

    let enumNames = [];
    for (const log in ButtonPosition) {

      if (isNaN(Number(log))) {
        enumNames.push(log);
      }
    }

    return enumNames[position - 1];
  }

  onResize(event) {
    var width = event.target.innerWidth;

    let result = (width / 100) > 100 ? 100 : (width / 10);
    this.width = result;
  }

  imageRedirection(item) {

    if (item.EnableButton) {


      if (item.ButtonObject.IsExternal) {

        if (item.ButtonObject.Target) {
          window.open(item.ButtonObject.Action, '_blank');
        } else {
          window.location.href = item.ButtonObject.Action;
        }

      } else {
        this.redirecTo(item.ButtonObject.Type);
      }


    }

  }
}
