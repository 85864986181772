import { Directive, Input, ElementRef } from '@angular/core';
import { StorageService } from '../../services/storage/storage.services';
import { GlobalConstans } from '../../global-constants/constants';
@Directive({
  selector: '[resolveStorageDirective]'
})
export class ResolveStorageDirective {
  @Input() valueStorage: string;

  constructor(private storageService: StorageService, private el: ElementRef) { }

  ngOnInit() {
    // Lógica de la directiva aquí
    console.log("entra a la directiva", this.valueStorage);
    //storefront/13/banner/b9319a86-b8ca-4ec0-b18e-ac07eb9754ad.png/
    let processUrl = `${this.valueStorage.replace(GlobalConstans.replaceUrl, "")}/`;;
    this.storageService.resolveStorage(processUrl).subscribe((res: any) => {
      const byteCharacters = atob(res);
      //mimeType
      let splitUrl = processUrl.split('/');
      let mimeType = "";
      for (const i of splitUrl) {
        if (i.indexOf('.') > -1) {
          mimeType = this._resolveMimeType(i);
        }
      }

      // Crear un array buffer a partir de los bytes decodificados
      const buffer = new ArrayBuffer(byteCharacters.length);
      const bufferView = new Uint8Array(buffer);
      for (let i = 0; i < byteCharacters.length; i++) {
        bufferView[i] = byteCharacters.charCodeAt(i);
      }

      // Crear un objeto Blob a partir del array buffer
      const blob = new Blob([buffer], { type: mimeType });

      // Crear una URL (Object URL) a partir del Blob
      const url_blob = URL.createObjectURL(blob);
      this.el.nativeElement.src = url_blob;

      const tiempoEspera = 3000;

      // Ejecuta la función miFuncion() después del tiempo de espera
      setTimeout(() => {
        URL.revokeObjectURL(url_blob);
      }, tiempoEspera);


    })



    //console.log("Valor de el ", el);
  }

  _resolveMimeType(name: string) {

    const mimetypes = {
      ".html": "text/html",
      ".css": "text/css",
      ".js": "application/javascript",
      ".json": "application/json",
      ".png": "image/png",
      ".jpg": "image/jpeg",
      ".gif": "image/gif",
      ".pdf": "application/pdf",
      ".mp3": "audio/mpeg",
      ".mp4": "video/mp4",
      // Agrega más extensiones y mimetypes según se requieran
    };

    // Obtener la extensión del archivo
    const extension = "." + name.split(".").pop().toLowerCase();

    // Obtener el mimetype correspondiente de acuerdo a la extensión
    const mimetype = mimetypes[extension] || "application/octet-stream";

    return mimetype;
  }

}
