import { Injectable, Inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse  } from '@angular/common/http';
import { SendContactEmail } from '../../models/SendContactEmail';
import { MailRequest } from '../../models/MailRequest';
import { Domains } from '../../models/Domains';
import { GlobalConstans } from '../../global-constants/constants';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  url = "";
  prefix = "";
  controller = "";
  domainId:number;

  constructor(@Inject('BASE_URL') baseUrl: string,private http: HttpClient) {

    this.prefix = "api/";
    this.controller = "payment/";
    this.url = baseUrl + this.prefix + this.controller;

    let jsonConfig = JSON.parse(localStorage.getItem('jsonConfig'));
    if(jsonConfig !== undefined && jsonConfig !== null){
      this.domainId =parseInt(jsonConfig["Id"]);
    }

   }

  charge(token: string, email: string, total: number, products: string, fullName:string): Observable<any>{
    
    const API_URL = `${this.url}charge`;

    let userId = 0;
    const userObject = JSON.parse(localStorage.getItem('currentUser'));
    if (userObject !== null) {
      userId = userObject.Id;
    };

    const $parameters = {
      'Token': token,
      'Email': email,
      'Total': total,
      'DomainId': this.domainId,
      'BarerToken': GlobalConstans.getAuthToken(),
      'UserId': userId,
      'Products': products,
      'FullName': fullName
    };
    console.log({ $parameters });
    debugger;
    return this.http.post<any>(API_URL, $parameters,{ observe: 'response' })
      .pipe(catchError((error): any => {

                return throwError(`Connection Error CHARGE method: ${error}`);
            }
        ));
  
  }
}
