export class EmailConfig
{
    public Mail : string;
    public DisplayName : string;
    public Password : string;
    public Host : string;
    public Port : number;
    public EmailReceiver: string;
    public EmailCopyPurchase: string;
}
