export class Product{
    UserId : number;
    Id : number;
    Name : string;
    ImgSrc : string;
    Price : number;
    Quantity : number;
    Type : number;
    Total : number;
    CompanyId: number;
    RedeemCode: string;
    CategoryId: number;
    CategoryName: string;
    GlobalId: string;
    ContentId: number;
}