import { Address } from "./Address";

export class ContactInfo
{
    constructor()
    {
        this.Address = new Address();
    }

    Schedule:string;
    Address:Address;
    PhoneNumber:string;
    Email:string;

}
