//https://jasonwatmore.com/post/2020/04/19/angular-9-jwt-authentication-example-tutorial#projectstructure
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';

import { UserService } from '../shared/services/user/user.service';
import { AlertService } from '../shared/services/alert/alert.service';
import { AuthenticationService } from '../shared/services/authentication/authentication.service';
import { RequestsService } from '../shared/services/requests.service';

import { User } from '../shared/models/User';
import { GlobalConstans } from '../shared/global-constants/constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';

  constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private service: RequestsService,
        private alertService: AlertService) {

    this.loginForm = new FormGroup({ username: new FormControl(), password: new FormControl() });

   }

    ngOnInit(): void {
      this.loginForm = this.formBuilder.group({
        username: ['', Validators.required],
        password: ['', Validators.required]
    });
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
      
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
        return;
    }

    this.loading = true;
    debugger;
    this.authenticationService.login(this.f.username.value, this.f.password.value)
    .pipe(first())
      .subscribe(
        data => {
          debugger;
          if(data){ return this.router.navigate(['/']);}

          this.error="Username or password is incorrect";
          this.loading = false;
        },
        error => {
            this.error = error;
            this.loading = false;
        });

    
}

}
