import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

//Services
import { CartService } from '../shared/services/cart.service';
import { RequestsService } from '../shared/services/requests.service';

//Models
import { Product } from '../shared/models/Product';
import { Category } from '../shared/models/Category';
import { FeaturedProduct } from '../shared/models/FeaturedProduct';

//Enums
import { ContentType } from '../shared/enums/ContentType';

//Constants
import { GlobalConstans } from '../shared/global-constants/constants';

@Component({
  selector: 'app-added',
  templateUrl: './added.component.html',
  styleUrls: ['./added.component.css']
})
export class AddedComponent implements OnInit {

  customOptions: any = {}
  categories: Array<Category> = new Array();
  loadCategories = false;

  ProductList: Array<FeaturedProduct> = new Array();//FeaturedProduct[];
  loadProducts = false;

  Id: string;
  Product: Product;
  ContentType: number;
  CategoryId: number;

  constructor(private route :ActivatedRoute, private cartService: CartService, private requestService: RequestsService) {
    
    this.route.params.subscribe(parameters => { this.Id = parameters.globalId; });
    
    if(this.Id !== "" && this.Id !== undefined && this.Id !== null){
      this.Product = new Product();
      this.Product = this.cartService._getProductById(this.Id);
    }

    if(this.Product !== null){
      this.ContentType = this.Product.Type;
      this.CategoryId = this.Product.CategoryId;

      if(this.ContentType == ContentType.Course){

        this.requestService.courseHome_GetTopCategories().subscribe((res: Category[]) => { 
          this.categories = res; 
          this.categories.forEach(element => {
            element.ImgSrc = GlobalConstans.returnCategoryUrlImage(element.CompanyId.toString(), element.Id.toString(), element.ImgSrc);
            
           });
          this.loadCategories = true;
          
        }, err => { console.log(err); });

      }else{
        
        this.requestService.examHome_GetTopCategories().subscribe((res: Category[]) => { 
          this.categories = res; 
          this.categories.forEach(element => {
            element.ImgSrc = GlobalConstans.returnCategoryUrlImage(element.CompanyId.toString(), element.Id.toString(), element.ImgSrc);
            
           });
          this.loadCategories = true;
          
        }, err => { console.log(err); });
      }
      

      this.requestService.getRelatedProductsByCategoryId(this.ContentType, this.CategoryId).subscribe((res: FeaturedProduct[]) => { 
        this.ProductList = res; 
        this.ProductList.forEach(element => {
          if(element.Type === 1){
            element.ImgSrc = GlobalConstans.returnCoursesUrlImage(element.CompanyId.toString(), element.Id.toString(), element.ImgSrc);
          }else{
            element.ImgSrc = GlobalConstans.returnExamsUrlImage(element.CompanyId.toString(), element.Id.toString(),element.ImgSrc);
          }
          this.loadProducts = true;
         });
      }, err => { console.log(err); });
    }
    
   }

  ngOnInit(): void {
    this.customOptions = {
      loop: false,
      mouseDrag: false,
      touchDrag: false,
      pullDrag: false,
      margin:15,
      dots: false,
      navSpeed: 700,
      navText: ['<i class="fa fa-arrow-left"></i>', '<i class="fa fa-arrow-right"></i>'],
      nav: true,
      responsive: {
        0: {
          items: 1
        },
        400: {
          items: 2
        },
        740: {
          items: 3
        },
        940: {
          items: 3
        }
      },
    }
  }

  addProduct(model: any){
    this.cartService._addProductToCart(model);
  }

}
