import { Component, Injectable, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { DiscountCoupon } from '../shared/models/DiscountCoupon';
import { AlertService } from '../shared/services/alert/alert.service';
import { CouponsService } from '../shared/services/coupons/coupons.service';

//===========================================================================

function padNumber(value: number | null) {
  if (!isNaN(value) && value !== null) {
    return value.toString().length === 1 ? `0${value}`.slice(-4) : `${value}`.slice(-4);
  } else {
    return '';
  }
}

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct | null {
    if (value) {
      const dateParts = value.trim().split('-');

      let dateObj: NgbDateStruct = { day: <any>null, month: <any>null, year: <any>null }
      const dateLabels = Object.keys(dateObj);

      dateParts.forEach((datePart, idx) => {
        dateObj[dateLabels[idx]] = parseInt(datePart, 10) || <any>null;
      });
      return dateObj;
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? `${padNumber(date.month)}/${padNumber(date.day)}/${padNumber(date.year)}` : '';
  }
}
//===========================================================================

@Component({
  selector: 'app-couponmodal',
  templateUrl: './couponmodal.component.html',
  styleUrls: ['./couponmodal.component.css']
})
export class NgbdModalConfirmAutofocus{
    @Input() public coupon;
    couponForm: FormGroup;
  model: DiscountCoupon = new DiscountCoupon();
  clonedCoupon: DiscountCoupon = new DiscountCoupon();
    isCreation = true;
    submitCouponForm = false;
    
    couponPattern = "[A-Z0-9^\s]+";
  invalidDueDate = false;
  minDate: { year: number, month: number, day: number };
  dueDate: NgbDate;
  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private service: CouponsService,
    private alertService: AlertService,
    private calendar: NgbCalendar  ) {

    const current = new Date();
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate() + 1
    };

    //this.dueDate = this.calendar.getToday();

    this.couponForm = new FormGroup({
      Coupon: new FormControl(),
      DueDate: new FormControl(),
      Discount: new FormControl()
    });

    this.couponForm = this.formBuilder.group({
      Coupon: [this.model.Coupon, [Validators.required, Validators.pattern(/^(?:[A-Z0-9]+)?$/), Validators.minLength(5), Validators.maxLength(25)]],
      DueDate: [this.model.DueDate, Validators.required],
      Discount: [this.model.Discount, [Validators.required, Validators.min(1), Validators.max(100)]],
    });
  }

  saveModal() {
    
    this.submitCouponForm = true;
    this.model.DueDate = new Date(this.couponForm.controls.DueDate.value.year, (this.couponForm.controls.DueDate.value.month - 1), this.couponForm.controls.DueDate.value.day);//this.minDate;

    if (!this.isCreation && this.model.Coupon === this.clonedCoupon.Coupon) {
      this.exec(this.model);
    } else {
      this.service.validateExistingCoupon(this.model)
        .subscribe((response) => {

          if (response !== null && response.Id !== 0) {

            let $title = "Sorry!";
            let $content = "You already have an active discount coupon with the same name ";

            this.alertService.onError($title, $content).then((result) => {
              if (result.value) {
                this.submitCouponForm = false;
                return true;
              }
            });

          } else {
            
            this.exec(this.model);
          }
        });
    }

    

   
  }

  ngOnInit(): void {
    
    if (this.coupon.Id !== 0) {
      this.model = this.coupon;
      this.clonedCoupon = Object.assign({}, this.model);
      var d = new Date(this.model.DueDate);
      this.dueDate = new NgbDate(d.getFullYear(), d.getMonth() + 1, d.getDate());
      this.isCreation = false;
    }
    

  }

  get cf() { return this.couponForm.controls; }

  onDateSelection(date: NgbDate) {

    let selectedDate = date.month + "/" + date.day + "/" + date.year;
    console.log(this.model.DueDate);

  }

  closeModal() {
    this.modal.close();
  }

  errorAlert() {
    let $title = "Sorry!";
    let $content = this.isCreation
      ? "We cannot create your discount coupon, try again later."
      : "We cannot update your discount coupon, try again later.";

    this.alertService.onError($title, $content).then((result) => {
      if (result.value) {
        this.submitCouponForm = false;
        this.closeModal();
        return true;
      }
    });
  }

  successAlert() {
    this.closeModal();

    let $title = "Done!";
    let $content = this.isCreation ? "Your coupon was created successfully!" : "Your coupon was updated successfully!";

    this.alertService
      .onConfirm($title, $content)
      .then((result) => {
        if (result.value) {
          this.submitCouponForm = false;

        }
      });
  }

  exec(data: DiscountCoupon) {
    this.service[this.isCreation ? "addCoupon" : "editCoupon"](data)
      .subscribe((res: any) => {

        if (res) {
          this.successAlert();
        }


      }, err => {
        this.errorAlert();
      });
  }
}
