import { Injectable } from '@angular/core';
import { Observable, of as observableOf, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse  } from '@angular/common/http';
import { GlobalConstans } from '../global-constants/constants';
import { CookieService } from 'ngx-cookie-service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
//import { Observable, of as observableOf } from 'rxjs';

//Interfaces
import { IProduct } from '../interfaces/IProduct';
import { THIS_EXPR, ReturnStatement } from '@angular/compiler/src/output/output_ast';

//Models
import { Product } from '../models/Product';
import { CheckoutConfirmation } from '../models/CheckoutConfirmation';
import { Domains } from '../models/Domains';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  apiUrl: string = GlobalConstans.apiURL;
  userId: any;
  appSettings: Domains; 

  constructor(private http: HttpClient, private cookieService: CookieService,private route: Router) {
    this.userId = GlobalConstans.returnGUID();
    this.appSettings = new Domains();
    //this.appSettings.OrganizationId = 1;

    const jsonConfig = JSON.parse(localStorage.getItem('jsonConfig'));
    if (jsonConfig !== undefined && jsonConfig !== null) {
      this.appSettings.OrganizationId = jsonConfig.OrganizationId;
    }

   }

//************************************************************************************************************************* */
// Database
//************************************************************************************************************************* */

  AddProductToCart(data: IProduct): Observable<any> {
    
    if(typeof this.userId !== 'number'){
      let API_URL = `${this.apiUrl}/AddProductToCart`;
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers };
      return this.http.post(API_URL, data, options).pipe(catchError(this.error));
    }

    //return this.addToLocalstorage(this.userId,data);
   
  }

  getProductsCart(): Observable<IProduct[]>{
    if(typeof this.userId !== 'number'){
      let API_URL = `${this.apiUrl}/GetProductsCart/${5}`;
      return this.http.get<IProduct[]>(API_URL).pipe(catchError(this.error));
    }
    
  }

  updateProductToCart(data: IProduct): Observable<any> {
    let API_URL = `${this.apiUrl}/UpdateProductToCart`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
   return this.http.post(API_URL, data, options).pipe(catchError(this.error));
  
  }

  deleteProductToCart(productId: number): Observable<any> {
    let userId = 5;
    let API_URL = `${this.apiUrl}/DeleteProductToCart/${userId}/${productId}`;

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    return this.http.post(API_URL, options).pipe(catchError(this.error));
  
  }

//************************************************************************************************************************* */
// cookies
//************************************************************************************************************************* */

private _checkIfCookieExists(){
  
  let exist = this.cookieService.check(GlobalConstans.cookieId);
  if(!exist){
    this.cookieService.set( GlobalConstans.cookieId, undefined );
  }

}

_addProductToCart(model: any){
  
  let products = this._getProductsCart();
  let exist = false;
  if(model == null) return;

  let data = new Product();
  data.Id = model.Id;
  data.Quantity = 1;
  data.Price = model.Price;
  data.Total = (data.Quantity * data.Price)
  data.CompanyId = model.CompanyId;
  data.Type = model.Type;
  data.Name = model.Name;
  data.ImgSrc = model.ImgSrc;
  data.CategoryId = model.CategoryId;
  data.CategoryName = model.CategoryName;
  data.GlobalId = model.GlobalId;

  products.forEach((element,key) => { if(element.Id === data.Id){ exist = true; return;} });

  if(!exist){ products.push(data); }

  this.cookieService.set( GlobalConstans.cookieId, JSON.stringify(products) );
  this.route.navigate(['/added',data.GlobalId]);
}

_getProductsCart(){
  this._checkIfCookieExists();

  let products = new Array<Product>();

  let currentData = this.cookieService.get(GlobalConstans.cookieId);
  
  if(currentData != "undefined") products = JSON.parse(currentData);

  return products;
}

_updateProductToCart(data: Product){

  let products = this._getProductsCart();
  
  products.forEach((element,key) => {
      if(element.Id === data.Id){
        element.Quantity = data.Quantity;
        element.Price = data.Price;
        element.Total = (element.Quantity * element.Price);
        return;
      }
    });

  this.cookieService.set( GlobalConstans.cookieId, JSON.stringify(products) );
  return true;

}

_deleteProductToCart(globalId: string){
  let products = this._getProductsCart();
  
  let index = products.findIndex(p => p.GlobalId === globalId );
  products.splice(index, 1);

  this.cookieService.set( GlobalConstans.cookieId, JSON.stringify(products) );
  return true;

}

_deleteAllProducts(){
  this.cookieService.deleteAll();
  return true;
}

_getProductById(globalId: string){
  let data = this._getProductsCart();
  if(data.length == 0) return new Product();

  return data.find(c => c.GlobalId === globalId);
}

//************************************************************************************************************************* */
// Generate Orders
//************************************************************************************************************************* */

  generateOrder(coupons: string, discountTotal: number): Observable<any> {
  
  const products = this._getProductsCart();
  let userId = 0;
  const userObject = JSON.parse(localStorage.getItem('currentUser'));
  if(userObject !== null)
  {
    userId = userObject.Id;
  };
    
    const data = {
      'UserId': userId,
      'OrganizationId': this.appSettings.OrganizationId,
      'ProductList': products,
      'Coupons': coupons,
      'DiscountTotal': discountTotal
    };

  const API_URL = `${this.apiUrl}/GenerateOrder`;
  const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  const options = { headers: headers };
  
  return this.http.post(API_URL, data, options).pipe(catchError(this.error));

}

getOrderDetailsBySlug(slug: string): Observable<CheckoutConfirmation>{

  let API_URL = `${this.apiUrl}/GetOrderDetailsBySlug`;
  let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  let options = { headers: headers };

  let data = JSON.stringify({Slug: slug});
  
 return this.http.post<CheckoutConfirmation>(API_URL,data,options).pipe(catchError(this.error));
}

   // Handle Errors 
   error(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }


}
