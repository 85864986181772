import { Component, OnInit, Type } from '@angular/core';

//Constants
import { GlobalConstans } from '../shared/global-constants/constants';

//Service
import { CartService } from '../shared/services/cart.service';
import { ToastService } from '../shared/services/toast/toast.service';

//Interfaces
import { IProduct } from '../shared/interfaces/IProduct';

//Enum
import { ContentType } from '../shared/enums/ContentType'

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  courseContent = 0;
  examContent = 0;
  allContent = 0;
  
  cart: IProduct[];
  totalPrice: number;
  editCart = false;

  constructor(private cartService: CartService, private toastService: ToastService) { 
    this.courseContent = ContentType.Course;
    this.examContent = ContentType.Exam;
    this.allContent = ContentType.AllContent;
    this.loadCart();
  }

  ngOnInit(): void {
  }

  deleteProduct(globalId: string){

    let result = this.cartService._deleteProductToCart(globalId);
    if(result){
      this.toastService.onInfo("Deleted!","Your product has been deleted correctly!");
      this.loadCart();
      return;
    }

    return this.toastService.onWarning("Ups","Something wrong is happening!");
  }

  clearCart(){
    let result = this.cartService._deleteAllProducts();
    if(result){
      this.toastService.onInfo("Cleaned!","You shopping cart is empty now");
      this.loadCart();
      return;
    }

    return this.toastService.onWarning("Ups","Something wrong is happening!");
  }

  enableUpdateCart() {
    this.editCart = true;
  }

  updateCart(product) {
    
    const result = this.cartService._updateProductToCart(product);
    if (result) {
      this.toastService.onInfo("Perfect", "Quantity has been updated correctly!");
      return;
    }

    return this.toastService.onWarning("Ups", "Something wrong is happening!");
    
  }

  doneCart() {
    this.editCart = false;
    this.loadCart();
  }

  private loadCart(){

    let res = this.cartService._getProductsCart();
    this.cart = res;
    this.totalPrice = this.cart.reduce((a,b) => a + b.Total,0);
  }

}
