export class DiscountCoupon {
  constructor() {
    this.Id = 0;
    this.Coupon = "";
    this.Discount = 0;
    this.DueDate = new Date();
  }
  Id?: number;
  Coupon: string;
  Discount: number;
  Status?: number;
  IsDeleted?: boolean;
  DueDate: Date;
  CreatedDate?: Date;
  ModifiedDate?: Date;
  ReleasedDate?: Date;
  OrganizationId?: number;
  Subtotal?: number;
  TotalRedemptions?: number;
}
