export class MailRequest{
    UserId: number;
    Name: string;
    LastName: string;
    Email: string; //Cuenta de la persona que esta enviando el correo
    Username: string;
    Subject : string;
    Body : string;
    Template : string;
    ToEmail : string; //¿A que cuenta se va enviar el correo?
    DomainId: number;
    EmailType: number;
    ProductList: string[];
    CurrentUrl: string;
    BearerToken: string;
    OrderId: string;
}
