import { Text } from './Text';
import { Button } from './Button';
import { ImageItem } from './ImageItem';

export class Banner {
    
    constructor () {
        //this.ImageList = new Array();
        this.ButtonObject = new Button();
        this.TextObject = new Text();
    }
    
    public Image: ImageItem;
    
    public Style: string;
    
    public EnableButton: boolean;
    
    public ButtonObject: Button;
    
    public EnableText: boolean;
    
    public TextObject: Text;

    public Toogle : boolean;
}