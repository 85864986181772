import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DiscountCoupon } from '../shared/models/DiscountCoupon';
import { UsersByCoupon } from '../shared/models/UsersByCoupon';
import { CouponsService } from '../shared/services/coupons/coupons.service';

@Component({
  selector: 'app-usersbycoupon',
  templateUrl: './usersbycoupon.component.html',
  styleUrls: ['./usersbycoupon.component.css']
})
export class UsersbycouponComponent implements OnInit {

  @Input() public coupon;
  model: DiscountCoupon = new DiscountCoupon();

  //DT variables
  page = 1;
  pageSize = 25;
  collectionSize = 0;

  //order vars
  orderFistName = false;
  orderLastName = false;
  orderEmail = false;
  orderTotal = false;

  // Table vars
  users: Array<UsersByCoupon> = new Array();
  usersCopy: Array<UsersByCoupon> = new Array();

  constructor(private service: CouponsService,
    public modal: NgbActiveModal  ) { }

  ngOnInit(): void {
    this.model = this.coupon;
    this.onGetUsersByCoupon(this.model.Id);
  }

  onGetUsersByCoupon(couponId:number) {
    this.service.getUsersByCoupon(couponId)
      .subscribe((res: UsersByCoupon[]) => {
        this.users = res;
        this.usersCopy = res;
      //this.loadCoupons = true;
        this.collectionSize = this.users.length;
        console.log(this.users);
    }, err => { console.log(err); });
  }

  get result(): UsersByCoupon[] {
    if (this.users !== undefined) {
      return this.users.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
    }
  }

  countDates(dates:string) {
    return JSON.parse(dates).length;
  }

  showDates(user: UsersByCoupon) {
    user.Expand = !user.Expand;
  }
  getDates(dates: string) {
    return JSON.parse(dates);
  }

  parseDate(date) {
    var d = new Date(date)

    if (d.getFullYear() === 1) {
      return " - - - ";
    } else {
      return d.toLocaleDateString("en-US");
    }
  }

  onSortFirstName() {
    this.orderFistName = !this.orderFistName;

    if (this.orderFistName) {
      // Ascending
      this.users = this.users.sort((a, b) => 0 - (a.FirstName > b.FirstName ? -1 : 1));
    } else {
      // Descengind
      this.users = this.users.sort((a, b) => 0 - (a.FirstName > b.FirstName ? 1 : -1));
    }

  }

  onSortLastName() {
    this.orderLastName = !this.orderLastName;

    if (this.orderLastName) {
      // Ascending
      this.users = this.users.sort((a, b) => 0 - (a.LastName > b.LastName ? -1 : 1));
    } else {
      // Descengind
      this.users = this.users.sort((a, b) => 0 - (a.LastName > b.LastName ? 1 : -1));
    }

  }

  onSortEmail() {
    this.orderEmail = !this.orderEmail;

    if (this.orderEmail) {
      // Ascending
      this.users = this.users.sort((a, b) => 0 - (a.Email > b.Email ? -1 : 1));
    } else {
      // Descengind
      this.users = this.users.sort((a, b) => 0 - (a.Email > b.Email ? 1 : -1));
    }

  }

  onSortTotal() {
    this.orderTotal = !this.orderTotal;

    if (this.orderTotal) {
      // Ascending
      this.users = this.users.sort((a, b) => 0 - (this.getDates(a.RedemptionsDate).length > this.getDates(b.RedemptionsDate).length ? -1 : 1));
    } else {
      // Descengind
      this.users = this.users.sort((a, b) => 0 - (this.getDates(a.RedemptionsDate).length > this.getDates(b.RedemptionsDate).length ? 1 : -1));
    }

  }

  searchText(search: string) {

    if (search === '') {
      this.users = this.usersCopy;
      return;
    }

    this.users = this.users.filter((a) => a.FirstName.match(search) || a.LastName.match(search) || a.Email.match(search));
  }

  //Modal functions
  closeModal() {
    this.modal.close();
  }

}
