import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { GlobalConstans } from '../shared/global-constants/constants';
import { Banner } from '../shared/models/Banner';
import { RequestsService } from '../shared/services/requests.service';
import { AzurestorageService } from '../shared/services/azurestorage/azurestorage.service';
import { AlertService } from '../shared/services/alert/alert.service';
import { ImageItem } from '../shared/models/ImageItem';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ButtonPosition } from '../shared/enums/ButtonPosition';


@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {
  
    /**
   * Variables para Image: azure storage
   */
  bannerfiles: string[] = [];  
  bannerfileToUpload: FormData;  
  bannerfileUpload: any;  
  bannerfileUpoadInitiated: boolean;  
  bannerfileDownloadInitiated: boolean;
  bannerfileName: string;  
  /**
   * TERMINA: Variables para Image: azure storage
   */
  ImagesList: Array<Banner> = new Array();
  public showImages = false;
  submitted = false;
  uploadingImage = false;

  enableCourses = false;
  enableExams = false;

  //positions: any;
  positions: { id: number; name: string }[] = [];
  constructor(
    private service: RequestsService,
    private azureClient: AzurestorageService,
    private alert: AlertService,
    private rutas : Router) {

      const userObject = JSON.parse(localStorage.getItem('currentUser'));
      if(userObject === null)
      {
        this.rutas.navigate(['/index']); 
      }

    for (const n in ButtonPosition) {
      if (typeof ButtonPosition[n] === 'number') {
        this.positions.push({ id: <any>ButtonPosition[n], name: n });
      }
    }

    console.log({'positions':this.positions});

    }

  ngOnInit(): void {

    const config = JSON.parse(localStorage.getItem('jsonConfig'));

    this.enableCourses = config.EnableCourses;
    this.enableExams = config.EnableExams;

    this.getBannerImages();
  }

  getBannerImages(){

    this.showImages = false;
    this.ImagesList = new Array();

    this.service.GetBannerForEdit()
    .subscribe((images : Array<Banner>) => {
      
      if(images.length > 0){
        this.showImages = true;
        this.ImagesList = images;
        
      }

    })
  }


  /**
   * Método para cambiar el status de la imagen
   * @param item Elemento actual del foreach
   */
  onChangeImageStatus(item:Banner){
    let isChecked = item.Image.Enable;

    let $title = !isChecked ? 'Enable image' : 'Disabled image';
    let $text = !isChecked 
    ? 'Do you want to enable the current image?'
    : 'Do you want to disabled the current image?'
    let $button = {'ConfirmButtonText': 'Change', 'cancelButtonText': 'Cancel'};

    this.alert.onBeforeConfirm($title, $text, $button)
    .then((response) => {
      
      if(response.value === true){
        this.service.UpdateImageStatus(item, !isChecked)
        .subscribe((res) => {
          if(res.Success){
            item.Image.Enable = !isChecked;
            this.alert.onConfirm("Perfect!","You have change the image status successfully");
            
          }
          
        });

      }else{
        
        item.Image.Enable = isChecked;
      }
      
    });
  }

  /**
   * Método para eliminar una imagen de la galería
   * @param item Elemento actual del foreach
   */
  onDeleteImages(item:Banner){

    let $title = "Delete image";
    let $text = "Do you want to delete the current image? You won't be able to recover it"
    let $button = {'ConfirmButtonText': 'Delete', 'cancelButtonText': 'Cancel'};

    this.alert.onBeforeConfirm($title, $text, $button)
    .then((response)=>{
      if(response.value === true){
        
        let fileName = item.Image.Id;
        let extension = item.Image.Src.split(".").pop();
        fileName = fileName +'.'+extension;

      this.azureClient.DeleteBannerImage(fileName)
      .subscribe((d) => {

        if(d){
          this.service.DeleteImageFromBanner(item)
          .subscribe((a) => {
              if(a.Success){
                this.getBannerImages();
                this.alert.onConfirm("Done","We already deleted your image");
              }
          });
        }

      });

      }else{
        this.alert.onError("Ups!","We couldn't delete your image, please try again later.")
      }
    });

  }

  returnBannerImg(image : string){
    return GlobalConstans.GetBannerImage(image);
  }

  onSaveImage(image){

    this.submitted = true;

    if(!image.ButtonObject.IsExternal){
      image.ButtonObject.Action = null;
    }

    this.service.SaveImage(image).subscribe((res:any) => {
      if(res.Success){
        this.submitted = false;
        this.getBannerImages();
        return this.alert.onConfirm("Done!","Updated correctly!");
      }
    })

  }

  onChangeSelectItem(item){
    
    item.ButtonObject.IsExternal = item.ButtonObject.Type === "external";

  }

  onChangePosition(item) {

    //item.ButtonObject.IsExternal = item.ButtonObject.Type === "external";
    console.log({ item });

  }

  addAnSpace(text) {
    return text.replace(/([A-Z])/g, ' $1');
  }
  //========================================================================
  // Logotipo
  //========================================================================
  banneraddFile() {  
    if (!this.bannerfileUpoadInitiated) {  
      document.getElementById('banneruploadImgBanner').click();  
    }  
  }

  bannerhandleFileInput(files: any) {  
    
    const allowed_types = ['image/png', 'image/jpeg','image/gif']
    if(files.length > 0){

      let found = allowed_types.find(x => x.includes(files[0].type))

      if (!found) {
        this.alert.onError("Ups!","Only Images are allowed ( JPG | PNG | GIF )");
        return;
      }
    }
    
    //Validar size
    const max_height = 1200;
    const max_width = 1900;
    let reader = new FileReader();
      reader.readAsDataURL(files[0]); 
      reader.onload = (event) => { 
      var img = new Image();
      img.src = event.target.result as string;
      img.onload = rs => {
        const img_height = rs.currentTarget['height'];
        const img_width = rs.currentTarget['width'];
        
        if (img_height > max_height && img_width > max_width) {
            this.alert.onError("Ups!",'Maximum dimentions allowed: width ' + max_width  + 'px * height ' + max_height + 'px');
            return;
        } else {
          
          let formData: FormData = new FormData();  
          formData.append("asset", files[0], files[0].name);  
          if(files.length > 0){
            this.bannerfileName = files[0].name;
          }
          this.bannerfileToUpload = formData;  
          this.banneronUploadFiles();  
        }
      };

      }
    
    //END: Validar size
    
  }

  banneronUploadFiles() {  
    if (this.bannerfileUpoadInitiated) {  
      return;  
    }  
    this.bannerfileUpoadInitiated = true;  
    if (this.bannerfileToUpload == undefined) {  
      this.bannerfileUpoadInitiated = false;  
      return false;  
    }  
    else {  
      this.uploadingImage = true;
      this.azureClient.UploadBannerImage(this.bannerfileToUpload)
      .subscribe((response: any) => {  
        this.bannerfileUpoadInitiated = false;  
        this.bannerfileUpload = ''; 
        if (response.body.success) {  
          
          let image = new ImageItem();
          image.Id = response.body.code;
          image.Src = GlobalConstans.GetBannerImage(response.body.message);
          
          let item = new Banner();
          item.Image = image;

          this.service.InsertBannerImage(item)
          .subscribe((result) => {
              
              if(result){
                this.uploadingImage = false;
                this.getBannerImages();
                this.alert.onConfirm("Perfect!","Your image has been uploaded successfully")
                .then((a) => {
                  
                });
                
              }
          });
          
        }  
        else {  
          this.bannerfileUpoadInitiated = false; 
          this.uploadingImage = false; 
        }  
      },  
      
        err => console.log(err),  
      ); 

    }  
  }

  DeleteBannerImage(fileName: string) {  
    
    this.azureClient.DeleteBannerImage(fileName)
    .subscribe(result => {  
      if (result != null) {  
        //this.showBlobs();  
        return true;
      }  
    }, error => console.error(error));  
  }
  //========================================================================
  // END: Logotipo
  //========================================================================

}

