import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';

//Interfaces
import { ICategory } from '../shared/interfaces/categories';
import { ILanguage } from '../shared/interfaces/language';
import { ILevel } from '../shared/interfaces/ILevel';
import { ICourses } from '../shared/interfaces/courses';
import { IFeaturedProducts } from '../shared/interfaces/featured-products';
import { ITests } from '../shared/interfaces/tests';

//Models
import { Search } from '../shared/models/Search';
import { Category } from '../shared/models/Category';
import { Language } from '../shared/models/Language';
import { Level } from '../shared/models/Level';
import { FeaturedProduct } from '../shared/models/FeaturedProduct';
import { Product } from '../shared/models/Product';
import { Course } from '../shared/models/Course';

//Service
import { RequestsService } from '../shared/services/requests.service';
import { CartService } from '../shared/services/cart.service';
import { ToastService } from '../shared/services/toast/toast.service';

//Constans
import { GlobalConstans } from '../shared/global-constants/constants';

//Enums
import { ContentType } from '../shared/enums/ContentType';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  public isCollapsed = false;
  public orderByContentType = 0;
  public sortAlpha = false;
  disabled = false;
  ShowFilter = false;
  appliedFilters = true;
  limitSelection = false;
  

  contentType: Array<any> = [];
  checkContentType = false;
  contentTypeButton = false;

  languagesLoaded = false;
  checkLanguages = false;
  languagesButton = false;
  
  categoriesLoaded = false;
  checkCategories = false;
  categoriesButton = false;

  levelsLoaded = false;
  checkLevels = false;
  levelsButton = false;

  model: Search;
  type: number;

  page = 1;
  pageSize = 10;
  collectionSize = 0;

  courses: ICourses[];
  exams: ITests[];
  allContent: IFeaturedProducts[];

  loaded = false;
  noResults = true;

  enableCourses = true;
  enableExams = true;

  constructor(
    private route: ActivatedRoute,
    private service: RequestsService,
    private cartService: CartService,
    private toastService: ToastService) { 
    //====================================================================================================================================
    // Declarations
    //====================================================================================================================================
    this.model = new Search();
    this.allContent = new Array<IFeaturedProducts>();
    this.courses = new Array<ICourses>();
    this.exams = new Array<ITests>();
    this.contentType.push(
      {'Id': 0, 'Name': 'All content', 'Value': 0, 'Checked': false},
      {'Id': 2, 'Name': 'Courses', 'Value': 1, 'Checked': false},
      {'Id': 1, 'Name': 'Exams', 'Value': 2, 'Checked': false}
      );

    //====================================================================================================================================
    // END: Declarations
    //====================================================================================================================================

    //====================================================================================================================================
    // Getting parameters
    //====================================================================================================================================
    
    let contentType = parseInt(this.route.snapshot.paramMap.get('contentType'));
    this.checkContent({'Id': contentType});
    
    let paramCategoryId = this.route.snapshot.paramMap.get('categoryId');
    let categoryId = 0;
    if(paramCategoryId !== null){
      categoryId = parseInt(paramCategoryId);
    }

    let txtSearch = this.route.snapshot.paramMap.get('search');
    this.model.Text = txtSearch;
    //====================================================================================================================================
    // END: Getting parameters
    //====================================================================================================================================

    //====================================================================================================================================
    // Getting filters
    //====================================================================================================================================
    let loadCategories = this.service.loadCategories();
    let loadLanguages = this.service.loadLanguages();
    let getcourseLevels = this.service.getCourseLevels();
    
    forkJoin([loadCategories, loadLanguages, getcourseLevels]).subscribe(results => {
        let categories = results[0];
        let languages = results[1];
        let levels = results[2];

      this.model.CategoryList = categories;
      
        if(categoryId !== 0){
          this.model.CategoryList.find(c => c.Id === categoryId).Checked = true;
        }
        this.categoriesLoaded = true; 

        
        this.model.LanguageList = languages;
        this.languagesLoaded = true;

        
        this.model.LevelList = levels;
        this.levelsLoaded = true;
        
        this.allContent = new Array<IFeaturedProducts>();
        this.executeSearch(this.model, this.allContent);
    });
    //====================================================================================================================================
    // END: Getting filters
    //====================================================================================================================================
    
  }

  ngOnInit(): void {

    let config = JSON.parse(localStorage.getItem('jsonConfig'));
    this.enableCourses = config.EnableCourses;
    this.enableExams = config.EnableExams;
    
  }

  // ====================================================================================
  // FILTER FUNCTIONS
  // ====================================================================================

  searchText(text: string){
    this.model.Text = text;
    this.allContent = new Array<IFeaturedProducts>(); //TODO: Necesito averiguar como mejorar este proceso para limpiarlo en cada busqueda de mejor manera
    this.executeSearch(this.model, this.allContent);
  }

  checkContent(content: any){
    this.model.ContentType = content.Id;
    
    if(content.Id === ContentType.AllContent){
      
      let checked = this.contentType.find(x => x.Id === content.Id).Checked;
      this.contentType.map(x => x.Checked = !checked);

      if(checked){
        this.allContent = new Array<IFeaturedProducts>();
        this.loaded = true;
        this.noResults = true;
        return;
      }
    }else{
      
      let find = this.contentType.find(x => x.Id === content.Id);
      find.Checked = !find.Checked;

      let otherContent = find.Id === ContentType.Exam ? ContentType.Course : ContentType.Exam;
      let findOtherContent = this.contentType.find(x => x.Id === otherContent).Checked;

      if(findOtherContent && !this.contentType.find(x => x.Id === ContentType.AllContent).Checked){
        this.model.ContentType = ContentType.AllContent;
        this.contentType.map(x => x.Checked = true);
      }

      if(!this.contentType.find(x => x.Id === content.Id).Checked || !this.contentType.find(x => x.Id === otherContent).Checked){
        
          this.contentType.find(x => x.Id === ContentType.AllContent).Checked = false;
        
      }

    }
    
    this.allContent = new Array<IFeaturedProducts>(); //TODO: Necesito averiguar como mejorar este proceso para limpiarlo en cada busqueda de mejor manera
    this.executeSearch(this.model, this.allContent);

  }

  categoryListFiltered(){
    return this.model.CategoryList.filter(x => x.Checked);
  }

  checkCategory(category: any) {
    
    let find = this.model.CategoryList.find(x => x.Id === category.Id);
    find.Checked = !find.Checked;

    if (find.ParentId === 0) {
      this.model.CategoryList.forEach(sub => {

        if (sub.ParentId !== 0 && sub.ParentId === find.Id) {
          sub.Checked = !sub.Checked;
        }

      });
    }

    
    this.allContent = new Array<IFeaturedProducts>(); //TODO: Necesito averiguar como mejorar este proceso para limpiarlo en cada busqueda de mejor manera
    this.executeSearch(this.model, this.allContent);
  }

  checkAllCategories(){
    this.checkCategories = !this.checkCategories;
    this.model.CategoryList.map(x => x.Checked = this.checkCategories);

    this.allContent = new Array<IFeaturedProducts>(); //TODO: Necesito averiguar como mejorar este proceso para limpiarlo en cada busqueda de mejor manera
    this.executeSearch(this.model, this.allContent);
  }

  languageListFiltered(){
    return this.model.LanguageList.filter(x => x.Checked);
  }

  checkLanguage(language: any){
    let find = this.model.LanguageList.find(x => x.Id === language.Id);
    find.Checked = !find.Checked;

    this.allContent = new Array<IFeaturedProducts>(); //TODO: Necesito averiguar como mejorar este proceso para limpiarlo en cada busqueda de mejor manera
    this.executeSearch(this.model, this.allContent);
  }

  checkAllLanguages(){
    this.checkLanguages = !this.checkLanguages;
    this.model.LanguageList.map(x => x.Checked = this.checkLanguages);

    this.allContent = new Array<IFeaturedProducts>(); //TODO: Necesito averiguar como mejorar este proceso para limpiarlo en cada busqueda de mejor manera
    this.executeSearch(this.model, this.allContent);
  }

  levelListFiltered(){
    return this.model.LevelList.filter(x => x.Checked);
  }

  checkLevel(level: any){
    let find = this.model.LevelList.find(x => x.Id === level.Id);
    find.Checked = !find.Checked;

    this.allContent = new Array<IFeaturedProducts>(); //TODO: Necesito averiguar como mejorar este proceso para limpiarlo en cada busqueda de mejor manera
    this.executeSearch(this.model, this.allContent);
  }

  checkAllLevels(){
    this.checkLevels = !this.checkLevels;
    this.model.LevelList.map(x => x.Checked = this.checkLevels);

    this.allContent = new Array<IFeaturedProducts>(); //TODO: Necesito averiguar como mejorar este proceso para limpiarlo en cada busqueda de mejor manera
    this.executeSearch(this.model, this.allContent);
  }

  searchByPrice(){
    this.allContent = new Array<IFeaturedProducts>(); //TODO: Necesito averiguar como mejorar este proceso para limpiarlo en cada busqueda de mejor manera
    // console.log(this.model.MinPrice);
    // console.log(this.model.MaxPrice);

    // let minPrice = typeof this.model.MinPrice === "number";
    // let maxPrice = typeof this.model.MaxPrice === "number";
    this.executeSearch(this.model, this.allContent);
  }

  filterSubcategories(categoryId, categoryList) {

    if (categoryId === 0 || categoryList === undefined) return;

    var subCategoryList = [];
    
    categoryList.forEach(sub => {

      if (sub.Id === categoryId) {
        subCategoryList.push(sub)
      }

    });

    return subCategoryList;

  }

  hasSubcategories(categoryId, categoryList) {

    if (categoryId === 0 || categoryList === undefined) return;

    var subCategoryList = [];

    categoryList.forEach(sub => {

      if (sub.ParentId === categoryId) {
        subCategoryList.push(sub)
      }

    });
    
    return subCategoryList.length > 0;

  }

  // ====================================================================================
  // PRIVATE FUNCTIONS
  // ====================================================================================

  addProduct(model: any){

    this.cartService._addProductToCart(model);
    
  }

  _searchCourses(model: any, allContent: any) {
    
    this.service.searchCourses(model).subscribe((res: ICourses[]) => {
      this.courses = res;
      this.collectionSize = this.courses.length;
      
      this.courses.map(function(course){
        let item = new FeaturedProduct();
        item.Type = ContentType.Course;
        item.Id = course.Id;
        item.Name = course.Name;
        item.ImgSrc = GlobalConstans.returnCoursesUrlImage(course.CompanyId.toString(), course.Id.toString(), course.ImgSrc);
        item.Summary = course.Summary;
        item.CategoryName = course.CategoryName;
        item.Level = course.Level;
        item.Price = course.Price;
        item.OldPrice = course.OldPrice;
        item.GlobalId = course.GlobalId;
        item.ComingSoon = course.ComingSoon;
        allContent.push(item);
      });
      
      this.loaded = true;
      this.noResults = this.collectionSize === 0;
    }, err => { console.log(err); });
  }

  _searchExams(model: any, allContent: any){
    this.service.searchExams(model).subscribe((res: ITests[]) => { 
      this.exams = res;
      this.collectionSize = this.exams.length;
      this.exams.forEach(exam => {

        let item = new FeaturedProduct();
        item.Type = ContentType.Exam;
        item.Id = exam.Id;
        item.Name = exam.Name;
        item.ImgSrc = GlobalConstans.returnExamsUrlImage(exam.CompanyId.toString(), exam.Id.toString(),exam.ImgSrc);
        item.Description = exam.Description;
        item.CategoryName = exam.CategoryName;
        item.Price = exam.Price;
        item.OldPrice = exam.OldPrice;
        item.GlobalId = exam.GlobalId;
        item.ComingSoon = exam.ComingSoon;
        allContent.push(item);

      });

      this.loaded = true;
      this.noResults = this.collectionSize === 0;
    }, err => { console.log(err); });
  }


  _searchAllContentV2(model: any, allContent: any){

    let getCourses = this.service.searchCourses(model);
    let getExams = this.service.searchExams(model);
    
    forkJoin([getCourses, getExams]).subscribe(results => {

      this.courses = results[0];
      this.courses.map(function(course){
        let item = new FeaturedProduct();
        item.Type = ContentType.Course;
        item.Id = course.Id;
        item.Name = course.Name;
        item.ImgSrc = GlobalConstans.returnCoursesUrlImage(course.CompanyId.toString(), course.Id.toString(), course.ImgSrc);
        item.Summary = course.Summary;
        item.CategoryName = course.CategoryName;
        item.Level = course.Level;
        item.Price = course.Price;
        item.OldPrice = course.OldPrice;
        item.GlobalId = course.GlobalId;
        allContent.push(item);
      });

      this.exams = results[1];
      this.exams.map(function(exam){
        
        let item = new FeaturedProduct();
        item.Type = ContentType.Exam;
        item.Id = exam.Id;
        item.Name = exam.Name;
        item.ImgSrc = GlobalConstans.returnExamsUrlImage(exam.CompanyId.toString(), exam.Id.toString(),exam.ImgSrc);
        item.Description = exam.Description;
        item.CategoryName = exam.CategoryName;
        item.Price = exam.Price;
        item.OldPrice = exam.OldPrice;
        item.GlobalId = exam.GlobalId;
        allContent.push(item);
      });
      
      this.collectionSize = this.allContent.length;
      this.loaded = true;
      this.noResults = this.collectionSize === 0;

    });

    
  }

  get resultData(): any[] {
    
    let currentType = typeof this.model.ContentType === "number" ? this.model.ContentType : 0 ;
    
    switch (currentType) {
      
      case ContentType.Course:
        if(this.courses !== undefined){    
          this.sortAlpha = this.sortAlpha === undefined ? false : this.sortAlpha;

          if(this.sortAlpha){
            //asc
            this.allContent.sort((a,b) =>  (a.Name > b.Name ? 1 : -1));
            
          }else{
            //desc
            this.allContent.sort((a,b) => (a.Name > b.Name ? -1 : 1))
          }

          return this.allContent.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
        }  
        break;
      
      case ContentType.Exam:
          if(this.exams !== undefined){

            this.sortAlpha = this.sortAlpha === undefined ? false : this.sortAlpha;

          if(this.sortAlpha){
            //asc
            this.allContent.sort((a,b) =>  (a.Name > b.Name ? 1 : -1));
            
          }else{
            //desc
            this.allContent.sort((a,b) => (a.Name > b.Name ? -1 : 1))
          }

            return this.allContent.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
          }
          break;
    
      default:
        if(this.courses !== undefined && this.exams !== undefined){
          
          let orderBy = this.orderByContentType  === undefined ? 0 : this.orderByContentType;
          this.sortAlpha = this.sortAlpha === undefined ? false : this.sortAlpha;
          
          if(orderBy !== 0){
            this.allContent.sort(function(x, y) { return (x.Type === orderBy) ? 0 : x ? -1 : 1; });
          }
          
          this.allContent.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);

          if(this.sortAlpha){
            //asc
            this.allContent.sort((a,b) =>  (a.Name > b.Name ? 1 : -1));
            
          }else{
            //desc
            this.allContent.sort((a,b) => (a.Name > b.Name ? -1 : 1))
          }

          return this.allContent;//.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
        }  
        break;
    }

  }

  get enableFilter(): any{
    return this.contentType.find(x => x.Id === 0).Checked;//this.contentType.find(x => x.ContenttypeId === 0).Cheked;
  }

  changeOrderFilter($event: any){
    this.orderByContentType = parseInt($event);
  }

  // sortingData(){
  //   this.sortAlpha = !this.sortAlpha;
  // }

  executeSearch(model: Search, allContent: any){
    if(model.CategoryList.length === 0 && model.LanguageList.length === 0 && model.LevelList.length === 0) return;
    this.loaded = !this.loaded;
    let searchModel = new Search();
    searchModel.Text = model.Text,
    searchModel.ContentType = model.ContentType,
    searchModel.MinPrice = model.MinPrice,
    searchModel.MaxPrice = model.MaxPrice,
    searchModel.LevelId = model.LevelId,
    searchModel.CategoryList = model.CategoryList.filter(c => c.Checked),
    searchModel.LanguageList = model.LanguageList.filter(c => c.Checked),
    searchModel.LevelList = model.LevelList.filter(c => c.Checked)
    
    switch(searchModel.ContentType){
      case ContentType.Course:
        this._searchCourses(searchModel, allContent);
        break;
      case ContentType.Exam:
        this._searchExams(searchModel, allContent);
        break;
      default:
        this._searchAllContentV2(searchModel, allContent);
        break;
    }

    

  }

  setDefaultPic(product){
if(product.Type === ContentType.Course){
  product.ImgSrc = GlobalConstans.returnCoursesUrlImage("", "", undefined);
}else{
  product.ImgSrc = GlobalConstans.returnExamsUrlImage("", "", undefined);;
}
  }
}

