import { MenuItemBase } from "./MenuItemBase";

export class MenuItem extends MenuItemBase{
    
    constructor() {
        super();
        this.DropdownItems = new Array<MenuItemBase>();
    }
    public DropdownItems : MenuItemBase[];
    Type : number;
}