import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse  } from '@angular/common/http';
import { GlobalConstans } from '../../global-constants/constants';
import { ICountry } from '../../interfaces/ICountry';
import { User } from '../../models/User';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  apiUrl: string = GlobalConstans.apiURL;

  constructor(private http: HttpClient) { }

  getCountries(): Observable<ICountry[]> {
    let API_URL = `${this.apiUrl}/GetCountries`;
    return this.http.get<ICountry[]>(API_URL).pipe(catchError(this.error));
  }

  insertUser(data: User): Observable<any>{
    let API_URL = `${this.apiUrl}/InsertUser`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    data.UrlDomain = document.location.origin;
    debugger;
    return this.http.post<Response>(API_URL, data, options).pipe(catchError(this.error));
  }


  getInit() {
    
    const API_URL = `${GlobalConstans.loginAPP}/getInit`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    const url = document.location.origin;

    const promise =  new Promise<boolean>((resolve) => {
      setTimeout(() => {
        
        this.http.post(API_URL, { 'Url': url }, options).toPromise()
          .then((resp) => {
            localStorage.setItem('jsonConfig', JSON.stringify(resp));

            const auth_url = `${GlobalConstans.loginAPP}/auth`;
            const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
            const username = GlobalConstans.USERNAME;
            const password = GlobalConstans.PASSWORD;

            this.http.post(auth_url, { username, password }, options).toPromise()
              .then((data) => {
                
                localStorage.setItem('access_token', JSON.stringify(data));
                resolve(true);
              }).catch((error) => {
                console.log("NO se resolvio authApp() en user service");
              });


            
          });
      }, 100);
    });

    return promise;
  }

  getInitv2(): Promise<any> {
    const API_URL = `${GlobalConstans.loginAPP}/getInit`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    const url = document.location.origin;

    return this.http.post(API_URL, { 'Url': url }, options).toPromise();
  }

  authApp(): Promise<boolean> {

    const API_URL = `${GlobalConstans.loginAPP}/auth`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    const username = GlobalConstans.USERNAME;
    const password = GlobalConstans.PASSWORD;

    return new Promise<boolean>((resolve) => {
      setTimeout(() => {

        this.http.post(API_URL, { username, password }, options).toPromise()
          .then((data) => {
            localStorage.setItem('access_token', JSON.stringify(data));
            resolve(true);
          }).catch((error) => {
            resolve(false);
            console.log("NO se resolvio authApp() en user service");
            console.log(JSON.stringify(error));
          });

        
      }, 100);
    });

  }

  authAppv2(): Promise<any> {

    const API_URL = `${GlobalConstans.loginAPP}/auth`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    const username = GlobalConstans.USERNAME;
    const password = GlobalConstans.PASSWORD;

    return this.http.post(API_URL, { username, password }, options).toPromise();

  }

  // Handle Errors
  error(error: HttpErrorResponse) {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
        errorMessage = error.error.message;
      } else {
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      console.log(errorMessage);
      return throwError(errorMessage);
    }
}
