export class WebsiteStyle
{
    /**
     *
     */
    constructor() {
        this.PrimaryColor = "#557700";
        this.PrimaryColorBlurry = "#8C2711";
        this.LinkColor = "#7b838a";
        this.PrimaryColor2 = "#ff6c00";
        this.PrimaryColor3 = "#828bb3";
        this.TitleColor = "#222222";
        this.TextColor = "#777777";
        this.BaseColor = "#c5322d";
        this.Dip = "#222222";
        this.Pfont = "#777777";
        this.White = "#fff";
        this.Black = "#c5322d";
        this.Offwhite = "#fcfcfc";
    }
    PrimaryColor: string;
    PrimaryColorBlurry: string;
    LinkColor: string;
    PrimaryColor2: string;
    PrimaryColor3: string;
    TitleColor: string;
    TextColor: string;
    BaseColor: string;
    Dip: string;
    Pfont: string;
    White: string;
    Black: string;
    Offwhite: string;
}