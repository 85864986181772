export class Course{
    Id: number;
    CompanyId: number;
    Name: string;
    ProductId: string;
    Language: string;
    Level: string;
    CategoryId: number;
    Category: string;
    Subcategory: string;
    Summary: string;
    Experts: any;
    Price: number;
    OldPrice: number;
    ImgSrc:string;
    VideoSrc:string;
    HasWorkbook: boolean;
    HasResources: boolean;
    CourseTime: number;
}