import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastr: ToastrService) { }

  options:{
    closeButton: true,
    timeOut: 10000,
    positionClass: 'toast-top-right'
  };

  onSuccess(title: string, message: string){
    this.toastr.success(message, title);
  }

  onError(title: string, message: string){
    this.toastr.error(message, title);
  }

  onInfo(title: string, message: string){
    this.toastr.info(message, title);
  }

  onWarning(title: string, message: string){
    this.toastr.warning(message, title);
  }
}
