import { Injectable, Inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse  } from '@angular/common/http';
import { SendContactEmail } from '../../models/SendContactEmail';
import { MailRequest } from '../../models/MailRequest';
import { Domains } from '../../models/Domains';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  url = "";
  prefix = "";
  controller = "";

  appSettings : any;

  constructor(@Inject('BASE_URL') baseUrl: string,private http: HttpClient) { 

    this.prefix = "api/";
    this.controller = "mail/";
    this.url = baseUrl + this.prefix + this.controller;

    //=====================================================================================
    // Lectura de appsetings.json
    //=====================================================================================
    this.appSettings = JSON.parse(localStorage.getItem('jsonConfig'));
    if(this.appSettings === null || this.appSettings === undefined){
      console.log("EmailService: No hay datos en this.appSettings");
    }
    //=====================================================================================
    // TERMINA: Lectura de appsetings.json
    //=====================================================================================
  }

  getDomains(): Observable<any[]> {
    let API_URL = `${this.url}domains`;
    return this.http.get<any[]>(API_URL).pipe(catchError(this.error));
  }

  //<summary> Método que se encarga de enviar el correo electronico desde la vista de log in </summary>
  register(model: MailRequest):Observable<any>{
    let API_URL = `${this.url}register`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    model.DomainId = this.appSettings.Id;
    return this.http.post<any>(API_URL, model,{ observe: 'response' })
      .pipe(catchError((error): any => {

                return throwError(`Connection Error register: ${error}`);
            }
        ));
  
  }

  recover(model: MailRequest):Observable<any>{
    let API_URL = `${this.url}recover`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    model.DomainId = this.appSettings.Id;
    return this.http.post<any>(API_URL, model,{ observe: 'response' })
      .pipe(catchError((error): any => {

                return throwError(`Connection Error recovery: ${error}`);
            }
        ));
  
  }

  confirmation(model: MailRequest):Observable<any>{
    let API_URL = `${this.url}confirmation`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    model.DomainId = this.appSettings.Id;
    return this.http.post<any>(API_URL, model,{ observe: 'response' })
      .pipe(catchError((error): any => {

                return throwError(`Connection Error confirmation: ${error}`);
            }
        ));
  
  }

  contact(model: MailRequest):Observable<any>{
    let API_URL = `${this.url}contact`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    
    return this.http.post<any>(API_URL, model,{ observe: 'response' })
      .pipe(catchError((error): any => {

                return throwError(`Connection Error SEND: ${error}`);
            }
        ));
  
  }

  
  emailDemo(model: MailRequest): Observable<any> {
    let API_URL = `${this.url}emaildemo`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    model.DomainId = this.appSettings.Id;
    return this.http.post<any>(API_URL, model, { observe: 'response' })
      .pipe(catchError((error): any => {

        return throwError(`Connection Error register: ${error}`);
      }
      ));

  }
     // Handle Errors 
     error(error: HttpErrorResponse) {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
        if(error){
          errorMessage = error.error.message;
        }
        
      } else {
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      console.log("error" + error);
      console.log("error.error" + error.error);
      console.log(errorMessage);
      return throwError(errorMessage);
    }
  
  
}
