import { MailSettings } from "./MailSettings";
import { StripeConfig } from "./StripeConfig";
import { WebsiteStyle } from "./WebsiteStyle";

export class Domains{
    
    constructor() {
        this.ContactSettings = new MailSettings();
        this.StripeConfig = new StripeConfig();
        this.CssConfig = new WebsiteStyle();
    }
    Logo: string;
    Favicon: string;
    OrganizationId: number;
    Direction: string;
    ContactSettings: MailSettings;
    StripeConfig: StripeConfig;
    CssConfig: WebsiteStyle;
}