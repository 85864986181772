import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective } from '@angular/forms';
import { InvisibleReCaptchaComponent, ReCaptcha2Component, ReCaptchaV3Service } from 'ngx-captcha';


//Models
import { User } from '../shared/models/User';
import { Country } from '../shared/models/Country';
import { SelectListItem } from '../shared/models/SelectListItem';

//Services
import { UserService } from '../shared/services/user/user.service';
import { RequestsService } from '../shared/services/requests.service';
import { AuthenticationService } from '../shared/services/authentication/authentication.service';
import { AccountResponse } from '../shared/models/AccountResponse';
import { GlobalConstans } from '../shared/global-constants/constants';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  userObject: any;
  countries: Country[];
  timezones: SelectListItem[];
  response: AccountResponse;
  siteKey: any;

  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaResponse?: string;
  public captchaIsReady = false;

  public recaptchaEmailResponse: any = null;

  @ViewChild('recaptchaEmail', { static: false }) recaptchaEmail: InvisibleReCaptchaComponent;
  @ViewChild('recaptchaPassword', { static: false }) recaptchaPassword: InvisibleReCaptchaComponent;
  @ViewChild('recaptchaSecurity', { static: false }) recaptchaSecurity: InvisibleReCaptchaComponent;

  loadUser = false;
  editMode = false;

  editPersonalInformation = false;
  editEmail = false;
  editLocalization = false;
  editPassword = false;
  editSecurity = false;

  //alerts
  successAlert = false;
  errorAlert = false;

  //Forms
  personalInformationForm: FormGroup;
  emailForm: FormGroup;
  localizationForm: FormGroup;
  passwordForm: FormGroup;
  securityForm: FormGroup;

  //submit vars
  submitPersonalInformationForm = false;
  submitEmailForm = false;
  submitLocalizationForm = false;
  submitPasswordForm = false;
  submitSecurityForm = false;

  //submit vars
  loadingPersonalInformationForm = false;
  loadingEmailForm = false;
  loadingLocalizationForm = false;
  loadingPasswordForm = false;
  loadingSecurityForm = false;

  
  constructor(
    private rutas: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private service: RequestsService,
    private authService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private reCaptchaV3Service: ReCaptchaV3Service  ) {

    this.userObject = JSON.parse(localStorage.getItem('currentUser'));
    if(this.userObject === null)
    {
      this.rutas.navigate(['/index']); 
    }

    this.loadUser = true;
    this.siteKey = GlobalConstans.CaptchaSiteKey;
    //Get timezones for dropdown


    //Init object
    this.response = new AccountResponse();

    //Personal Information Form
    this.personalInformationForm = new FormGroup({ firstName:new FormControl(), lastName: new FormControl()});

    //Email Form
    this.emailForm = new FormGroup({ email: new FormControl(), recaptchaEmail: new FormControl });
    //this.emailForm = new FormGroup({ email: new FormControl() });

    //Localization Form
    this.localizationForm = new FormGroup({ countryId: new FormControl(), timezoneId: new FormControl() });

    //password Form
    this.passwordForm = new FormGroup({ currentPassword: new FormControl(), newPassword: new FormControl(), recaptchaPassword: new FormControl() });

    //securityForm Form
    this.securityForm = new FormGroup({ securityId: new FormControl(), answer: new FormControl(), recaptchaSecurity: new FormControl() });

    this.service.getTimezones().subscribe((res: SelectListItem[])=>{
      this.timezones = res;   
    })

    //Get countries for dropdown
    this.userService.getCountries().subscribe((res: Country[])=>{
      this.countries = res;
    });

    

   }

  ngOnInit(): void {

    this.onInitPersonalInformationForm();
    this.onInitEmailForm();
    this.onInitLocalizationForm();
    this.onInitPasswordForm();
    this.onInitSecurityForm();
    
  }

  ngAfterViewInit(): void {
    this.captchaIsLoaded = true;
    this.cdr.detectChanges();
    //this.highlight();
  }

  // ====================================================================================================================================
  // INIT FORMS
  // ====================================================================================================================================
  onInitPersonalInformationForm(){
  //Personal information form
  this.personalInformationForm = this.formBuilder.group({ firstName: [ this.userObject.FirstName, Validators.required], lastName: [this.userObject.LastName, Validators.required], });
  }
  onInitEmailForm(){
    //Email Form
    this.emailForm = this.formBuilder.group({ email: [this.userObject.Email, [Validators.required, Validators.email]], recaptchaEmail: ['', Validators.required] });
  }
  onInitLocalizationForm(){
        //Localization Form
        this.localizationForm = this.formBuilder.group({ countryId: [this.userObject.CountryId === undefined ? '' : this.userObject.CountryId, Validators.required], timezoneId: [this.userObject.TimeZoneId === undefined ? '' : this.userObject.TimeZoneId, Validators.required] });
  }
  onInitPasswordForm() {
    //securityForm Form
    this.passwordForm = this.formBuilder.group({ currentPassword: ['', Validators.required], newPassword: ['', Validators.required], recaptchaPassword: ['', Validators.required] });
  }
  onInitSecurityForm() {
    //securityForm Form
    this.securityForm = this.formBuilder.group({ securityId: ['', Validators.required], answer: ['', Validators.required], recaptchaSecurity: ['', Validators.required] });
  }

  // ====================================================================================================================================
  // FORMS PROPERTIES
  // ====================================================================================================================================
    get pi() { return this.personalInformationForm.controls; }
    get e() { return this.emailForm.controls; }
    get l() { return this.localizationForm.controls; }
    get p() { return this.passwordForm.controls; }
    get s() { return this.securityForm.controls; }

  // ====================================================================================================================================
  // FORMS
  // ====================================================================================================================================
  onSubmitPersonalInformation(){
    this.submitPersonalInformationForm = true;

    // stop here if form is invalid
    if (this.personalInformationForm.invalid) { return; }

    this.loadingPersonalInformationForm = true;
    let data = new User();
    data.Id = this.userObject.Id;
    data.FirstName = this.personalInformationForm.value.firstName;
    data.LastName = this.personalInformationForm.value.lastName;
    
    this.authService.accountChangeName(data).subscribe((res: AccountResponse) => { 
      
      this.response = res;
      if(this.response.Status === 1){

        this.response.Message = "Your information has been changed correctly";
        this.successAlert = true;
        //TODO: Actualizar la info del user
        this.userObject.FirstName = data.FirstName;
        this.userObject.LastName = data.LastName;
        this.userObject.FullName = this.userObject.FirstName + ' ' +this.userObject.LastName;
        localStorage.setItem('currentUser', JSON.stringify(this.userObject));

        this.cancel('personalInformation');

      }else{
        this.errorAlert = true;
        this.response.Exception = this.response.Message !== '' ? this.response.Message : undefined;
        this.response.Message = "We found an error, please try again later";
      }

      this.submitPersonalInformationForm = false;
      this.loadingPersonalInformationForm = false;
    }, err => { 
      this.submitPersonalInformationForm = false;
      this.loadingPersonalInformationForm = false;
      console.log(err);
    });

    
  }

  onSubmitEmail() {
    
    this.submitEmailForm = true;

    // stop here if form is invalid
    if (this.emailForm.invalid) { return; }

    this.loadingEmailForm = true;
    let data = new User();
    data.Id = this.userObject.Id;
    data.Email = this.emailForm.value.email;

    this.authService.accountChangeEmail(data).subscribe((res: AccountResponse) => { 

      this.response = res;
      if(this.response.Status === 1){

        this.response.Message = "Your email has been changed correctly";
        this.successAlert = true;
        //TODO: Actualizar la info del user
        this.userObject.Email = data.Email;
        localStorage.setItem('currentUser', JSON.stringify(this.userObject));

        this.cancel('email');

      }else{
        this.errorAlert = true;
        this.response.Exception = this.response.Message !== '' ? this.response.Message : undefined;
        this.response.Message = "We found an error, please try again later";
      }

      this.submitEmailForm = false;
      this.loadingEmailForm = false;

    }, err => { 

      console.log(err); 
    });
  }

  onSubmitLocalizationForm(){
    this.submitLocalizationForm = true;

    // stop here if form is invalid
    if (this.localizationForm.invalid) { return; }

    this.loadingLocalizationForm = true;
    let data = new User();
    data.Id = this.userObject.Id;
    data.CountryId = parseInt(this.localizationForm.value.countryId);
    data.TimeZoneId = this.localizationForm.value.timezoneId;

    this.authService.accountChangeLocation(data).subscribe((res: AccountResponse) => { 

      this.response = res;
      if(this.response.Status === 1){
        
          let USER = JSON.parse(localStorage.getItem('currentUser'));
          if (USER !== undefined && USER !== null) {
            this.userObject.HaveLocalization = true;
          }
        

        this.response.Message = "Your localization info has been changed correctly";
        this.successAlert = true;
        //TODO: Actualizar la info del user
        this.userObject.CountryId = data.CountryId;
        this.userObject.TimeZoneId = data.TimeZoneId;
        localStorage.setItem('currentUser', JSON.stringify(this.userObject));

        this.cancel('localization');

      }else{
        this.errorAlert = true;
        this.response.Exception = this.response.Message !== '' ? this.response.Message : undefined;
        this.response.Message = "We found an error, please try again later";
      }

      this.submitLocalizationForm = false;
      this.loadingLocalizationForm = false;


    }, err => { 
      this.submitLocalizationForm = false;
      this.loadingLocalizationForm = false;
      console.log(err);
     });
  }

  onSubmitPasswordForm(){
    this.submitPasswordForm = true;

    // stop here if form is invalid
    if (this.passwordForm.invalid) { return; }

    this.loadingPasswordForm = true;
    let data = new User();
    data.Id = this.userObject.Id;
    data.CurrentPassword = this.passwordForm.value.currentPassword;
    data.Password = this.passwordForm.value.newPassword;

    this.authService.accountChangePassword(data).subscribe((res: AccountResponse) => { 

      this.response = res;
      if(this.response.Status === 1){

        this.response.Message = "Your password has been updated correctly";
        this.successAlert = true;
        this.cancel('password');

      }else{
        this.errorAlert = true;
        this.response.Exception = this.response.Message !== '' ? this.response.Message : undefined;
        this.response.Message = "We found an error, please try again later";
      }

      this.submitPasswordForm = false;
      this.loadingPasswordForm = false;
      //this.recaptchaPassword.resetCaptcha;

    }, err => { 
      this.submitPasswordForm = false;
      this.loadingPasswordForm = false;
      console.log(err); 
    });

    
  }

  onSubmitSecurityForm(){
    this.submitSecurityForm = true;

    // stop here if form is invalid
    if (this.securityForm.invalid) { return; }

    this.loadingSecurityForm = true;
    let data = new User();
    data.Id = this.userObject.Id;
    data.SecurityQuestionId = this.securityForm.value.securityId;
    data.Answer = this.securityForm.value.answer;

    this.authService.accountChangeQuestion(data).subscribe((res: AccountResponse) => { 

      this.response = res;
      if(this.response.Status === 1){

        if (!this.haveSecurityQuestion()) {
          let USER = JSON.parse(localStorage.getItem('currentUser'));
          if (USER !== undefined && USER !== null) {
            USER.HaveSecurityQuestion = true;
            localStorage.setItem('currentUser', JSON.stringify(USER));
          }
        }

        this.response.Message = "Your security question has been updated correctly";
        this.successAlert = true;
        this.cancel('security');

      }else{
        this.errorAlert = true;
        this.response.Exception = this.response.Message !== '' ? this.response.Message : undefined;
        this.response.Message = "We found an error, please try again later";
      }

      this.submitSecurityForm = false;
      this.loadingSecurityForm = false;

    }, err => { 
      this.submitSecurityForm = false;
      this.loadingSecurityForm = false;
      console.log(err); 
    });

  }
  // ====================================================================================================================================
  // END: FORMS
  // ====================================================================================================================================
  changeMode(enableForm: string){
    this.editMode = true;
    this.successAlert = false;

    switch (enableForm) {
      case 'personalInformation':
        this.editPersonalInformation = true;
        break;
      case 'email':
          this.editEmail = true;
          break;
      case 'localization':
        this.editLocalization = true;
        break;
      case 'password':
        this.editPassword = true;
        break;
      case 'security':
        this.editSecurity = true;
        break;
      default:
        break;
    }
  }

  cancel(disabledForm: string){
    this.editMode = false;
    this.errorAlert = false;

    switch (disabledForm) {
      case 'personalInformation':
        this.onInitPersonalInformationForm();
        this.editPersonalInformation = false;
        this.loadingPersonalInformationForm = false;
        this.submitPersonalInformationForm = false;
        break;
      case 'email':
        this.onInitEmailForm();
          this.editEmail = false;
          this.loadingEmailForm = false;
          this.submitEmailForm = false;
          break;
      case 'localization':
        this.onInitLocalizationForm();
        this.editLocalization = false;
        this.loadingLocalizationForm = false;
        this.submitLocalizationForm = false;
        break;
        case 'password':
          this.onInitPasswordForm();
          this.editPassword = false;
          this.loadingPasswordForm = false;
          this.submitPasswordForm = false;
          break;
      case 'security':
        this.onInitSecurityForm();
        this.editSecurity = false;
        this.loadingSecurityForm = false;
        this.submitSecurityForm = false;
        break;
      default:
        break;
    }
  }

  haveSecurityQuestion() {
    const USER = JSON.parse(localStorage.getItem('currentUser'));
    if (USER !== undefined && USER !== null) {
      return USER.HaveSecurityQuestion;

    }
    return false;
  }

  haveLocalization() {
    return this.userObject.HaveLocalization;
  }
  // ====================================================================================================================================
  // CAPTCHA FUNCTIONS
  // ====================================================================================================================================
  handleEmailLoad() {
    this.reCaptchaV3Service.execute(this.siteKey, 'profile', (token) => {
      this.emailForm.controls.recaptchaEmail.setValue(token);
    }, {
      useGlobalDomain: false
    });
  }

  handlePasswordLoad() {
    this.reCaptchaV3Service.execute(this.siteKey, 'profile', (token) => {
      this.passwordForm.controls.recaptchaPassword.setValue(token);
    }, {
      useGlobalDomain: false
    });
  }

  handleSecurityLoad() {
    this.reCaptchaV3Service.execute(this.siteKey, 'profile', (token) => {
      this.securityForm.controls.recaptchaSecurity.setValue(token);
    }, {
      useGlobalDomain: false
    });
  }

  handleExpire(){ }

  handleReset() { }

  handleReady(): void { }

  handleSuccess(captchaResponse: string): void { }


  
  // ====================================================================================================================================
  // END: CAPTCHA FUNCTIONS
  // ====================================================================================================================================
}
