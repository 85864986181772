import { Injectable, Inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse  } from '@angular/common/http';
import { GlobalConstans } from '../global-constants/constants';

//Interfaces
import { ILanguage } from '../interfaces/language';
import { ICurrency } from '../interfaces/currency';
import { ICategory } from '../interfaces/categories';
import { ICourses } from '../interfaces/courses';
import { IFeaturedProducts } from '../interfaces/featured-products';
import { ITests } from '../interfaces/tests';
import { ICourseShell } from '../interfaces/courseshell';
import { CourseShell } from '../models/CourseShell';
import { ILesson } from '../interfaces/lesson';
import { ISearch } from '../interfaces/ISearch';
import { ILevel } from '../interfaces/ILevel';
import { Search } from '../models/Search';
import { Category } from '../models/Category';
import { Language } from '../models/Language';
import { Level } from '../models/Level';
import { EmailTemplate } from '../models/EmailTemplate';
import { FeaturedProduct } from '../models/FeaturedProduct';
import { Purchase } from '../models/Purchase';
import { SelectListItem } from '../models/SelectListItem';
import { Domains } from '../models/Domains';
import { RedeemOrder } from '../models/RedeemOrder';
import { ContactInfo } from '../models/ContactInfo';
import { EmailConfig } from '../models/EmailConfig';
import { StripeConfig } from '../models/StripeConfig';
import { MenuItem } from '../models/MenuItem';


@Injectable({
  providedIn: 'root'
})
export class RequestsService {

  apiUrl: string = GlobalConstans.apiURL;
  headers = new HttpHeaders().set('Content-Type', 'application/json');

  appSettings: any; 
  loaded = false;

  constructor(private http: HttpClient) {    
    //=====================================================================================
    // Lectura de appsetings.json
    //=====================================================================================
    this.appSettings = JSON.parse(localStorage.getItem('jsonConfig'));
    if(this.appSettings === null || this.appSettings === undefined){
      console.log("RequestService: No hay datos en this.appSettings");
    }
    //=====================================================================================
    // TERMINA: Lectura de appsetings.json
    //=====================================================================================
    
  }


  initSettings(){
    let API_URL = `${this.apiUrl}/GetInitialSettings`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let url = document.location.origin;
    return this.http.post(API_URL, {'Url': url}, options).toPromise();
  }

  loadLanguages(): Observable<Language[]> {
    let API_URL = `${this.apiUrl}/GetLang`;
    return this.http.get<Language[]>(API_URL).pipe(catchError(this.error));
  }

  loadCurrencyTypes(): Observable<ICurrency[]>{
    let API_URL = `${this.apiUrl}/GetCurrencyTypes`;
    return this.http.get<ICurrency[]>(API_URL).pipe(catchError(this.error));
  }

  loadCategories(): Observable<Category[]>{
    let API_URL = `${this.apiUrl}/GetCategories/${this.appSettings.OrganizationId}`;
    return this.http.get<Category[]>(API_URL).pipe(catchError(this.error));
  }

  getTimezones(): Observable<SelectListItem[]>{
    let API_URL = `${this.apiUrl}/GetTimezones`;
    return this.http.get<SelectListItem[]>(API_URL).pipe(catchError(this.error));
  }
  /**
   * Métodos para HOME
   */
  loadTopCourses(): Observable<ICourses[]>{
    let API_URL = `${this.apiUrl}/GetTopCourses/${this.appSettings.OrganizationId}`;
    return this.http.get<ICourses[]>(API_URL).pipe(catchError(this.error));
  }

  loadFeaturedProducts(contentType): Observable<any>{
    
      let API_URL = `${this.apiUrl}/GetFeaturedProducts/${this.appSettings.OrganizationId}/${contentType}`;
  
      let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: headers };
  
      return this.http.get<any>(API_URL, options).pipe(catchError(this.error));
    
    
  }

  loadTopTests(): Observable<ITests[]>{
    let API_URL = `${this.apiUrl}/GetTopTests/${this.appSettings.OrganizationId}`;
    return this.http.get<ITests[]>(API_URL).pipe(catchError(this.error));
  }

/**
 * Métodos para COURSE DETAILS
 */
  getCourseDetailsById(id): Observable<ICourses>{
    let API_URL = `${this.apiUrl}/GetCourseDetailsById/${id}/${this.appSettings.OrganizationId}`;
      return this.http.get<ICourses>(API_URL).pipe(catchError(this.error));
  }

  getShell(id): Observable<ICourseShell>{
    let API_URL = `${this.apiUrl}/GetCourseShellById/${id}/${this.appSettings.OrganizationId}`;
      return this.http.get<ICourseShell>(API_URL).pipe(catchError(this.error));
  }

  /**
   * Métodos para EXAM DETAILS
   */
  getExamDetailsById(id): Observable<ITests>{
    let API_URL = `${this.apiUrl}/GetTestDetailsById/${id}/${this.appSettings.OrganizationId}`;
    let result  = this.http.get<ITests>(API_URL).pipe(catchError(this.error));
      return result;
  }

  getCourseLevels(): Observable<Level[]>{
    let API_URL = `${this.apiUrl}/GetCourseLevels`;
    return this.http.get<Level[]>(API_URL).pipe(catchError(this.error));
  }

   // Handle Errors 
   error(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  searchCourses(data: ISearch): Observable<any> {
    let API_URL = `${this.apiUrl}/SearchCourses`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
   return this.http.post(API_URL, data, options).pipe(catchError(this.error));
  
  }

  searchExams(data: ISearch): Observable<any> {
    let API_URL = `${this.apiUrl}/searchExams`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
   return this.http.post(API_URL, data, options).pipe(catchError(this.error));
  
  }

  courseHome_GetTopCategories(): Observable<Category[]>{
    let API_URL = `${this.apiUrl}/CourseHome_GetTopCategories/${this.appSettings.OrganizationId}`;
    return this.http.get<Category[]>(API_URL).pipe(catchError(this.error));
  }

  examHome_GetTopCategories(): Observable<Category[]>{
    let API_URL = `${this.apiUrl}/ExamHome_GetTopCategories/${this.appSettings.OrganizationId}`;
    return this.http.get<Category[]>(API_URL).pipe(catchError(this.error));
  }

  courseHome_GetRecentAdded(): Observable<ICourses[]>{
    let API_URL = `${this.apiUrl}/CourseHome_GetRecentAdded/${this.appSettings.OrganizationId}`;
    return this.http.get<ICourses[]>(API_URL).pipe(catchError(this.error));
  }

  examHome_GetRecentAdded(): Observable<ITests[]>{
    let API_URL = `${this.apiUrl}/ExamHome_GetRecentAdded/${this.appSettings.OrganizationId}`;
    return this.http.get<ITests[]>(API_URL).pipe(catchError(this.error));
  }

  getEmailTemplate(url: string, emailTypeId: number):Observable<EmailTemplate>{
    let API_URL = `${this.apiUrl}/GetEmailTemplate`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

   return this.http.post(API_URL, {'Domain': url, 'EmailTypeId': emailTypeId}, options).pipe(catchError(this.error));
  
  }

  getRelatedProductsByCategoryId(typeId: number, categoryId: number): Observable<FeaturedProduct[]>{
    let API_URL = `${this.apiUrl}/GetRelatedProductsByCategoryId/${typeId}/${categoryId}/${this.appSettings.OrganizationId}`;
    return this.http.get<FeaturedProduct[]>(API_URL).pipe(catchError(this.error));
  }

  getPurchasesByUserId(userId: number): Observable<Purchase[]>{
    let API_URL = `${this.apiUrl}/GetPurchasesByUserId/${userId}`;
    return this.http.get<Purchase[]>(API_URL).pipe(catchError(this.error));
  }

  redeemOrder(model: RedeemOrder): Observable<any>{
    
    const API_URL = `${this.apiUrl}/order/redeem`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    
    model.OrganizationId = this.appSettings.OrganizationId;
    model.UrlDomain = document.location.origin;

   return this.http.post(API_URL, model, options).pipe(catchError(this.error));
  }


  /**
   * SECTION: UI REQUESTS
   */

  SetCssConfig(cssConfig: any):Observable<EmailTemplate>{
    let API_URL = `${this.apiUrl}/SetCssConfig`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let url = document.location.origin;
    let cssConfigString = JSON.stringify(cssConfig);

   return this.http.post(API_URL, {'OrganizationId': this.appSettings.OrganizationId, 'UrlDomain': url, 'CssConfigString': cssConfigString }, options).pipe(catchError(this.error));
  
  }

  SaveCssConfig(cssConfig: any):Observable<EmailTemplate>{
    let API_URL = `${this.apiUrl}/SaveCssConfig`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let url = document.location.origin;
    let cssConfigString = JSON.stringify(cssConfig);
    let userObject = JSON.parse(localStorage.getItem('currentUser'));

   return this.http.post(API_URL, 
      {'OrganizationId': this.appSettings.OrganizationId,
      'UrlDomain': url,
      'CssConfigString': cssConfigString,
      'UserId': userObject.Id }
      , options).pipe(catchError(this.error));
  
  }

  SaveAndPublishCssConfig(cssConfig: any):Observable<EmailTemplate>{
    let API_URL = `${this.apiUrl}/SaveAndPublishCssConfig`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let url = document.location.origin;
    let cssConfigString = JSON.stringify(cssConfig);
    let userObject = JSON.parse(localStorage.getItem('currentUser'));
    
    return this.http.post(API_URL, 
      {'OrganizationId': this.appSettings.OrganizationId,
      'UrlDomain': url,
      'CssConfigString': cssConfigString,
      'UserId': userObject.Id }
      , options).pipe(catchError(this.error));
  
  }

  GetCssConfigByUserId(){
    let API_URL = `${this.apiUrl}/GetCssConfigByUserId`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    let url = document.location.origin;
    let userObject = JSON.parse(localStorage.getItem('currentUser'));
    
    return this.http.post(API_URL, 
      {'OrganizationId': this.appSettings.OrganizationId,
      'UrlDomain': url,
      'UserId': userObject.Id }
      , options).pipe(catchError(this.error));
  
  }

  UpdateLogo(logo: string):Observable<EmailTemplate>{
    let API_URL = `${this.apiUrl}/UpdateLogo`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let url = document.location.origin;
    
    let userObject = JSON.parse(localStorage.getItem('currentUser'));
    
    return this.http.post(API_URL, 
      {'OrganizationId': this.appSettings.OrganizationId,
      'UrlDomain': url,
      'Logo': logo,
      'UserId': userObject.Id }
      , options).pipe(catchError(this.error));
  
  }

  UpdateFavicon(favicon: string):Observable<EmailTemplate>{
    let API_URL = `${this.apiUrl}/UpdateFavicon`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let url = document.location.origin;
    
    let userObject = JSON.parse(localStorage.getItem('currentUser'));
    
    return this.http.post(API_URL, 
      {'OrganizationId': this.appSettings.OrganizationId,
      'UrlDomain': url,
      'Favicon': favicon,
      'UserId': userObject.Id }
      , options).pipe(catchError(this.error));
  
  }

  ChangeShowBanner(status: boolean):Observable<EmailTemplate>{
    let API_URL = `${this.apiUrl}/ChangeShowBanner`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let url = document.location.origin;
    
    let userObject = JSON.parse(localStorage.getItem('currentUser'));
    
    return this.http.post(API_URL, 
      {'OrganizationId': this.appSettings.OrganizationId,
      'UrlDomain': url,
      'Status': status,
      'UserId': userObject.Id }
      , options).pipe(catchError(this.error));
  
  }


  GetBannerForEdit():Observable<any>{
    let API_URL = `${this.apiUrl}/GetBannerForEdit`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let url = document.location.origin;
    
    return this.http.post(API_URL, 
      {'OrganizationId': this.appSettings.OrganizationId,
      'UrlDomain': url}
      , options).pipe(catchError(this.error));
  
  }

  InsertBannerImage(image:any):Observable<any>{
    let API_URL = `${this.apiUrl}/InsertBannerImage`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let url = document.location.origin;
    
    return this.http.post(API_URL, 
      {'OrganizationId': this.appSettings.OrganizationId,
      'UrlDomain': url,
      'Images': image}
      , options).pipe(catchError(this.error));
  
  }

  UpdateImageStatus(image:any, status: boolean):Observable<any>{
    let API_URL = `${this.apiUrl}/UpdateImageStatus`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let url = document.location.origin;
    
    return this.http.post(API_URL, 
      {'OrganizationId': this.appSettings.OrganizationId,
      'UrlDomain': url,
      'Images': image,
      'Status': status}
      , options).pipe(catchError(this.error));
  
  }

  DeleteImageFromBanner(image:any):Observable<any>{
    let API_URL = `${this.apiUrl}/DeleteImageFromBanner`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let url = document.location.origin;
    
    return this.http.post(API_URL, 
      {'OrganizationId': this.appSettings.OrganizationId,
      'UrlDomain': url,
      'Images': image
    }
      , options).pipe(catchError(this.error));
  
  }

  SaveImage(image:any):Observable<any>{
    let API_URL = `${this.apiUrl}/SaveImage`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let url = document.location.origin;
    
    return this.http.post(API_URL, 
      {'OrganizationId': this.appSettings.OrganizationId,
      'UrlDomain': url,
      'Images': image
    }
      , options).pipe(catchError(this.error));
  
  }

  ImagesForBanner():Observable<any>{
    let API_URL = `${this.apiUrl}/ImagesForBanner`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let url = document.location.origin;
    
    return this.http.post(API_URL, 
      {'OrganizationId': this.appSettings.OrganizationId,
      'UrlDomain': url}
      , options).pipe(catchError(this.error));
  
  }

  /**
   * END UI REQUESTS
   */


   /**
   * SECTION: CUSTOMIZATION REQUESTS
   */

  GetCustomizationInfo():Observable<any>{
    let API_URL = `${this.apiUrl}/GetCustomizationInfo`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let url = document.location.origin;
    
    return this.http.post(API_URL, 
      {'OrganizationId': this.appSettings.OrganizationId,
      'UrlDomain': url}
      , options).pipe(catchError(this.error));
  
  }

  UpdateContactInfo(contactInfo:ContactInfo):Observable<any>{
    let API_URL = `${this.apiUrl}/UpdateContactInfo`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let url = document.location.origin;
    
    return this.http.post(API_URL, 
      {'OrganizationId': this.appSettings.OrganizationId,
      'UrlDomain': url,
      'ContactInfo': contactInfo
      }
      , options).pipe(catchError(this.error));
  
  }

  UpdateEmailSettings(emailSettings:EmailConfig):Observable<any>{
    let API_URL = `${this.apiUrl}/UpdateEmailSettings`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let url = document.location.origin;
    
    return this.http.post(API_URL, 
      {'OrganizationId': this.appSettings.OrganizationId,
      'UrlDomain': url,
      'EmailSettings': emailSettings
    }
      , options).pipe(catchError(this.error));
  
  }

  UpdateStripeSettings(stripeSettings:StripeConfig):Observable<any>{
    let API_URL = `${this.apiUrl}/UpdateStripeSettings`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let url = document.location.origin;
    
    return this.http.post(API_URL, 
      {'OrganizationId': this.appSettings.OrganizationId,
      'UrlDomain': url,
      'StripeSettings': stripeSettings
    }
      , options).pipe(catchError(this.error));
  
  }

  GetContactInfo():Observable<any>{
    let API_URL = `${this.apiUrl}/GetContactInfo`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let url = document.location.origin;
    let info:any = [];

    return this.http.post(API_URL, 
      {'OrganizationId': this.appSettings.OrganizationId,
      'UrlDomain': url
      }
      , options).pipe(catchError(this.error));
  }

  GetMenu():Observable<any>{
    let API_URL = `${this.apiUrl}/GetMenu`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let url = document.location.origin;
    let info:any = [];

    return this.http.post(API_URL, 
      {'OrganizationId': this.appSettings.OrganizationId,
      'UrlDomain': url
      }
      , options).pipe(catchError(this.error));
  }

  UpdateItemsToMenu(item : MenuItem):Observable<any>{
    let API_URL = `${this.apiUrl}/UpdateItemsToMenu`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let url = document.location.origin;
    
    let data = {'OrganizationId': this.appSettings.OrganizationId, 'UrlDomain': url, 'Menu':item };
    return this.http.post(API_URL, data , options).pipe(catchError(this.error));
  }

  DeleteItemToMenu(item : MenuItem):Observable<any>{
    let API_URL = `${this.apiUrl}/DeleteItemToMenu`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let url = document.location.origin;
    
    let data = {'OrganizationId': this.appSettings.OrganizationId, 'UrlDomain': url, 'Menu':item };
    return this.http.post(API_URL, data , options).pipe(catchError(this.error));
  }
   /**
   * END CUSTOMIZATION REQUESTS
   */
}
