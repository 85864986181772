export class Button {
    /**
     *
     */
    constructor() {
        //this.Action = "external";

    }
    
    public Text: string;
    
    public Action: string;
    
    public IsExternal: boolean;
    
    public Target: boolean;
    
    public Type: string;

    public Position: string;
}
