export enum ButtonPosition {
  TopLeftCorner = 1,
  TopMiddle = 2,
  TopRightCorner = 3,
  LeftMiddle = 4,
  RightMiddle = 5,
  BottomLeftCorner = 6,
  BottomMiddle = 7,
  BottomRightCorner = 8,
  Center = 9

}
