import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { InvisibleReCaptchaComponent, ReCaptcha2Component, ReCaptchaV3Service } from 'ngx-captcha';
import { NgxSpinnerService } from "ngx-spinner";
import { RequestsService } from '../shared/services/requests.service';
import { UserService } from '../shared/services/user/user.service';
import { Country } from '../shared/models/Country';
import { AlertService } from '../shared/services/alert/alert.service';
import { User } from '../shared/models/User';
import { EmailType } from '../shared/enums/EmailType';
import { EmailTemplate } from '../shared/models/EmailTemplate';
import { SelectListItem } from '../shared/models/SelectListItem';
import { Response } from '../shared/models/Response';
import { MailRequest } from '../shared/models/MailRequest';
import { EmailService } from '../shared/services/email/email.service';
import { GlobalConstans } from '../shared/global-constants/constants';
import { CustomValidators } from '../shared/custom-validators/custom-validators';
import { Router } from '@angular/router';
import { timeout } from 'rxjs/operators';

//https://w3path.com/how-to-integrate-recaptcha-in-angular-8/
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;
  loading = false;
  revealPassword = false;
  countries: Country[];
  timezones: SelectListItem[];
  siteKey: any;
  //captchaToken: string;
  //public captchaSuccess = false;

  //@ViewChild('recaptchaElement', {static: true }) recaptchaElement: ReCaptcha2Component;

  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaResponse?: string;
  public captchaIsReady = false;

  public badge: 'bottomright' | 'bottomleft' | 'inline' = 'inline';
  public type: 'image' | 'audio';
  public theme: 'light' | 'dark' = 'light';

  public recaptcha: any = null;

  @ViewChild('captchaElem', { static: false }) captchaElem: InvisibleReCaptchaComponent;
  @ViewChild('langInput', { static: false }) langInput: ElementRef;

  constructor(private userService: UserService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private service: RequestsService,
    private emailService: EmailService,
    private router: Router,
    private reCaptchaV3Service: ReCaptchaV3Service,
    private cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService) {


    this.registerForm = new FormGroup({
      firstName:new FormControl(),
      lastName: new FormControl(),
      email: new FormControl(),
      username: new FormControl(),
      password: new FormControl()
  });

    this.siteKey = GlobalConstans.CaptchaSiteKey;
   }

  ngOnInit(): void {

    this.registerForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.pattern("[a-zA-Z][a-zA-Z ]+")]],
      lastName: ['', [Validators.required, Validators.pattern("[a-zA-Z][a-zA-Z ]+")]],
      email: ['', [Validators.required, Validators.email]],
      username: ['', Validators.required],
      password: [
        null,
        Validators.compose([
          Validators.required,
          // check whether the entered password has a number
          CustomValidators.patternValidator(/\d/, { hasNumber: true }),
          // check whether the entered password has upper case letter
          CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
          // check whether the entered password has a lower case letter
          CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
          // check whether the entered password has a special character
          //CustomValidators.patternValidator(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, { hasSpecialCharacters: true }),
          CustomValidators.patternValidator(/[ #$%&*+]/, { hasSpecialCharacters: true }),
          Validators.minLength(8)
        ])
      ],
  });

    
  }

  get f() { return this.registerForm.controls; }

  startTypingPassword() {
    this.revealPassword = this.registerForm.value.password === "" || this.registerForm.value.password === null;
  }

  onSubmit() {
    
    this.submitted = true;
    
    if (this.registerForm.invalid) {
        return;
    }

    this.spinner.show("registering");
      this.loading = true;
      let data = new User();
      data.FirstName = this.registerForm.value.firstName;
      data.LastName = this.registerForm.value.lastName;
      data.Email = this.registerForm.value.email;
      data.UserName = this.registerForm.value.username;
      data.Password = this.registerForm.value.password;
    
      this.userService.insertUser(data).subscribe((res: Response) => {
        if (res.Success) {
          
          let model = new MailRequest();
          model.Name = data.FirstName;
          model.LastName = data.LastName;
          model.ToEmail = data.Email;
          model.Email = data.Email;
          model.Username = data.UserName;
          model.EmailType = EmailType.UserRegistration;
          //model.DomainId = currentDomain[0].id;
          model.BearerToken = GlobalConstans.getAuthToken();

          this.emailService.register(model).subscribe((res: any) => {
            if (res) {
              this.spinner.hide("registering");
              this.alertService
                .onConfirm('Perfect!', "Thanks for register with us!")
                .then((result) => {
                  if (result.value) {
                    this.registerForm.reset();
                    this.loading = false;
                    this.submitted = false;
                    
                    this.router.navigate(['/checkout']);
                  }
                });
              return;


            }

          }, err => {
            this.spinner.hide("registering");
            this.alertService
              .onError('Sorry!', "Your new account was successfully created but we couldn't send you an email confirmation at this moment")
              .then((result) => {
                if (result.value) {
                  
                  console.log("Error send: " + err);
                  this.loading = false;
                  return true;
                }
              });
            return;
          });






        } else {
          
          let $text = res.Message != undefined ? res.Message : undefined;
          let alert = "<div class=\"row\"><div class=\"col-md-12\"><div class=\"alert alert-success\"><i class=\"fa fa-info-circle\"></i> If you already have an account at <a href=\"https://lms.onlineexpert.com/Account/Login\" target=\"_blank\">onlineexpert.com</a>, you can use it here. If you don't remember your password, you can recover it <a href=\"https://lms.onlineexpert.com/Account/ForgotPassword\" target=\"_blank\">here</a>.</div></div></div>";
          
          let fullText = alert + $text;
          this.spinner.hide("registering");
          this.alertService
            .onError('Sorry!', fullText, true)
            .then((result) => {
              
              if (result.value) {
                this.loading = false;
                return true;
              }
            });
        }

      }, err => {
        
        //InsertUser process
        this.spinner.hide("registering");
        this.alertService
          .onError('Sorry!', "Please try it again later!")
          .then((result) => {
            
            if (result.value) {
              this.loading = false;
              console.log("REGISTER");
              console.log(err);
              return true;
            }
          });
        return;

      });
    //}

    
    
}


  writeUserName() {
    this.registerForm.get('username')?.patchValue(this.registerForm.controls['email']?.value.trim());
    this.registerForm.get('username')?.markAsTouched();
  }

onReset() {
  this.submitted = false;
  this.registerForm.reset();
}

  ngAfterViewInit(): void {
    this.captchaIsLoaded = true;
    this.cdr.detectChanges();
    //this.highlight();
  }

  execute(): void {
    this.captchaElem.execute();
  }

  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.cdr.detectChanges();
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.cdr.detectChanges();
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.cdr.detectChanges();
  }

  handleReady(): void {
    this.captchaIsReady = true;
    this.cdr.detectChanges();
  }

  changeBadge(badge: 'bottomright' | 'bottomleft' | 'inline' = 'bottomright'): void {
    this.badge = badge;
  }

  changeType(type: 'image' | 'audio'): void {
    this.type = type;
  }

  getResponse(): void {
    const response = this.captchaElem.getResponse();
    if (!response) {
      alert(`There is no response from grecaptcha script - try using 'getCurrentResponse' method or subscribe to 'success' event`);
    } else {
      alert(response);
    }
  }

  reload(): void {
    this.captchaElem.reloadCaptcha();
  }

  getCaptchaId(): void {
    alert(this.captchaElem.getCaptchaId());
  }

  reset(): void {
    this.captchaElem.resetCaptcha();
  }

  getCurrentResponse(): void {
    const currentResponse = this.captchaElem.getCurrentResponse();
    if (!currentResponse) {
      alert('There is no current response - have you submitted captcha?');
    } else {
      alert(currentResponse);
    }
  }

  changeTheme(theme: 'light' | 'dark'): void {
    this.theme = theme;
  }

}
