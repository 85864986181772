import { Injectable, Inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse  } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AzurestorageService {
  appSettings: any;
  url = "";
  prefix = "";
  controller = "";

  constructor(
    @Inject('BASE_URL') baseUrl: string,
    private http: HttpClient
  ){ 
    //=====================================================================================
    // Lectura de appsetings.json
    //=====================================================================================
    this.appSettings = JSON.parse(localStorage.getItem('jsonConfig'));
    if(this.appSettings === null || this.appSettings === undefined){
      console.log("RequestService: No hay datos en this.appSettings");
    }
    //=====================================================================================
    // TERMINA: Lectura de appsetings.json
    //=====================================================================================
    this.prefix = "api/";
    this.controller = "blobstorage/";
    this.url = baseUrl + this.prefix + this.controller;
  }

  DeleteFile(filename: string): Observable<any[]> {
    let API_URL = `${this.url}DeleteFile/${filename}/${this.appSettings.OrganizationId}`;
    return this.http.get<any[]>(API_URL).pipe(catchError(this.error));
  }

  InsertFile(file: any):Observable<any>{
    let API_URL = `${this.url}InsertFile/${this.appSettings.OrganizationId}`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    return this.http.post<any>(API_URL, file,{ observe: 'response' })
      .pipe(catchError((error): any => {

                return throwError(`Connection Error InsertFile: ${error}`);
            }
        ));
  
  }


  UploadBannerImage(images: any):Observable<any>{
    let API_URL = `${this.url}UploadBannerImage/${this.appSettings.OrganizationId}`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    return this.http.post<any>(API_URL, images,{ observe: 'response' })
      .pipe(catchError((error): any => {

                return throwError(`Connection Error UploadBannerImage: ${error}`);
            }
        ));
  
  }

  DeleteBannerImage(filename: string): Observable<any[]> {
    let API_URL = `${this.url}DeleteBannerImage/${filename}/${this.appSettings.OrganizationId}`;
    return this.http.get<any[]>(API_URL).pipe(catchError(this.error));
  }
   // Handle Errors 
   error(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      if(error){
        errorMessage = error.error.message;
      }
      
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log("error" + error);
    console.log("error.error" + error.error);
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
