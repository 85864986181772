import { Component, OnInit } from '@angular/core';
import { RequestsService } from '../shared/services/requests.service';
import { Category } from '../shared/models/Category';
import { ICourses } from '../shared/interfaces/courses';
import { GlobalConstans } from '../shared/global-constants/constants';
import { Product } from '../shared/models/Product';
import { CartService } from '../shared/services/cart.service';
import { ToastService } from '../shared/services/toast/toast.service';
import {NgbTabsetConfig} from '@ng-bootstrap/ng-bootstrap';

//Interfaces
import { ICategory } from '../shared/interfaces/categories';
import { ILanguage } from '../shared/interfaces/language';
import { ILevel } from '../shared/interfaces/ILevel';

//enums
import { ContentType } from '../shared/enums/ContentType'

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css'],
  providers: [NgbTabsetConfig]
})
export class CoursesComponent implements OnInit {

  courseContentType = ContentType.Course;

  customOptions: any = {}
  categories: Array<Category> = new Array();
  loadCategories = false;

  fCourses: ICourses[];
  fCoursesLoaded = false;

  rCourses: ICourses[];
  rCoursesLoaded = false;

  searchCategories: ICategory[];
  constructor(
    private requestService: RequestsService,
     config: NgbTabsetConfig,
     private cartService: CartService,
     private toastService: ToastService) {

    config.justify = 'center';
    config.type = 'pills';

    this.requestService.courseHome_GetTopCategories().subscribe((res: Category[]) => { 
      this.categories = res; 
      this.categories.forEach(element => {
        element.ImgSrc = GlobalConstans.returnCategoryUrlImage(element.CompanyId.toString(), element.Id.toString(), element.ImgSrc);
        GlobalConstans.imageExists(element.ImgSrc, function(exists) {
          if(!exists){
              console.log('RESULT: url=' + element.ImgSrc + ', exists=' + exists);
              element.ImgSrc = GlobalConstans.returnCategoryUrlImage(element.CompanyId.toString(), element.Id.toString(), undefined);
          }
          });
        
       });
      this.loadCategories = true;
      
    }, err => { console.log(err); });

    this.requestService.loadTopCourses().subscribe((res: ICourses[]) => { 
      this.fCourses = res; 
      this.fCourses.forEach(element => {
        element.ImgSrc = GlobalConstans.returnCoursesUrlImage(element.CompanyId.toString(), element.Id.toString(), element.ImgSrc);
        GlobalConstans.imageExists(element.ImgSrc, function(exists) {
          if(!exists){
              console.log('RESULT: url=' + element.ImgSrc + ', exists=' + exists);
              element.ImgSrc = GlobalConstans.returnCoursesUrlImage(element.CompanyId.toString(), element.Id.toString(), undefined);
          }
          });
        
       });
       this.fCoursesLoaded = true;
       
    }, err => { console.log(err); });

    this.requestService.courseHome_GetRecentAdded().subscribe((res: ICourses[]) => { 
      this.rCourses = res; 
      this.rCourses.forEach(element => {
        element.ImgSrc = GlobalConstans.returnCoursesUrlImage(element.CompanyId.toString(), element.Id.toString(), element.ImgSrc);
        GlobalConstans.imageExists(element.ImgSrc, function(exists) {
          if(!exists){
              console.log('RESULT: url=' + element.ImgSrc + ', exists=' + exists);
              element.ImgSrc = GlobalConstans.returnCoursesUrlImage(element.CompanyId.toString(), element.Id.toString(), undefined);
          }
          });
        
       });
       this.rCoursesLoaded = true;
       
    }, err => { console.log(err); });

    //Search requests
    this.requestService.loadCategories().subscribe((res: ICategory[]) => { this.searchCategories = res; }, err => { console.log(err); });
    
   }

  ngOnInit(): void {
    this.customOptions = {
      loop: false,
      mouseDrag: false,
      touchDrag: false,
      pullDrag: false,
      margin:15,
      dots: false,
      navSpeed: 700,
      navText: ['<i class="fa fa-arrow-left"></i>', '<i class="fa fa-arrow-right"></i>'],
      nav: true,
      responsive: {
        0: {
          items: 1
        },
        400: {
          items: 2
        },
        740: {
          items: 3
        },
        940: {
          items: 3
        }
      },
    }
  }

  addProduct(model: any){

    this.cartService._addProductToCart(model);
    
  }
}
