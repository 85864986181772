import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AuthenticationService } from '../shared/services/authentication/authentication.service';
import { AlertService } from '../shared/services/alert/alert.service';
// import custom validator to validate that password and confirm password fields match
import { MustMatch } from '../shared/helpers/must-match.validator';
import { User } from '../shared/models/User';

@Component({
  selector: 'app-forgotconfirmation',
  templateUrl: './forgotconfirmation.component.html',
  styleUrls: ['./forgotconfirmation.component.css']
})
export class ForgotconfirmationComponent implements OnInit {
  resetForm: FormGroup;
  submitted = false;
  userId = 0;

  constructor(private formBuilder: FormBuilder, 
    private route: ActivatedRoute,
     private rutas: Router,
     private authenticationService: AuthenticationService,
     private alertService: AlertService) {

    this.route.params.subscribe(parameters => { this.userId = parameters.id; });
    
    if(this.userId === 0){
      this.rutas.navigate(['/index']); 
    }

   }

  ngOnInit(): void {
    this.resetForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
    }, { validator: MustMatch('password', 'confirmPassword')});
  }

  get f() { return this.resetForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.resetForm.invalid) {
        return;
    }

    let model = new User();
    model.Id = this.userId;
    model.Password = this.resetForm.value.password;

    this.authenticationService.resetPassword(model).subscribe((res: any) => {
      if(res){
        
        this.alertService
        .onConfirm('Perfect!',"We have send you an email with instructions to recover your password")
        .then((result) => {
          if (result.value) {
            this.submitted = false;
            this.rutas.navigate(['/index']);
            return true;
          }
        });
        return;

        
      }

    }, err => { 
      this.alertService
      .onError('Sorry!',"We cannot recover your password")
      .then((result) => {
        if (result.value) {
          console.log("Error send: " + err); 
          this.submitted = false;
          return true;
        }
      });
      return;
    });

    // display form values on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.resetForm.value, null, 4));
}

onReset() {
    this.submitted = false;
    this.resetForm.reset();
}

}
