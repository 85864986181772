import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { GlobalConstans } from '../../global-constants/constants';
import { User } from '../../models/User';
import { AccountResponse } from '../../models/AccountResponse';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  apiUrl: string = GlobalConstans.apiURL;
  domainId: number;

  constructor(private http: HttpClient) {
      this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();

    const jsonConfig = JSON.parse(localStorage.getItem('jsonConfig'));
    if (jsonConfig !== undefined && jsonConfig !== null) {
      this.domainId = jsonConfig.Id;
      
    }

   }

   public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {
    
    let API_URL = `${this.apiUrl}/ValidateUser/${this.domainId}`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
  
    return this.http.post<any>(API_URL, { username, password }, options)
      .pipe(map(user => {
        
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if(user){
              localStorage.setItem('currentUser', JSON.stringify(user));
              this.currentUserSubject.next(user);
            }
            
            return user;
        }));
  }

  logout() {
      // remove user from local storage to log user out
      localStorage.removeItem('currentUser');
      this.currentUserSubject.next(null);
  }

  loginAPP(): Observable<any> {

    let API_URL = `${GlobalConstans.loginAPP}/auth`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    const username = GlobalConstans.USERNAME;
    const password = GlobalConstans.PASSWORD;
    return  this.http.post(API_URL, {username, password}, options);
 
  }

  async isAuthenticated(){

    let API_URL = `${GlobalConstans.loginAPP}/auth`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    const username = GlobalConstans.USERNAME;
    const password = GlobalConstans.PASSWORD;
    
    const response = await this.http.post(API_URL, {username, password}, options).toPromise();
    return response;
 }

  getToken(){
    return localStorage.getItem('access_token');
  }

  validateUserByEmail(email: string): Observable<any> {

      
    let API_URL = `${GlobalConstans.apiURL}/ValidateUserByEmail`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    return this.http.post(API_URL, {email}, options).pipe(catchError(this.error));

  }

  resetPassword(model: User): Observable<any> {

      
    let API_URL = `${GlobalConstans.apiURL}/ResetPassword`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    return this.http.post(API_URL, model, options).pipe(catchError(this.error));

  }

  // Account methods
  accountChangeName(data: User): Observable<any> {
    let API_URL = `${this.apiUrl}/AccountChangeName`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    return this.http.post(API_URL, data, options).pipe(catchError(this.error));
  }

  accountChangeEmail(data: User): Observable<any> {
    let API_URL = `${this.apiUrl}/AccountChangeEmail`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    return this.http.post(API_URL, data, options).pipe(catchError(this.error));
  }

  accountChangeLocation(data: User): Observable<any> {
    let API_URL = `${this.apiUrl}/AccountChangeLocation`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    return this.http.post(API_URL, data, options).pipe(catchError(this.error));
  }

  accountChangePassword(data: User): Observable<any> {
    let API_URL = `${this.apiUrl}/AccountChangePassword`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    return this.http.post(API_URL, data, options).pipe(catchError(this.error));
  }

  accountChangeQuestion(data: User): Observable<any> {
    let API_URL = `${this.apiUrl}/AccountChangeQuestion`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    return this.http.post(API_URL, data, options).pipe(catchError(this.error));
  }
  //END: Account methods
  // Handle Errors 
  error(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
