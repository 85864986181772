export class FeaturedProduct{
    Id: number;
    Name: string;
    CompanyId: number;
    ProductId: string;
    Language: string;
    Level: string;
    CategoryId: number;
    CategoryName: string;
    Category: string;
    Subcategory: string;
    Summary: string;
    Description: string;
    Experts: any;
    Price: number;
    OldPrice: number;
    ImgSrc:string;
    VideoSrc:string;
    Type:number;
    GlobalId: string;
    ComingSoon: boolean;
}
