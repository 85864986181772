import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CartService } from '../services/cart.service';
import { RequestsService } from '../services/requests.service';
import { AuthenticationService } from '../services/authentication/authentication.service';

import { AzurestorageService } from '../services/azurestorage/azurestorage.service';

import { AlertService } from '../services/alert/alert.service';
import { User } from '../models/User';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalConstans } from '../global-constants/constants';
import { ContentType } from '../enums/ContentType';
import { Domains } from '../models/Domains';
import { WebsiteStyle } from '../models/WebsiteStyle';
import { Menu } from '../models/Menu';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit{
  Menu: Menu;
  /**
   * Variables para Logotipo: azure storage
   */
  files: string[] = [];  
  fileToUpload: FormData;  
  fileUpload: any;  
  fileUpoadInitiated: boolean;  
  fileDownloadInitiated: boolean;
  fileName: string;  
  /**
   * TERMINA: Variables para Logotipo: azure storage
   */

  /**
   * Variables para Favicon: azure storage
   */
  Favfiles: string[] = [];  
  FavfileToUpload: FormData;  
  FavfileUpload: any;  
  FavfileUpoadInitiated: boolean;  
  FavfileDownloadInitiated: boolean;
  FavfileName: string;  
  /**
   * TERMINA: Variables para Favicon: azure storage
   */

  public isCollapsed = true;
  public displayOverlay = false;

  public totalOpen: number;

  baseUrl: string;
  opened: boolean;
  item: string;
  userData: User;
  contentType = 0;
  txtSearch = "";
  urlLogo = "";
  themeModel = new WebsiteStyle();
  private themeWrapper = document.querySelector('body');
  isCartManager = false;
  isManageCoupons = false;
  /**
   * Variables para habilitar o deshabilitar Courses/Exams
   */
  enableCourses = true;
  enableExams = true;
  txtPlaceholder = "";

  constructor(
    private cartService: CartService,
    private authenticationService: AuthenticationService,
    private service: RequestsService,
    private alertService: AlertService,
    private route: Router,
    private httpClient: HttpClient,
    private azureClient: AzurestorageService,
    @Inject('BASE_URL') baseUrl: string
  ) {

    
      this.Menu = new Menu();

      this.baseUrl = baseUrl;
      this.userData = new User();

      this.totalOpen = 0;

      const config = JSON.parse(localStorage.getItem('jsonConfig'));
      this.themeModel = config.CssConfig;
      this.enableCourses = config.EnableCourses;
      this.enableExams = config.EnableExams;


      if (this.enableCourses && this.enableExams) {
        this.contentType = ContentType.AllContent;
        this.txtPlaceholder = "Search..."
      }
      else if (this.enableCourses && !this.enableExams) {
        this.contentType = ContentType.Course;
        this.txtPlaceholder = "Search courses..."
      }
      else if (!this.enableCourses && this.enableExams) {
        this.contentType = ContentType.Exam;
        this.txtPlaceholder = "Search exams..."
      }


      GlobalConstans.SetWebsiteTheme(this.themeWrapper, this.themeModel);
      this.urlLogo = GlobalConstans.GetUrlLogo();
    
      this._getMenu();
    this._setFavicon();


  }

  ngOnInit(): void { }

  calcTotal() { return this.cartService._getProductsCart().length; }

  isLogged(){
    this.userData = this.userData === null ? new User() : this.userData;
    this.userData = JSON.parse(localStorage.getItem('currentUser'));
    this.setIsCartManager();
    return JSON.parse(localStorage.getItem('currentUser')) !== null;
  }

  logout(){
    this.authenticationService.logout();
    this.totalOpen == 0;
    if(!this.isCollapsed) this.isCollapsed = true;

    let currentConfig = JSON.parse(localStorage.getItem('jsonConfig'));
    let currentTheme = this.themeModel;

    if(currentConfig !== null && currentConfig !== undefined){

      if(JSON.stringify(currentConfig.CssConfig) !== JSON.stringify(currentTheme)){

        GlobalConstans.SetWebsiteTheme(this.themeWrapper, currentConfig.CssConfig);
        GlobalConstans.ReloadTheme();

      }

    }

    this.route.navigate(['/']);
  }

  goTo(redirect: string){
    this.route.navigate(['/' + redirect]);
  }

  setIsCartManager() {
    //Validacion del IsCartManagement
    const USER = JSON.parse(localStorage.getItem('currentUser'));
    if (USER !== undefined && USER !== null) {
      this.isCartManager = USER.IsCartManager;
      this.isManageCoupons = USER.IsManageCoupons;
    }
  }

  haveMissingInformation() {
    const USER = JSON.parse(localStorage.getItem('currentUser'));
    if (USER !== undefined && USER !== null) {
      return USER.HaveSecurityQuestion && USER.HaveLocalization;
      
    }
    return false;
  }

  searchContent(){
    this.route.navigate(['/search', {"contentType": this.contentType, "search": this.txtSearch}]);
  }

  openThemeCustomization(){
          
  if(this.isCollapsed && this.totalOpen === 0){
  //TODO: Revisamos si el usuario tiene algun tema pendiente
  this.service.GetCssConfigByUserId().subscribe((result:any) => {
    if(result){
      
      this.alertService.onBeforeConfirm(
        "Theme customization",
        "We have found a theme that has not been published. Do you want to apply it right now? These changes will not be reflected until they are published.",
        {"ConfirmButtonText": "Apply", "cancelButtonText": "Continue"})
      .then((response) => {
        if (response.value) {
          //let themeWrapper = document.querySelector('body');
          this.displayOverlay = true;
          this.themeModel = result.CssConfig;
          GlobalConstans.SetWebsiteTheme(this.themeWrapper,result.CssConfig);
          GlobalConstans.ReloadTheme();
          
        } else if (response.dismiss) {
        return true;
        }
      })
    }

  });
  }

  this.totalOpen++;
  this.isCollapsed = !this.isCollapsed;
  }

  onSubmit(form) {
    
    //GlobalConstans.SetWebsiteTheme(this.themeWrapper, form.value);
  }

  onPreview(form){
    
    GlobalConstans.SetWebsiteTheme(this.themeWrapper, form.value);
  }

  onSaveTheme(form){
    
    this.alertService.onBeforeConfirm(
      "Change theme",
      "The current theme customization will be applied ONLY when you will be logged",
      {"ConfirmButtonText": "Confirm", "cancelButtonText": "Cancel"})
    .then((result) => {

      if (result.value) {

        this.service.SaveCssConfig(form.value).subscribe((result)=>{
          
          this.alertService.onConfirm("Perfect", "Theme was changed successfully", "Reload page")
          .then((result)=>{
            this.displayOverlay = true;
            GlobalConstans.SetWebsiteTheme(this.themeWrapper, form.value);
            GlobalConstans.ReloadTheme();
            //location.reload(true);

          });
        });

     
      // For more information about handling dismissals please visit
      // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss) {
      return true;
      }
    })
  }

  onSaveAndPublishTheme(form){
    
    this.alertService.onBeforeConfirm(
      "Change theme",
      "The current theme customization will be applied for all users",
      {"ConfirmButtonText": "Confirm", "cancelButtonText": "Cancel"})
    .then((result) => {
      if (result.value) {

        this.service.SaveAndPublishCssConfig(form.value).subscribe((result)=>{
          
          this.alertService.onConfirm("Perfect", "Theme was changed successfully", "Reload page")
          .then((result)=>{
            this.displayOverlay = true;
            let config = JSON.parse(localStorage.getItem('jsonConfig'));
            if(config !== null && config !== undefined){
              config.CssConfig = form.value;
              localStorage.setItem("jsonConfig", JSON.stringify(config));
            }
            GlobalConstans.SetWebsiteTheme(this.themeWrapper, form.value);
            GlobalConstans.ReloadTheme();
            location.reload();

          });
        });

     
      // For more information about handling dismissals please visit
      // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss) {
      return true;
      }
    })

  }

  onReset(){
    
      let config = JSON.parse(localStorage.getItem('jsonConfig'));
      this.themeModel = config.CssConfig;
      GlobalConstans.SetWebsiteTheme(this.themeWrapper, this.themeModel);
  }

  //========================================================================
  // Logotipo
  //========================================================================
  addFile() {  
    if (!this.fileUpoadInitiated) {  
      document.getElementById('fileUpload').click();  
    }  
  }

  handleFileInput(files: any) {  
    
    const allowed_types = ['image/png', 'image/jpeg','image/gif']

    if(files.length > 0){

      let found = allowed_types.find(x => x.includes(files[0].type))

      if (!found) {
        this.alertService.onError("Ups!","Only Images are allowed ( JPG | PNG | GIF )");
        return;
      }
    }

    //Validar size
    let max_height = 75;
    let max_width = 300;
    var reader = new FileReader();

      reader.readAsDataURL(files[0]); 
      reader.onload = (event) => { 
      var img = new Image();
      img.src = event.target.result as string;
      img.onload = rs => {
  
        const img_height = rs.currentTarget['height'];
        const img_width = rs.currentTarget['width'];

        if (img_height > max_height && img_width > max_width) {
            this.alertService.onError("Ups!",'Maximum dimentions allowed: width ' + max_width  + 'px * height ' + max_height + 'px');
            return;
        } else {
          
          let formData: FormData = new FormData();  
          formData.append("asset", files[0], files[0].name);  
          if(files.length > 0){
            this.fileName = files[0].name;
          }
          this.fileToUpload = formData;  
          this.onUploadFiles();  
        }
      };

      }
    
    //END: Validar size
    
  }

  onUploadFiles() {  
    if (this.fileUpoadInitiated) {  
      return;  
    }  
    this.fileUpoadInitiated = true;  
    if (this.fileToUpload == undefined) {  
      this.fileUpoadInitiated = false;  
      return false;  
    }  
    else {  

      let jsonConfig = localStorage.getItem("jsonConfig");
      let data = JSON.parse(jsonConfig);
      
      this.azureClient.DeleteFile(data.Logo)
      .subscribe((e : any) => {
        
        if(e){

          this.azureClient.InsertFile(this.fileToUpload)
          .subscribe((response: any) => {  
            
            this.fileUpoadInitiated = false;  
            this.fileUpload = ''; 
            
            if (response.ok) {  
              this.service.UpdateLogo(this.fileName)
              .subscribe((res: any) => {
                
                if(res.Success){
                  
                  this.alertService.onConfirm("Perfect", "You logo has been changed successfully", "Reload page")
                  .then((result)=>{
                    this.urlLogo = GlobalConstans.returnUrlLogo(this.fileName);
                    
  
                    let jsonConfig = localStorage.getItem("jsonConfig");
                    let data = JSON.parse(jsonConfig);
  
                    if(data){
                        data.Logo = this.fileName;
                      }
                    localStorage.setItem('jsonConfig', JSON.stringify(data));
                    this.fileName = '';
  
                  });
                }
                
              });
              
            }  
            else {  
              alert('Error occured!');  
              this.fileUpoadInitiated = false;  
            }  
          },  
            err => console.log(err),  
          ); 



        }//cierra e



      });

         
  
    }  
  }

  deleteFile(fileName: string) {  
    
    this.azureClient.DeleteFile(fileName)
    .subscribe(result => {  
      if (result != null) {  
        //this.showBlobs();  
        return true;
      }  
    }, error => console.error(error));  
  }
  //========================================================================
  // END: Logotipo
  //========================================================================

   //========================================================================
  // Favicon
  //========================================================================

  setDefaultPic(){
    this.urlLogo = "assets/img/logo.png";
  }

  favaddFile() {  
    if (!this.FavfileUpoadInitiated) {  
      document.getElementById('FavfileUpload').click();  
    }  
  }

  favhandleFileInput(files: any) {  
    
    const allowed_types = ['image/png', 'image/x-icon'];

    if(files.length > 0){

      let found = allowed_types.find(x => x.includes(files[0].type))

      if (!found) {
        this.alertService.onError("Ups!","Only Images are allowed ( PNG | ICO )");
        return;
      }
    }

    //Validar size
    let max_height = 48;
    let max_width = 48;
    var reader = new FileReader();

      reader.readAsDataURL(files[0]); 
      reader.onload = (event) => { 
      var img = new Image();
      img.src = event.target.result as string;
      img.onload = rs => {
  
        const img_height = rs.currentTarget['height'];
        const img_width = rs.currentTarget['width'];

        if (img_height > max_height && img_width > max_width) {
            this.alertService.onError("Ups!",'Maximum dimentions allowed: width ' + max_width  + 'px * height ' + max_height + 'px');
            return;
        } else {
          
              let formData: FormData = new FormData();  
              formData.append("asset", files[0], files[0].name);  
              if(files.length > 0){
                this.FavfileName = files[0].name;
              }
              this.FavfileToUpload = formData;  
              this.favonUploadFiles();   
        }
      };

      }
    
    //END: Validar size



  }

  favonUploadFiles() {  
    if (this.FavfileUpoadInitiated) {  
      return;  
    }  
    this.FavfileUpoadInitiated = true;  
    if (this.FavfileToUpload == undefined) {  
      this.FavfileUpoadInitiated = false;  
      return false;  
    }  
    else {  

      let jsonConfig = localStorage.getItem("jsonConfig");
      let data = JSON.parse(jsonConfig);
      
      this.azureClient.DeleteFile(data.Favicon)
      .subscribe((e) => {

        if(e){
          this.azureClient.InsertFile(this.FavfileToUpload)
        .subscribe((response: any) => {  
          this.FavfileUpoadInitiated = false;  
          this.FavfileUpload = ''; 
          
          if (response.ok) {  
            this.service.UpdateFavicon(this.FavfileName)
            .subscribe((res: any) => {
              
              if(res.Success){
                
                this.alertService.onConfirm("Perfect", "You favicon has been changed successfully", "Reload page")
                .then((result)=>{
                  var favUrl = GlobalConstans.returnUrlLogo(this.FavfileName);
                  
                  var links = document.getElementsByClassName("favicon");//.setAttribute("href", favUrl);
                  for ( var i = 0; i < links.length; i++ ) {
                        var queryString = '?reload=' + new Date().getTime();
                        links[i].setAttribute("href", favUrl.replace(/\?.*|$/, queryString));
                  }
                  
                  this.FavfileName = '';

                });
              }
              
            });
            
          }  
          else {  
            alert('Error occured!');  
            this.fileUpoadInitiated = false;  
          }  
        },  
          err => console.log(err),  
        );  
        }
      });




        
  
    }  
  }

  favdeleteFile(fileName: string) {  
    
    this.azureClient.DeleteFile(fileName)
    .subscribe(result => {  
      if (result != null) {  
        //this.showBlobs();  
        return true;
      }  
    }, error => console.error(error));  
  }
  //========================================================================
  // END: Favicon
  //========================================================================

  // ====================================================================================================================================
  // GetMenu
  // ====================================================================================================================================

  _getMenu(){
    this.service.GetMenu().subscribe((menu:Menu) => {
      
    let temp = menu.Links.filter(x => x.Enable);
    let enableMenu = new Menu();
    enableMenu.Links = temp;
      this.Menu = enableMenu;
      
      });
  }

  // ====================================================================================================================================
  // setFavicon
  // ====================================================================================================================================
  _setFavicon() {
    
    let link = document.getElementsByClassName('favicon')[0];
    link.setAttribute('href', GlobalConstans.GetUrlFavicon());
  }
}
