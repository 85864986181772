import { WebsiteStyle } from "../models/WebsiteStyle";
//import { AppConfiguration } from "read-appsettings-json";
import { environment } from '../../../environments/environment';
import { isDevMode } from '@angular/core';
export class GlobalConstans {
  constructor() {

  }
  // =====================================================================================================================================================
  // API Path
  // =====================================================================================================================================================
  //private static apiURLBase: string = "http://localhost:61273";
  //private static apiURLBase: string = "https://apidevlms.azurewebsites.net"; // CADENA PARA TEST
  private static apiURLBase: string = "https://apiprodlms.azurewebsites.net" // CADENA PARA PROD
  private static prefix: string = "api";
  private static version: string = "v1";
  private static controller = "storefront";
  public static apiURL = GlobalConstans.apiURLBase + '/' + GlobalConstans.prefix + '/' + GlobalConstans.version + '/' + GlobalConstans.controller;
  public static apiURLStorage = GlobalConstans.apiURLBase + '/' + GlobalConstans.prefix + '/' + GlobalConstans.version + '/resoveContent';

  private static isTesting = false;
  private static blobUrl: string = GlobalConstans.isTesting ? "https://gkanmediadev.blob.core.windows.net/" : "https://gkanmedia.blob.core.windows.net/";
  public static replaceUrl = "https://gkanmedia.blob.core.windows.net/";
  // =====================================================================================================================================================
  // Id para guardar los items del cart
  // =====================================================================================================================================================
  public static cookieId = "8a223add-aaf9-49f3-8a85-260cd264fc38";

  // =====================================================================================================================================================
  // Variables para el logeo de la aplicación
  // =====================================================================================================================================================
  public static USERNAME = "73956B7E-0BC0-46DA-8EFE-11A3F0C2BF8C";
  public static PASSWORD = "648BBAAC-194E-441D-854B-5DBC32E96952";
  public static APPID = "A24baa42-Pbff-P2ae-I60b-Dffd51626f81"

  private static authController: string = "login";
  public static loginAPP = GlobalConstans.apiURLBase + '/' + GlobalConstans.prefix + '/' + GlobalConstans.version + '/' + GlobalConstans.authController;
  // =====================================================================================================================================================
  // Variables STRIPE
  // =====================================================================================================================================================
  //"pk_live_518lotnCWdXdH6345HPAGdA6u23IxjgPEH6aGi9vl3Okt61qMbDkF02F8Wtbe41pXButFE6yGHxBx5HL4tPMRGazJ00IEUZTKDv";
  public static GetStripePublicKey() {

    const PUBLICKEY = GlobalConstans.getCacheValue("PublicKey");

    return PUBLICKEY === null || PUBLICKEY === undefined || PUBLICKEY === ''
      ? ''
      : PUBLICKEY;

  }


  public static CaptchaSiteKey = "6LebJ_giAAAAAEyMpCt-fZf75MYffBVSxcCSW349";
  /**
   * Método que se encarga de armar y regresar la URL de la imagen (Courses)
   */
  public static returnCoursesUrlImage(companyId: string, elementId: string, img: string) {

    //console.log(AppConfiguration.Setting().EnviromentInformation.FileName);
    //console.log({ environment});
    //console.log(isDevMode());

    const blobName = GlobalConstans.isTesting ? "test2-" : "";

    if (img === undefined || img === null) {
      const position = Math.floor(Math.random() * (+6 - +1) + +1);
      return `${GlobalConstans.blobUrl}${blobName}courselanding/test_template${position}.png`;
    } else {
      const url = `${GlobalConstans.blobUrl}${blobName}courselanding/${companyId}/${elementId}/Asset/${img}`;
      return url;
    }

  }


  /**
  * Método que se encarga de armar y regresar la URL de la imagen (Tests)
  */
  public static returnExamsUrlImage(companyId: string, elementId: string, img: string) {

    const blobName = GlobalConstans.isTesting ? "dev2-" : "";

    if (img === undefined || img === null) {
      var position = Math.floor(Math.random() * (+6 - +1) + +1);
      return `${GlobalConstans.blobUrl}${blobName}test-imagepreview/test_template${position}.png`;
    } else {
      var url = `${GlobalConstans.blobUrl}${blobName}testlanding/${companyId}/${elementId}/Asset/${img}`;
      GlobalConstans.imageExists(url, function (exists) {

        if (!exists) {
          return GlobalConstans.returnExamsUrlImage(companyId, elementId, undefined);
        }
      });
      return url;
    }

  }

  /**
   * Método que se encarga de armar y regresar la URL de la imagen (Categories)
   */
  public static returnCategoryUrlImage(companyId: string, elementId: string, img: string) {

    const blobName = GlobalConstans.isTesting ? "test2-" : "";

    if (img === undefined || img === null) {
      const position = Math.floor(Math.random() * (+6 - +1) + +1);

      return `${GlobalConstans.blobUrl}${blobName}categories-imagepreview/cat_template${position}.png`;
    } else {
      const url = `${GlobalConstans.blobUrl}${blobName}categorytlanding/${companyId}/${elementId}/Asset/${img}`;
      GlobalConstans.imageExists(url, function (exists) {
        if (!exists) {
          return GlobalConstans.returnCategoryUrlImage(companyId, elementId, undefined);
        }
      });
      return url;
    }

  }


  /**
   * Método para validar que las imagenes existan
   */
  public static imageExists(url, callback) {
    var img = new Image();
    img.onload = function () { callback(true); };
    img.onerror = function () { callback(false); };
    img.src = url;
  }

  /**
   * Método que se encarga de armar y regresar la URL de la imagen (Categories)
   */

  public static getAuthToken() {
    return JSON.parse(localStorage.getItem('access_token'));
  }

  public static returnGUID() {

    let d = new Date().getTime();
    let d2 = (performance && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16;
      if (d > 0) {
        var r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        var r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
    });

    return uuid;
  }

  public static SetWebsiteTheme(element: HTMLBodyElement, theme: any) {

    if (theme.PrimaryColor) {
      element.style.setProperty('--primary-color', theme.PrimaryColor);
    }

    if (theme.PrimaryColorBlurry) {
      element.style.setProperty('--primary-color-blurry', theme.PrimaryColorBlurry);
    }

    if (theme.LinkColor) {
      element.style.setProperty('--link-color', theme.LinkColor);
    }

    if (theme.PrimaryColor2) {
      element.style.setProperty('--primary-color2', theme.PrimaryColor2);
    }

    if (theme.PrimaryColor3) {
      element.style.setProperty('--primary-color3', theme.PrimaryColor3);
    }

    if (theme.TitleColor) {
      element.style.setProperty('--title-color', theme.TitleColor);
    }

    if (theme.TextColor) {
      element.style.setProperty('--text-color', theme.TextColor);
    }

    if (theme.BaseColor) {
      element.style.setProperty('--baseColor', theme.BaseColor);
    }

    if (theme.Dip) {
      element.style.setProperty('--dip', theme.Dip);
    }

    if (theme.Pfont) {
      element.style.setProperty('--pfont', theme.Pfont);
    }

    if (theme.White) {
      element.style.setProperty('--white', theme.White);
    }

    if (theme.Black) {
      element.style.setProperty('--black', theme.Black);
    }

    if (theme.Offwhite) {
      element.style.setProperty('--offwhite', theme.Offwhite);
    }
  }

  public static ReloadTheme() {
    var queryString = '?reload=' + new Date().getTime();

    var links = document.getElementsByTagName("link");
    for (var i = 0; i < links.length; i++) {

      if (links[i].getAttribute("rel") === "stylesheet") {
        if (links[i].getAttribute("href").includes("style.css") || links[i].getAttribute("href").includes("custom.css")) {
          links[i].setAttribute("href", links[i].getAttribute("href").replace(/\?.*|$/, queryString));
        }

      }

    }
  }

  public static GetUrlLogo() {
    
    let jsonConfig = localStorage.getItem("jsonConfig");
    let data = JSON.parse(jsonConfig);

    if (data) {
      if (data.Logo === undefined || data.Logo === null) {
        data.Logo = "assets/img/logo.png";
      } else {
        data.Logo = GlobalConstans.returnUrlLogo(data.Logo);
      }
    }
    //localStorage.setItem('jsonConfig', JSON.stringify(data));
    return data.Logo;
  }

  public static GetUrlFavicon() {

    const jsonConfig = localStorage.getItem("jsonConfig");
    const data = JSON.parse(jsonConfig);
    let urlFav = "";

    if (data) {
      if (data.Favicon === null || data.Favicon === undefined) {
        urlFav = "assets/img/Favicon.ico";
      } else {
        const orgId = data.OrganizationId;
        const icon = data.Favicon;
        urlFav = `${GlobalConstans.blobUrl}storefront/${orgId}/${icon}`;
      }

      return urlFav;
    }
  }

  public static returnUrlLogo(img: string) {

    const config = JSON.parse(localStorage.getItem('jsonConfig'));
    return `${GlobalConstans.blobUrl}storefront/${config.OrganizationId}/${img}`;
  }

  public static GetBannerImage(img: string) {

    const jsonConfig = localStorage.getItem("jsonConfig");
    const data = JSON.parse(jsonConfig);

    return `${GlobalConstans.blobUrl}storefront/${data.OrganizationId}/banner/${img}`;
  }

  public static generateShortGuid() {
    var gfirstPart = (Math.random() * 46656) | 0;
    var gsecondPart = (Math.random() * 46656) | 0;
    let firstPart = ("000" + gfirstPart.toString(36)).slice(-3);
    let secondPart = ("000" + gsecondPart.toString(36)).slice(-3);
    return firstPart + secondPart;
  }

  public static returnIconByContent(type: any) {
    // Types
    // 1.- Media
    // 2.- Questions
    // 3.- Tests
    // 4.- Sections

    let $type = parseInt(type.Type || 1);
    let $contentType = parseInt(type.ContentType);
    var icon = "fal fa- unlink";
    if ($type === 1) {
      switch ($contentType) {
        case 0:
          icon = "fal fa-newspaper";
          break;
        case 1:
          icon = "fal fa-music";
          break;
        case 2:
          icon = "fal fa-image";
          break;
        case 3:
          icon = "fal fa-video";
          break;
        case 5:
          icon = "fal fa-file-powerpoint";
          break;
        case 6:
          icon = "fal fa-file-pdf";
          break;
        case 7:
          icon = "fal fa-font";
          break;
        case 8:
          icon = "fal fa-file-archive";
          break;
        case 15:
          icon = "fal fa-download";
          break;
        case 11:
          icon = "fal fa-font";
          break;
        case 16:
          icon = "fal fa-video-plus";
          break;
      }
    } else if ($type === 2) {
      switch ($contentType) {
        case 1:
          icon = "fa fa-file-o";
          break;
        case 2:
          icon = "fal fa-clone";
          break;
        case 3:
          icon = "fal fa-paste";
          break;
        case 4:
          icon = "fal fa-check-circle";
          break;
        case 5:
          icon = "fal fa-check-square";
          break;
        case 6:
          icon = "fal fa-sticky-note";
          break;
        case 7:
          icon = "fal fa-toggle-off";
          break;
        case 10:
          icon = "fal fa-terminal";
          break;
        case 11:
          icon = "fal fa-object-ungroup";
          break;
        case 17:
          icon = "fal fa-sort-amount-down";

          break;
        case 19:
          icon = "far fa-image-polaroid";
          break;
        case 18:
          icon = "fal fa-window-alt";
          break;
        case 20:
          icon = "far fa-book-reader";
          break;
        case 21:
          icon = "far fa-images";
          break;
        case 22:
          icon = "far fa-photo-video";
          break;
        case 23:
          icon = "fas fa-columns";
          break;
        case 24:
          icon = "far fa-image-polaroid fa-rotate-90";//<i class=""></i>
          break;

      }
    }
    else if ($type === 3) {
      icon = "fal fa-file-alt";
    }
    else if ($type === 4) {
      icon = "";
    }

    return icon;
  }

  public static getCacheValue(key: string) {

    if (key === '') return undefined;

    let config = JSON.parse(localStorage.getItem("jsonConfig"));
    if (config !== null && config !== undefined) {
      return config[key];
    }
  }

  public static getUserCacheObject(key: string) {
    if (key === '') return undefined;

    let config = JSON.parse(localStorage.getItem("currentUser"));
    if (config !== null && config !== undefined) {
      return config[key];
    }

    return undefined;
  }

  public static updateCacheValue(key: string, value: any) {

    if (key === '') return false;

    let config = JSON.parse(localStorage.getItem("jsonConfig"));
    if (config !== null && config !== undefined) {
      config[key] = value;
      localStorage.setItem('jsonConfig', JSON.stringify(config));
      return true;
    }

  }
}
