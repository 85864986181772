import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AlertService } from '../shared/services/alert/alert.service';
import { AuthenticationService } from '../shared/services/authentication/authentication.service';
import { first } from 'rxjs/operators';
import { MailRequest } from '../shared/models/MailRequest';
import { EmailType } from '../shared/enums/EmailType';
import { EmailService } from '../shared/services/email/email.service';
import { User } from '../shared/models/User';
import { GlobalConstans } from '../shared/global-constants/constants';
import { InvisibleReCaptchaComponent, ReCaptcha2Component, ReCaptchaV3Service } from 'ngx-captcha';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css']
})
export class ForgotComponent implements OnInit {
  recoverForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  siteKey: any;
  //captchaToken: string;

  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaResponse?: string;
  public captchaIsReady = false;

  public badge: 'bottomright' | 'bottomleft' | 'inline' = 'inline';
  public type: 'image' | 'audio';
  public theme: 'light' | 'dark' = 'light';

  public recaptcha: any = null;

  @ViewChild('captchaElem', { static: false }) captchaElem: InvisibleReCaptchaComponent;
  @ViewChild('langInput', { static: false }) langInput: ElementRef;

  //@ViewChild('recaptchaElement', { static: true }) recaptchaElement: ReCaptcha2Component;
  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private emailService: EmailService,
    private rutas: Router,
    private reCaptchaV3Service: ReCaptchaV3Service,
    private cdr: ChangeDetectorRef  ) {

    this.recoverForm = new FormGroup({ useremail: new FormControl()});
    this.siteKey = GlobalConstans.CaptchaSiteKey;

   }

  ngOnInit(): void {
    this.recoverForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
  });
  }

  get f() { return this.recoverForm.controls; }

  onSubmit() {
    
    this.submitted = true;

    // stop here if form is invalid
    if (this.recoverForm.invalid) { return; }

    //if (this.captchaToken !== undefined && this.captchaToken !== null && this.captchaToken !== "") {

      this.loading = true;

      this.authenticationService.validateUserByEmail(this.recoverForm.value.email)
        .subscribe((_user: User) => {
          
          if (_user) {

            let model = new MailRequest();
            model.UserId = _user.Id;
            model.Name = _user.FirstName;
            model.LastName = _user.LastName;
            model.Email = this.recoverForm.value.email;
            model.ToEmail = this.recoverForm.value.email;
            model.EmailType = EmailType.PasswordRecovery;
            //model.DomainId = currentDomain[0].id;
            model.CurrentUrl = document.location.origin;
            model.BearerToken = GlobalConstans.getAuthToken();

            this.emailService.recover(model).subscribe((res: any) => {
              if (res) {

                this.alertService
                  .onConfirm('Perfect!', "We have send you an email with instructions to recover your password")
                  .then((result) => {
                    if (result.value) {
                      this.loading = false;
                      this.rutas.navigate(['/index']);
                      return true;
                    }
                  });
                return;


              }

            }, err => {
              this.alertService
                .onConfirm('Perfect!', "We have send you an email with instructions to recover your password")
                .then((result) => {
                  if (result.value) {
                    this.loading = false;
                    this.rutas.navigate(['/index']);
                    return true;
                  }
                });
              return;
            });

          } else {
            this.alertService
              .onConfirm('Perfect!', "We have send you an email with instructions to recover your password")
              .then((result) => {
                if (result.value) {
                  this.loading = false;
                  this.rutas.navigate(['/index']);
                  return true;
                }
              });
            return;
          }

        }, err => {

          this.alertService
            .onConfirm('Perfect!', "We have send you an email with instructions to recover your password")
            .then((result) => {
              if (result.value) {
                this.loading = false;
                this.rutas.navigate(['/index']);
                return true;
              }
            });
          return;

        });
    //}



}

  //handleLoad() { }
  //handleSuccess($event) { console.log('handleSuccess'); }
  //handleExpire() { }
  //handleReset() { }
  //handleReady() { }
  ngAfterViewInit(): void {
    this.captchaIsLoaded = true;
    this.cdr.detectChanges();
    //this.highlight();
  }

  execute(): void {
    this.captchaElem.execute();
  }

  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.cdr.detectChanges();
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.cdr.detectChanges();
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.cdr.detectChanges();
  }

  handleReady(): void {
    this.captchaIsReady = true;
    this.cdr.detectChanges();
  }

  changeBadge(badge: 'bottomright' | 'bottomleft' | 'inline' = 'bottomright'): void {
    this.badge = badge;
  }

  changeType(type: 'image' | 'audio'): void {
    this.type = type;
  }

  getResponse(): void {
    const response = this.captchaElem.getResponse();
    if (!response) {
      alert(`There is no response from grecaptcha script - try using 'getCurrentResponse' method or subscribe to 'success' event`);
    } else {
      alert(response);
    }
  }

  reload(): void {
    this.captchaElem.reloadCaptcha();
  }

  getCaptchaId(): void {
    alert(this.captchaElem.getCaptchaId());
  }

  reset(): void {
    this.captchaElem.resetCaptcha();
  }

  getCurrentResponse(): void {
    const currentResponse = this.captchaElem.getCurrentResponse();
    if (!currentResponse) {
      alert('There is no current response - have you submitted captcha?');
    } else {
      alert(currentResponse);
    }
  }

  changeTheme(theme: 'light' | 'dark'): void {
    this.theme = theme;
  }

  //private highlight(): void {
  //  const highlightBlocks = document.getElementsByTagName('code');
  //  for (let i = 0; i < highlightBlocks.length; i++) {
  //    const block = highlightBlocks[i];
  //    hljs.highlightBlock(block);
  //  }
  //}

}
