import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { first} from 'rxjs/operators';

//Service
import { CartService } from '../shared/services/cart.service';
import { AuthenticationService } from '../shared/services/authentication/authentication.service';
import { AlertService } from '../shared/services/alert/alert.service';
import { RequestsService } from '../shared/services/requests.service';
import { EmailService } from '../shared/services/email/email.service';
import { PaymentService } from '../shared/services/payment/payment.service';

//Interfaces
import { IProduct } from '../shared/interfaces/IProduct';
import { RedeemProductResponse } from '../shared/models/RedeemProductResponse';
import { RedeemOrder } from '../shared/models/RedeemOrder';
import { EmailType } from '../shared/enums/EmailType';

//Enum
import { ContentType } from '../shared/enums/ContentType'

import { StripeService, Elements, Element as StripeElement, ElementsOptions } from 'ngx-stripe';
import { MailRequest } from '../shared/models/MailRequest';
import { GlobalConstans } from '../shared/global-constants/constants';
import { NgxSpinnerService } from "ngx-spinner";
import { CouponsService } from '../shared/services/coupons/coupons.service';
import { DiscountCoupon } from '../shared/models/DiscountCoupon';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {

  availableCoupons: Array<DiscountCoupon> = new Array();
  discountCoupons: Array<DiscountCoupon> = new Array();

  courseContent = 0;
  examContent = 0;
  allContent = 0;
  coupon: string = "";


  cart: IProduct[];
  totalPrice: number;
  acceptTerms: boolean;
  registerForm: FormGroup;
  submitted = false;
  loading = false;

  //Login variables
  loginForm: FormGroup;
  submittedLogin = false;
  loadingLogin = false;
  error = '';

  //ngx stripe
  elements: Elements;
  card: StripeElement;
  elementsOptions: ElementsOptions = {
    locale: 'en'
  };
  stripeTest: FormGroup;

  //var for enablecoupons
  public enableCoupons: false;
  constructor(
    private cartService: CartService,
    private fb: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private service: RequestsService,
    private stripeService: StripeService,
    private emailService: EmailService,
    private paymentSevice: PaymentService,
    private couponSevice: CouponsService,
    private spinner: NgxSpinnerService) {

    const PUBLICKEY = GlobalConstans.getCacheValue("PublicKey");
    stripeService.changeKey(PUBLICKEY);

    this.courseContent = ContentType.Course;
    this.examContent = ContentType.Exam;
    this.allContent = ContentType.AllContent;

    this.loginForm = new FormGroup({
      username: new FormControl(),
      password: new FormControl()
    });

  }

  ngOnInit(): void {


    this.enableCoupons = GlobalConstans.getCacheValue("DiscountCoupons");
    this.stripeTest = this.fb.group({
      name: ['', [Validators.required]]
    });
    

    setTimeout(() => {
      this.stripeService.elements(this.elementsOptions)
        .subscribe(elements => {
          this.elements = elements;
          // Only mount the element the first time
          if (!this.card) {
            this.card = this.elements.create('card', {
              style: {
                base: {
                  iconColor: '#666EE8',
                  color: '#31325F',
                  lineHeight: '40px',
                  fontWeight: 300,
                  fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                  fontSize: '18px',
                  '::placeholder': {
                    color: '#CFD7E0'
                  }
                }
              }
            });
            this.card.mount('#card-element');
          }
        });
    },500)
    


    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.loadCart();
  }

  private loadCart() {

    const res = this.cartService._getProductsCart();
    this.cart = res;
    this.totalPrice = this.cart.reduce((a, b) => a + b.Total, 0);
  }

  get f() {
    return this.stripeTest.controls;
  }
  get l() {
    return this.loginForm.controls;
  }


  buy() {
    
    this.submitted = true;

    // stop here if form is invalid
    if (this.stripeTest.invalid) {
      return;
    }

    if (!this.isLogged()) {

      this.alertService
        .onError('Sorry!', "You must to be logged before pay your order!")
        .then((result) => {
          if (result.value) {
            return true;
          }
        });
      return;
    }

    this.loading = true;
    this.spinner.show("processingorder");
    const _products = this.cart.map(function (x) {
      return {
        'ContentId': x.Id,
        'Type': x.Type === 2 ? 'Course' : 'Exam',
        'Name': x.Name,
        'ProductId': x.GlobalId
      }
    });

    const TOTAL = Math.round(this.discountCoupons.length > 0 ?
      (this.totalPrice - this.discountCoupons.reduce((a, b) => a + b.Subtotal, 0)) :
      this.totalPrice);

    if (TOTAL === 0 && this.discountCoupons.length > 0) {
      
      this.GenerateOrder()
        .subscribe((guid: string) => {
          if (guid !== undefined && guid !== null) {
            
            // ===============================================================
            // START: Redeem Products
            // ===============================================================
            this.GenerateRedeemOrder(guid)
              .subscribe((res: RedeemProductResponse[]) => {
                
                if (res) {
                  //================================================================
                  // START: Email Request
                  //================================================================

                  this.GenerateConfirmationEmail(guid)
                    .subscribe((res: any) => {
                      
                      if (res.body) {
                        this.spinner.hide("processingorder");
                        this.alertService
                          .onConfirm('Perfect!', "Your purchase has been successful")
                          .then((result) => {
                            if (result.value) {
                              if (this.cartService._deleteAllProducts()) this.router.navigate(['/confirmation', guid, 1]);
                            }
                          });
                        return;
                      }
                    }, err => {
                      this.spinner.hide("processingorder");
                      this.alertService
                        .onConfirm('Perfect!', "We couldn't send your email but your order and payment was successfully")
                        .then((result) => {
                          if (result.value) {
                            if (this.cartService._deleteAllProducts()) this.router.navigate(['/confirmation', guid, 1]);
                          }
                        });
                      return;

                    });
                  //================================================================
                  // END: Email Request
                  //================================================================
                }
              });
            // ===============================================================
            // END: Redeem Products
            // ===============================================================
          } else {
            this.GenerateAlertError('Sorry!', "We couldn't generate your order, please try again later.")
          }
        }, err => {
          this.GenerateAlertError('Sorry!', "We couldn't generate your order, please try again later.")
        });
    } else {
      
      this.GenerateStripeToken()
        .subscribe(result => {
          if (result.token) {

            this.GenerateCharge(result.token.id, TOTAL, _products)
              .subscribe((res: any) => {

                const PAYMENTRESPONSE = res.body;

                if (PAYMENTRESPONSE.success) {

                  this.GenerateOrder()
                    .subscribe((guid: string) => {
                      if (guid !== undefined && guid !== null) {

                        // ===============================================================
                        // START: Redeem Products
                        // ===============================================================
                        this.GenerateRedeemOrder(guid)
                          .subscribe((res: RedeemProductResponse[]) => {

                            if (res) {
                              //================================================================
                              // START: Email Request
                              //================================================================

                              this.GenerateConfirmationEmail(guid)
                                .subscribe((res: any) => {

                                  if (res.body) {
                                    this.spinner.hide("processingorder");
                                    this.alertService
                                      .onConfirm('Perfect!', "Your purchase has been successful")
                                      .then((result) => {
                                        if (result.value) {
                                          if (this.cartService._deleteAllProducts()) this.router.navigate(['/confirmation', guid, 1]);
                                        }
                                      });
                                    return;
                                  }
                                }, err => {
                                  this.spinner.hide("processingorder");
                                  this.alertService
                                    .onConfirm('Perfect!', "We couldn't send your email but your order and payment was successfully")
                                    .then((result) => {
                                      if (result.value) {
                                        if (this.cartService._deleteAllProducts()) this.router.navigate(['/confirmation', guid, 1]);
                                      }
                                    });
                                  return;

                                });
                              //================================================================
                              // END: Email Request
                              //================================================================
                            }
                          });
                        // ===============================================================
                        // END: Redeem Products
                        // ===============================================================
                      } else {
                        this.GenerateAlertError('Sorry!', "We couldn't generate your order, please try again later.")
                      }
                    }, err => {
                      this.GenerateAlertError('Sorry!', "We couldn't generate your order, please try again later.")
                    });
                } else {
                  this.GenerateAlertError('Sorry!', PAYMENTRESPONSE.message)
                }
                //END: PAYMENT SUCCESS

              });

          } else if (result.error) {
            this.GenerateAlertError('Sorry!', result.error.message);
          }
        });
    }

    
  }

  //=========================================================================
  // INTERNAL FUNCTIONS
  //=========================================================================

  GenerateStripeToken() {
    
    const name = this.stripeTest.get('name').value;
    return this.stripeService.createToken(this.card, { name });
  }

  GenerateCharge(tokenId: any, total: any, _products: any) {
    
    return this.paymentSevice.charge(tokenId, GlobalConstans.getUserCacheObject("Email"), total, JSON.stringify(_products), GlobalConstans.getUserCacheObject("FullName"));
  }

  GenerateOrder() {
    
    let couponsString = '';
    let discount = 0;
    if (this.availableCoupons.length > 0) {
      couponsString = JSON.stringify(this.availableCoupons.map((x) => {
        return {
          Id: x.Id,
          Discount: x.Discount,
          Subtotal: x.Subtotal
        }
      }));

      discount = Math.round(this.discountCoupons.reduce((a, b) => a + b.Subtotal, 0));
    }

    return this.cartService.generateOrder(couponsString, discount);
  }

  GenerateRedeemOrder(guid: string) {
    
    let redeemModel = new RedeemOrder();
    redeemModel.GlobalOrderId = guid;
    redeemModel.UserId = GlobalConstans.getUserCacheObject("Id");
    return this.service.redeemOrder(redeemModel)
  }

  GenerateConfirmationEmail(guid: string) {
    
    let model = new MailRequest();
    model.Name = GlobalConstans.getUserCacheObject("FirstName");
    model.LastName = GlobalConstans.getUserCacheObject("LastName");
    model.Email = GlobalConstans.getUserCacheObject("Email");
    model.ToEmail = GlobalConstans.getUserCacheObject("Email");
    model.EmailType = EmailType.PurchaseConfirmation;
    model.BearerToken = GlobalConstans.getAuthToken();
    model.ProductList = this.cart.map(x => x.Name);
    model.OrderId = guid;

    return this.emailService.confirmation(model)
  }

  GenerateAlertError(title: string, message: string) {
    
    this.spinner.hide("processingorder");
    return this._OnErrorAlert(title, message)
      .then((result) => {
          if (result.value) {
              this.loading = false;
              return true;
            }
      });
    
  }
  //=========================================================================
  //=========================================================================

  onLogin() {
    this.submittedLogin = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loadingLogin = true;
    this.authenticationService.login(this.l.username.value, this.l.password.value)
      .pipe(first())
      .subscribe(
        data => {

          if (data) return this.router.navigate(['/checkout']);

          this.error = "Username or password is incorrect";
          this.loadingLogin = false;
        },
        error => {
          this.error = error;
          this.loadingLogin = true;
        });
  }

  isLogged() {
    return JSON.parse(localStorage.getItem('currentUser')) !== null;
  }

  _OnErrorAlert(title: string, message: string) {

    return this.alertService.onError(title, message).then((result) => {
      if (result.value) {
        this.loading = false;
        return true;
      }
    });


  }

  validateCoupon() {

    if (this.coupon === '') return;

    if (this.availableCoupons.filter((x) => x.Coupon === this.coupon).length > 0) {
      return this.alertService.onInfo("Sorry!", "The '" + this.coupon + "' is applied");
    }

    this.spinner.show('validatecoupon');

    let model = new DiscountCoupon();
    model.Coupon = this.coupon;

    this.couponSevice.redeemCoupon(model)
      .subscribe((response) => {
        
        if (response.Id === 0) {
          this.alertService.onInfo("Sorry!", "It looks like your discount coupon doesn't exist");
        } else {
          this.coupon = '';
          this.availableCoupons.push(response);
          this.discountCoupons.push(response);
        }
        this.spinner.hide('validatecoupon');

      })

  }

  deleteCoupon(coupon: DiscountCoupon) {
    this.availableCoupons.forEach((item, index) => {
      if (item.Id === coupon.Id) this.availableCoupons.splice(index, 1);
    });

    this.discountCoupons.forEach((item, index) => {
      if (item.Id === coupon.Id) this.discountCoupons.splice(index, 1);
    });
  }

  caculatePercentage(coupon: DiscountCoupon) {

    var total = (coupon.Discount / 100) * this.totalPrice;
    var result = Math.round(total); //total.toFixed(2);

    this.discountCoupons.forEach((item, index) => {
      if (item.Id === coupon.Id) this.discountCoupons[index].Subtotal = result;
    })

    return result;
  }

  discountFullTotal() {
    var discountTotal = this.discountCoupons.reduce((a, b) => a + b.Subtotal, 0);
    var fullTotal = this.totalPrice - discountTotal;
    if (fullTotal < 0) {
      return 0;
    }
    return Math.round(fullTotal); //fullTotal.toFixed(2);
  }


}
