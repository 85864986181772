import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";

//Service
import { CartService } from "../shared/services/cart.service";



//Constants
import { GlobalConstans } from "../shared/global-constants/constants";

//Models
import { Order } from "../shared/models/Order";

//Interface
import { IProduct } from "../shared/interfaces/IProduct";
import { CheckoutConfirmation } from "../shared/models/CheckoutConfirmation";
import { RedeemProductResponse } from "../shared/models/RedeemProductResponse";

@Component({
  selector: "app-confirmation",
  templateUrl: "./confirmation.component.html",
  styleUrls: ["./confirmation.component.css"],
})
export class ConfirmationComponent implements OnInit {
  currentOrder: Order;
  cart: IProduct[];
  totalPrice: number;
  slug: string;
  redeem: number;

  redeemResponse: Array<RedeemProductResponse> = new Array();

  //user vars
  initials: string;
  firstName: string;
  lastName: string;
  email: string;

  constructor(
    private route: ActivatedRoute,
    private cartService: CartService,
    private rutas: Router
  ) {
    this.currentOrder = new Order();

    if (GlobalConstans.getUserCacheObject("Id") === undefined) {
      this.rutas.navigate(["/index"]);
    }

    this.route.params.subscribe((parameters) => {
      this.slug = parameters.slug;
      this.redeem = parseInt(parameters.redeem);
    });

    if (this.slug === null) {
      this.rutas.navigate(["/index"]);
    }

    const redeemProducts = this.redeem === 1; //Bandera para saber que mensaje mostrar

    this.initials = GlobalConstans.getUserCacheObject("Initials");
    this.firstName = GlobalConstans.getUserCacheObject("FirstName");
    this.lastName = GlobalConstans.getUserCacheObject("LastName");
    this.email = GlobalConstans.getUserCacheObject("Email");

    this.cartService.getOrderDetailsBySlug(this.slug).subscribe(
      (checkout: CheckoutConfirmation) => {
        
        this.currentOrder.Id = this.slug;
        this.currentOrder.Total = checkout.GrandTotal;
        this.currentOrder.Date = checkout.Created;
        this.currentOrder.PaymentType = "Credit card";
        this.currentOrder.CouponApplied = checkout.CouponApplied;
        this.currentOrder.TotalDiscount = checkout.TotalDiscount;

        this.totalPrice = checkout.GrandTotal;
        this.cart = checkout.Products;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  ngOnInit(): void { }
}
