import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpErrorResponse  } from '@angular/common/http';

//Models
import { EmailTemplate } from '../shared/models/EmailTemplate';
import { EmailType } from '../shared/enums/EmailType';

//Services
import { AlertService } from '../shared/services/alert/alert.service';
import { RequestsService } from '../shared/services/requests.service';
import { EmailService } from '../shared/services/email/email.service';
import { SendContactEmail } from '../shared/models/SendContactEmail';
import { MailRequest } from '../shared/models/MailRequest';
import { Domains } from '../shared/models/Domains';
import { GlobalConstans } from '../shared/global-constants/constants';
import { ContactInfo } from '../shared/models/ContactInfo';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  href: string = "";
  contactForm: FormGroup;
  submitted = false;
  loading = false;
  domainId = 0;


  contactInfo: ContactInfo;

  constructor(private formBuilder: FormBuilder,
    private alertService: AlertService,
    private emailService: EmailService,
    private service: RequestsService) {

    this.contactInfo = new ContactInfo();

    this.contactForm = new FormGroup({
      name:new FormControl(),
      subject: new FormControl(),
      email: new FormControl(),
      message: new FormControl()
    });
    
    let jsonConfig = JSON.parse(localStorage.getItem('jsonConfig'));
    if(jsonConfig !== undefined){
      this.domainId =parseInt(jsonConfig["Id"]);
    }

   }

  ngOnInit(): void {
    this.resetContactForm();

    this.service.GetContactInfo()
    .subscribe((address) => {
      console.log({address});
      this.contactInfo = address;
    });
  }

resetContactForm(){
  this.contactForm = this.formBuilder.group({
    name: ['', Validators.required],
    subject: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    message: ['', Validators.required]
   
  });
}

  get f() { return this.contactForm.controls; }

  onSubmit(){
    this.submitted = true;
    // stop here if form is invalid
    if (this.contactForm.invalid) { return; }

    this.loading = true;
    

        let model = new MailRequest();
        model.Name = this.contactForm.controls['name'].value;
        model.Subject = this.contactForm.controls['subject'].value;
        model.Email = this.contactForm.controls['email'].value;
        model.Body = this.contactForm.controls['message'].value;
        model.DomainId = this.domainId;//currentDomain[0].id;
        model.EmailType = EmailType.Contact;
        model.BearerToken = GlobalConstans.getAuthToken();
        
        this.emailService.contact(model).subscribe((res: any) => {
          if(res){

            this.alertService
            .onConfirm('Perfect!',"We will contact you as soon as possible. Thank you.")
            .then((result) => {
              if (result.value) {
                this.contactForm.reset();
                this.loading = false;
                this.submitted = false;
                return true;
              }
            });
            return;

            
          }
    
        }, err => { 
          this.alertService
          .onError('Sorry!',"We couldn't send your email, please try again later")
          .then((result) => {
            if (result.value) {
              console.log("Error send: " + err); 
              this.loading = false;
              return true;
            }
          });
          return;
        });
  }

}
