import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, forkJoin } from 'rxjs';

//Constants
import { GlobalConstans } from '../shared/global-constants/constants';

//Models
import { Course } from '../shared/models/Course';
import { Lesson } from '../shared/models/Lesson';
import { Product } from '../shared/models/Product';

//Interfaces
import { ICourses } from '../shared/interfaces/courses';
import { ILesson } from '../shared/interfaces/lesson';
import { ICourseShell } from '../shared/interfaces/courseshell';

//Service
import { RequestsService } from '../shared/services/requests.service';
import { CartService } from '../shared/services/cart.service';
import { ToastService } from '../shared/services/toast/toast.service';

@Component({
  selector: 'app-productdetails',
  templateUrl: './productdetails.component.html',
  styleUrls: ['./productdetails.component.css']
})
export class ProductdetailsComponent implements OnInit {
  globalId: string;
  currentCourse: ICourses;
  shell: ILesson[];
  loading = false;
  loadingShell: boolean;
  activeIds: string[] = [];
  videoId: string;
  isYouTube: boolean;
  totalHours: string;

  constructor(private route: ActivatedRoute,
    private service: RequestsService,
    private cartService: CartService,
    private toastService: ToastService,
    private modalService: NgbModal) {
    
    //this.videoId = "";
    
    this.currentCourse = new Course();
    this.shell = Array<Lesson>();
    this.loadingShell = false;
    this.route.params.subscribe(parameters => { this.globalId = parameters.globalId; });
   }

  ngOnInit(): void {
    this.service.getCourseDetailsById(this.globalId).subscribe((res: ICourses) => { 
      this.currentCourse = res;
      
      let companyId = this.currentCourse.CompanyId.toString();
      let courseId = this.currentCourse.Id.toString();
      this.currentCourse.ImgSrc =  GlobalConstans.returnCoursesUrlImage(companyId, courseId, this.currentCourse.ImgSrc);
      this.totalHours = this.secondsToDhms(this.currentCourse.CourseTime);
      this.loading = true;
      
    }, err => { console.log(err); });

    this.service.getShell(this.globalId).subscribe((res: ICourseShell) => { 
      this.shell = res.Lesson;
      this.loadingShell = true;
    }, err => { console.log(err); });

  }

  secondsToDhms(seconds) {
    seconds = Number(seconds);

    if(seconds > 0){
      var d = Math.floor(seconds / (3600*24));
      var h = Math.floor(seconds % (3600*24) / 3600);
      var m = Math.floor(seconds % 3600 / 60);
      var s = Math.floor(seconds % 60);
      
      // var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
      // var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
      // var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
      // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
      
      var dDisplay = d > 0 ? d + (d == 1 ? "d " : "d ") : "";
      var hDisplay = h > 0 ? h + (h == 1 ? "h " : "h ") : "";
      var mDisplay = m > 0 ? m + (m == 1 ? "m " : "m ") : "";
      var sDisplay = s > 0 ? s + (s == 1 ? "s" : "s") : "";
      return dDisplay + hDisplay + mDisplay + sDisplay;
    }
    else{
      return "0 s"
    }
    
    
    }

  getIcon(type:any){
    return GlobalConstans.returnIconByContent(type);
  }

  openAll(){
    this.shell.forEach((element,key) => {
      this.activeIds.push("panel-" + key.toString());
    });
  }
  
  addProduct(model: any){

    this.cartService._addProductToCart(model);
    
  }

    openWindowCustomClass(content) {
      //this.currentCourse.VideoSrc = "https://vjs.zencdn.net/v/oceans.mp4";
      this.getYouTubeId();
      this.modalService.open(content, { centered: true, size: 'lg' });
    }
 
getYouTubeId(){
  //Extraje el componente de aqui: https://stackblitz.com/edit/angular-youtube-video?file=src%2Findex.html
  let $this = this.currentCourse.VideoSrc;

        if ($this.match(/(\?|&)v=([^&#]+)/)) {
            $this = $this.match(/(\?|&)v=([^&#]+)/).pop();
            this.isYouTube = true;

        } else if ($this.match(/(\.be\/)+([^\/]+)/)) {
          $this = $this.match(/(\.be\/)+([^\/]+)/).pop();
          this.isYouTube = true;

        } else if ($this.match(/(\embed\/)+([^\/]+)/)) {
          $this = $this.match(/(\embed\/)+([^\/]+)/).pop().replace('?rel=0','');
          this.isYouTube = true;
        }
        
        this.videoId = $this;
}

setDefaultPic() {
  this.currentCourse.ImgSrc = GlobalConstans.returnCoursesUrlImage("0", "0", undefined);
}

print(): void {
  this.openAll();
  let footer = document.getElementById('footer');
  footer.classList.add("d-none");

    setTimeout(function () {
      window.print();
    }, 1000);

    setTimeout(function () {
      footer.classList.remove("d-none");
    }, 1000);

}

getFileTime(files){

  let time = 0;
  if(files !== undefined){

    if(files.File !== undefined && files.File.length > 0){
      files.File.forEach(element => {
        time = time + parseInt(element.Time);
      });
    }else if(files.File.Time !== undefined){
      time = parseInt(files.File.Time)
    }

  }
  return time;
  

  

}

  getTimeBySubSection(section) {

  if(section.Content.length === 0) return;
  
  let time = 0;

    section.Content.forEach(element => {
      if (element.File !== undefined) {
        element.File.forEach(file => {

          if (file.Time !== null && file.Time !== undefined) {
            time = time + parseInt(file.Time);
          }

          
        });
      }
    
  });

  return time;
}

getTimeByLesson(lesson){
  let time = 0;

  if (lesson) {

    lesson.Section.forEach(section => {
      
      if(section.Type === '4' && section.ContentType === '1'){
        
        const result = this.getTimeBySubSection(section);
        time += result;

      }
    });
  }

  return time;
  
}

}
