import { Injectable, Inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { GlobalConstans } from '../../global-constants/constants';


@Injectable({
  providedIn: 'root'
})
export class StorageService {


  apiUrl: string = GlobalConstans.apiURLStorage;
  headers = new HttpHeaders().set('Content-Type', 'application/json');

  appSettings: any;
  loaded = false;



  // Handle Errors 
  error(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  constructor(private http: HttpClient) {
    //=====================================================================================
    // Lectura de appsetings.json
    //=====================================================================================
    this.appSettings = JSON.parse(localStorage.getItem('jsonConfig'));
    if (this.appSettings === null || this.appSettings === undefined) {
      console.log("RequestService: No hay datos en this.appSettings");
    }
    //=====================================================================================
    // TERMINA: Lectura de appsetings.json
    //=====================================================================================

  }

  resolveStorage(blobfile): Observable<any> {
    let API_URL = `${this.apiUrl}/GetStorage/${blobfile}`;

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    return this.http.get<any>(API_URL, options).pipe(catchError(this.error));

  }

}
