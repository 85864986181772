import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { GlobalConstans } from '../../global-constants/constants';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { DiscountCoupon } from '../../models/DiscountCoupon';
import { UsersByCoupon } from '../../models/UsersByCoupon';

@Injectable({
  providedIn: 'root'
})
export class CouponsService {
  apiUrl: string = GlobalConstans.apiURL;
  headers = new HttpHeaders().set('Content-Type', 'application/json');

  appSettings: any;
  loaded = false;
  constructor(private http: HttpClient) {
    //=====================================================================================
    // Lectura de appsetings.json
    //=====================================================================================
    this.appSettings = JSON.parse(localStorage.getItem('jsonConfig'));
    if (this.appSettings === null || this.appSettings === undefined) {
      console.log("RequestService: No hay datos en coupon service");
    }
    //=====================================================================================
    // TERMINA: Lectura de appsetings.json
    //=====================================================================================
  }

  addCoupon(data: DiscountCoupon): Observable<any> {
    let API_URL = `${this.apiUrl}/AddCoupon`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    const model = {
      'Coupon': data.Coupon,
      'Discount': data.Discount,
      'DueDate': data.DueDate,
      'OrganizationId': this.appSettings.OrganizationId
    };
    return this.http.post(API_URL, model, options).pipe(catchError(this.error));
  }

  editCoupon(data: DiscountCoupon): Observable<any> {
    let API_URL = `${this.apiUrl}/EditCoupon`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    const model = {
      'Id': data.Id,
      'Coupon': data.Coupon,
      'Discount': data.Discount,
      'DueDate': data.DueDate,
      'OrganizationId': this.appSettings.OrganizationId
    };
    return this.http.post(API_URL, model, options).pipe(catchError(this.error));
  }

  getCouponsByOrganizationId(): Observable<DiscountCoupon[]> {
    let organizationId = this.appSettings.OrganizationId;
    let API_URL = `${this.apiUrl}/GetCouponsByOrganizationId/${organizationId}`;
    return this.http.get<DiscountCoupon[]>(API_URL).pipe(catchError(this.error));
  }

  deleteCoupon(id: number): Observable<any> {
    let API_URL = `${this.apiUrl}/DeleteCoupon`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    const model = {
      'Id': id,
      'OrganizationId': this.appSettings.OrganizationId
    };
    return this.http.post(API_URL, model, options).pipe(catchError(this.error));
  }

  publishCoupon(id: number): Observable<any> {
    let API_URL = `${this.apiUrl}/PublishCoupon`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    const model = {
      'Id': id,
      'OrganizationId': this.appSettings.OrganizationId
    };
    return this.http.post(API_URL, model, options).pipe(catchError(this.error));
  }

  validateExistingCoupon(data: DiscountCoupon): Observable<any> {
    let API_URL = `${this.apiUrl}/ValidateExistingCoupon`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    const model = {
      'Coupon': data.Coupon,
      'OrganizationId': this.appSettings.OrganizationId
    };
    return this.http.post(API_URL, model, options).pipe(catchError(this.error));
  }

  changeDiscountCoupons(status: boolean): Observable<any> {
    let API_URL = `${this.apiUrl}/ChangeDiscountCoupons`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let url = document.location.origin;

    let userObject = JSON.parse(localStorage.getItem('currentUser'));

    return this.http.post(API_URL,
      {
        'OrganizationId': this.appSettings.OrganizationId,
        'UrlDomain': url,
        'Status': status,
        'UserId': userObject.Id
      }
      , options).pipe(catchError(this.error));

  }

  redeemCoupon(data: DiscountCoupon): Observable<any> {
    let API_URL = `${this.apiUrl}/RedeemCoupon`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    const model = {
      'Coupon': data.Coupon,
      'OrganizationId': this.appSettings.OrganizationId
    };
    return this.http.post(API_URL, model, options).pipe(catchError(this.error));
  }

  getUsersByCoupon(couponId: number): Observable<UsersByCoupon[]> {
    const organizationId = this.appSettings.OrganizationId;
    let API_URL = `${this.apiUrl}/GetUsersByCoupon/${couponId}/${organizationId}`;
    return this.http.get<UsersByCoupon[]>(API_URL).pipe(catchError(this.error));
  }

  // Handle Errors 
  error(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
