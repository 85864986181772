import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, FormArray, Form } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { AlertService } from '../shared/services/alert/alert.service';
import { RequestsService } from '../shared/services/requests.service';

//Models
import { ContactInfo } from '../shared/models/ContactInfo';
import { EmailConfig } from '../shared/models/EmailConfig';
import { StripeConfig } from '../shared/models/StripeConfig';
import { AccountResponse } from '../shared/models/AccountResponse';
import { Address } from '../shared/models/Address';
import { GlobalConstans } from '../shared/global-constants/constants';
import { EmailService } from '../shared/services/email/email.service';
import { MailRequest } from '../shared/models/MailRequest';

@Component({
  selector: 'app-customization',
  templateUrl: './customization.component.html',
  styleUrls: ['./customization.component.css']
})
export class CustomizationComponent implements OnInit {
  //Bind data
  Contact: ContactInfo;
  Email: EmailConfig;
  Stripe: StripeConfig;
  
  

  //flags
  editMode = false;
  successAlert = false;
  errorAlert = false;
  response: AccountResponse;

    
  // EditFlags
  editContactInformation = false;
  editEmailSettings = false;
  editStripeSettings = false;
      
  //Forms
  contactInformationForm: FormGroup;
  emailSettingsForm: FormGroup;
  stripeSettingsForm: FormGroup;

  //submit vars
  submitContactInformationForm = false;
  submitEmailSettingsForm = false;
  submitStripeSettingsForm = false;

   //loading vars
   loadingContactInformationForm = false;
   loadingEmailSettingsForm = false;
   loadingStripeSettingsForm = false;



  public enableCaurouselEdition = false;

  constructor(private alert: AlertService, private service: RequestsService,
    private formBuilder: FormBuilder, private rutas: Router, private emailService: EmailService) {
      
      let userObject = JSON.parse(localStorage.getItem('currentUser'));
      if(userObject === null || !userObject.IsCartManager)
      {
        this.rutas.navigate(['/index']); 
      }

    //Init response object
    this.response = new AccountResponse();

    //Contact Information Form
    this.contactInformationForm = new FormGroup({ 
      Schedule:new FormControl(), 
      Country: new FormControl(),
      State: new FormControl(),
      City: new FormControl(),
      Direction: new FormControl(),
      PostalCode: new FormControl(),
      Telephone: new FormControl(),
    });

    //Email Settings Form
    this.emailSettingsForm = new FormGroup({
      Mail: new FormControl(),
      DisplayName: new FormControl(),
      Password  : new FormControl(),
      Host: new FormControl(),
      Port: new FormControl(),
      EmailReceiver: new FormControl(),
      EmailCopyPurchase: new FormControl(),
      TestEmail: new FormControl()
    });


    //Stripe Settings Form
    this.stripeSettingsForm = new FormGroup({
      PublicKey: new FormControl(),
      SecretKey: new FormControl(),
    });

  }
  
//=====================================================================================================
// ngOnInit()  
//=====================================================================================================
  ngOnInit(): void {
    
    this.getDataForEdit();
    this.enableCaurouselEdition = GlobalConstans.getCacheValue("ShowBanner");
    
  }


getDataForEdit(){
  this.service.GetCustomizationInfo()
    .subscribe((info) => {

      this.Contact = info.ContactInfo;
      this.Email = info.EmailSettings;
      this.Stripe = info.StripeSettings;

      this.onInitContactInformationForm();
      this.onInitEmailSettingsForm();
      this.onInitStripeSettingsForm();
    });
}

//=====================================================================================================
// Toggle Carousel Slider
//=====================================================================================================
  onCheckboxCarousel(value) {
    
    let isChecked = value.target.checked;

    let $title = isChecked ? 'Enable Carousel Slider' : 'Disabled Carousel Slider';
    let $text = isChecked 
    ? 'Do you want to enable the carousel slider?'
    : 'Do you want to disabled the carousel slider?'
    let $button = {'ConfirmButtonText': 'Change', 'cancelButtonText': 'Cancel'};

    this.alert.onBeforeConfirm($title, $text, $button)
    .then((response) => {

      if(response.value === true){
        
        this.service.ChangeShowBanner(isChecked)
        .subscribe((response:any) => {
          
          if(response.Success){
            this.enableCaurouselEdition = isChecked;
            let text = isChecked 
            ? "You have enabled the carousel successfully"
            : "You have disabled the carousel successfully";
            return this.alert.onConfirm("Perfect",text);
          }

        });
        
        
      }else{
        this.enableCaurouselEdition = !isChecked;
      }
    });

  }

//=====================================================================================================
// Inicialización de Formularios
//=====================================================================================================

  onInitContactInformationForm()
  {
    this.contactInformationForm = this.formBuilder.group({
      Country: [this.Contact.Address.Country, Validators.required],
      City: [this.Contact.Address.City, Validators.required],
      State: [this.Contact.Address.State, Validators.required],
      Direction: [this.Contact.Address.Direction, Validators.required],
      PostalCode: [this.Contact.Address.PostalCode, Validators.required],
      Schedule: [this.Contact.Schedule],
      Telephone: [this.Contact.PhoneNumber],
      Email: [this.Contact.Email, Validators.email],
    })
  };

  onInitEmailSettingsForm()
  {
    this.emailSettingsForm = this.formBuilder.group({
      Mail: [this.Email.Mail, Validators.required],
      DisplayName: [this.Email.DisplayName, Validators.required],
      Password: [this.Email.Password, Validators.required],
      Host: [this.Email.Host, Validators.required],
      Port: [this.Email.Port === 0 ? '' : this.Email.Port, Validators.required],
      EmailReceiver: [this.Email.EmailReceiver, Validators.required],
      EmailCopyPurchase: [this.Email.EmailCopyPurchase, Validators.required],
      TestEmail: ['']
    })
  };

  onInitStripeSettingsForm()
  {
    this.stripeSettingsForm = this.formBuilder.group({
      PublicKey: [this.Stripe.PublicKey, Validators.required],
      SecretKey: [this.Stripe.SecretKey, Validators.required]
    })
  };

  
// ====================================================================================================================================
// FORMS PROPERTIES
// ====================================================================================================================================
  get ci() { return this.contactInformationForm.controls; }
  get es() { return this.emailSettingsForm.controls; }
  get ss() { return this.stripeSettingsForm.controls; }


// ====================================================================================================================================
// Submit Forms Functions
// ====================================================================================================================================

  onSubmitContactInformation(){
    this.submitContactInformationForm = true;

    // stop here if form is invalid
    if (this.contactInformationForm.invalid) { return; }

    this.loadingContactInformationForm = true;

    let contact = new ContactInfo();
      let address = new Address();
          address.PostalCode = this.contactInformationForm.value.PostalCode;
          address.City = this.contactInformationForm.value.City;
          address.State = this.contactInformationForm.value.State;
          address.Country = this.contactInformationForm.value.Country;
          address.Direction = this.contactInformationForm.value.Direction;
    contact.Address = address;
    contact.Email = this.contactInformationForm.value.Email;
    contact.PhoneNumber = this.contactInformationForm.value.Telephone;
    contact.Schedule = this.contactInformationForm.value.Schedule;
    
    this.service.UpdateContactInfo(contact)
    .subscribe((res:any) => {

      if(res.Success){

        this.successAlert = true;
        this.response.Message = "You have updated the Contact information successfully!";
        this.cancel('contactInformation', true);
      }else{
        this.errorAlert = true;
        this.response.Message = "We found an error, please try again later";
      }

    }, err => { 
          this.submitContactInformationForm = false;
          this.loadingContactInformationForm = false;
          console.log(err);
        })
  }

  onSubmitEmailSettings(){
    this.submitEmailSettingsForm = true;

    // stop here if form is invalid
    if (this.emailSettingsForm.invalid) { return; }

    this.loadingEmailSettingsForm = true;

    let email = new EmailConfig();
    email.Mail = this.emailSettingsForm.value.Mail;
    email.DisplayName = this.emailSettingsForm.value.DisplayName;
    email.Password = this.emailSettingsForm.value.Password;
    email.Host = this.emailSettingsForm.value.Host;
    email.Port = this.emailSettingsForm.value.Port;
    email.EmailReceiver = this.emailSettingsForm.value.EmailReceiver;
    email.EmailCopyPurchase = this.emailSettingsForm.value.EmailCopyPurchase;    
    this.service.UpdateEmailSettings(email)
    .subscribe((res:any)=>{

      if(res.Success){

        this.successAlert = true;
        this.response.Message = "You have updated the Email settings successfully!";
        this.cancel('emailSettings', true);
      }else{
        this.errorAlert = true;
        this.response.Message = "We found an error, please try again later";
      }

    }, err => { 
      this.submitEmailSettingsForm = false;
      this.loadingEmailSettingsForm = false;
      console.log(err);
    });
  }

  onSubmitStripeSettings(){
    this.submitStripeSettingsForm = true;

    // stop here if form is invalid
    if (this.stripeSettingsForm.invalid) { return; }

    this.loadingStripeSettingsForm = true;

    let stripe = new StripeConfig();
    stripe.PublicKey = this.stripeSettingsForm.value.PublicKey;
    stripe.SecretKey = this.stripeSettingsForm.value.SecretKey;

    this.service.UpdateStripeSettings(stripe)
    .subscribe((res:any) => {

      if(res.Success){

        this.successAlert = true;
        this.response.Message = "You have updated the Stripe settings successfully!";
        this.cancel('stripeSettings', true);
      }else{
        this.errorAlert = true;
        this.response.Message = "We found an error, please try again later";
      }

    }, err => { 
      this.submitStripeSettingsForm = false;
      this.loadingStripeSettingsForm = false;
      console.log(err);
    });

  }


// ====================================================================================================================================
// Aux Functions
// ====================================================================================================================================
  changeMode(enableForm: string){
    this.editMode = true;
    this.successAlert = false;

    switch (enableForm) {
      case 'contactInformation':
        this.editContactInformation = true;
        break;
      case 'emailSettings':
        this.editEmailSettings = true;
        break;
      case 'stripeSettings':
        this.editStripeSettings = true;
        break;
        default:
        break;
    }
  }
  
  cancel(disabledForm: string, execute?:boolean){
    this.editMode = false;
    this.errorAlert = false;

    this.editContactInformation = false;
    this.editEmailSettings = false;
    this.editStripeSettings = false;

    this.submitContactInformationForm = false;
    this.submitEmailSettingsForm = false;
    this.submitStripeSettingsForm = false;

    this.loadingContactInformationForm = false;
    this.loadingEmailSettingsForm = false;
    this.loadingStripeSettingsForm = false;

    if(execute){
      this.getDataForEdit();
    }

  }

  sendTestEmail() {
    
    const currentEmail = this.emailSettingsForm.value.TestEmail;

    if (currentEmail === '' || currentEmail === null) return;

    let model = new MailRequest();
    model.ToEmail = currentEmail;
    model.BearerToken = GlobalConstans.getAuthToken();

    this.emailService.emailDemo(model).subscribe((res: any) => {
      if (res) {
        this.alert
          .onConfirm('Perfect!', "Email service is working!")
          .then((result) => {
            if (result.value) {
              return;
            }
          });

      } else {
        this.alert
          .onError('Sorry!', "There is a problem with the email service, please contact your administrator.")
          .then((result) => {
            if (result.value) {
              return true;
            }
          });
      }
    })
  }
}
