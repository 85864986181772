export class User{
    Id: number;
    Initials: string;
    FirstName: string;
    LastName: string;
    CountryId: number;
    TimeZoneId :string;
    Email :string;
    UserName: string;
    CurrentPassword : string;
    Password: string;
    PasswordSalt: string;
    SecurityQuestionId: number;
    Answer: string;
    AnswerSalt: string;
    FullName: string;
    token?: string;
    IsCartManager: boolean;
    UrlDomain: string;
}
